import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { HOME_PATHS } from "../../constants"
import { useNavigation } from "../../hooks/useNavigation"
import Reservations from "./Home/Reservations"

import { useDeskCheckInMutation } from "../../redux/api/checkIns"
import { isApiResponseError, isRejected } from "../../redux/api/types"

import { Drawer } from "../../components/Mobile/Drawer"
import { TopNav } from "../../components/Mobile/TopNav"

import { ReactComponent as CheckSVG } from "../../assets/images/icons/CheckCircle.svg"
import { ReactComponent as ErrorSVG } from "../../assets/images/icons/Error.svg"

import "./DeskCheckin.sass"

type DeskCheckinParams = {
  desk_id: string
}

const DeskCheckin = () => {
  const { t } = useTranslation()
  const { desk_id } = useParams<DeskCheckinParams>()
  const { replace } = useNavigation()
  const [deskCheckIn, { isError, isSuccess }] = useDeskCheckInMutation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
    replace(HOME_PATHS.mobile)
  }

  useEffect(() => {
    const checkIn = async () => {
      if (!desk_id) {
        return
      }

      const request = await deskCheckIn({ id: desk_id })

      if (!isRejected(request)) {
        setOpen(true)

        return request
      }

      if (isApiResponseError(request.error)) {
        setOpen(true)
        const {
          error: { formError },
        } = request

        typeof formError === "object" &&
          "_error" in formError &&
          typeof formError._error === "string" &&
          setErrorMessage(formError._error)
      }
    }

    checkIn()
  }, [desk_id])

  return (
    <div>
      <Reservations />
      <Drawer open={open} onClose={onClose}>
        <div className="DeskCheckin">
          <TopNav
            title={t("mobile.desk_check_in.title")}
            titleCenter
            backArrow={false}
            onClose={onClose}
          />
          <div className="DeskCheckin__content">
            {isError && (
              <div className="DeskCheckin__message error">
                <ErrorSVG />
                <span>{errorMessage}</span>
              </div>
            )}
            {isSuccess && (
              <div className="DeskCheckin__message">
                <CheckSVG />
                <span>{t("mobile.desk_check_in.success")}</span>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default DeskCheckin
