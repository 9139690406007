import { MouseEvent, useCallback } from "react"

import { t } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { useToast } from "../../hooks/useToast"
import { ORGANIZATION_PATHS } from "../../screens/Settings/Organization/constants"
import Button from "../advanced/Button"
import { Input } from "../basic/Input"
import Field from "../Field"
import { setErrors } from "./formUtils"
import PageForm from "./PageFormHook"
import { isRejected } from "@reduxjs/toolkit"

import { useDeleteCompanyMutation } from "../../redux/api/company"
import { useFetchMeQuery } from "../../redux/api/me"
import { isApiResponseError } from "../../redux/api/types"

import "./DeleteCompanyForm.sass"

type FormValues = {
  email: string
  repeatEmail: string
}

const DeleteCompanyForm = () => {
  const { errorToast } = useToast()
  const history = useHistory()

  const { data: user } = useFetchMeQuery()

  const methods = useForm<FormValues>({
    defaultValues: {
      email: user?.email ?? "",
      repeatEmail: "",
    },
  })
  const {
    setError,
    control,
    formState: { isSubmitting },
    watch,
  } = methods

  const [deleteCompany] = useDeleteCompanyMutation()

  const email = watch("email")
  const repeatEmail = watch("repeatEmail")

  const onDeleteClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()

      if (email !== repeatEmail) {
        setError("repeatEmail", {
          type: "manual",
          message: "Emails do not match",
        })
        return
      }

      const response = await deleteCompany()

      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast)
          return
        }
      }

      history.push(ORGANIZATION_PATHS.company_details_survey)
    },
    [history, deleteCompany, email, repeatEmail, setError, errorToast],
  )

  return (
    <FormProvider {...methods}>
      <PageForm
        confirmationPrompt={false}
        className="DeleteCompanyForm"
        onDelete={onDeleteClick}
        updateMode
        additionalButton={
          <Button
            onClick={() => history.push(ORGANIZATION_PATHS.company_details)}
            disabled={isSubmitting}
            variant="secondary-white"
            className="cancelButton"
          >
            {t("general.cancel")}
          </Button>
        }
        deleteButtonText={t(
          "desktop.settings.organization.company_details.delete_company.form.delete",
        )}
      >
        <div className="field-width-50">
          <Field
            control={control}
            name="email"
            label={t(
              "desktop.settings.organization.company_details.delete_company.form.email",
            )}
          >
            {(props) => (
              <Input
                {...props}
                autoFocus
                maxLength={60}
                disabled
                onChange={undefined}
              />
            )}
          </Field>
        </div>
        <div className="field-width-50">
          <Field
            control={control}
            name="repeatEmail"
            label={t(
              "desktop.settings.organization.company_details.delete_company.form.retype_email",
            )}
          >
            {(props) => (
              <Input
                {...props}
                onPaste={(e) => e.preventDefault()}
                autoFocus
                maxLength={60}
                disabled={isSubmitting}
              />
            )}
          </Field>
        </div>
      </PageForm>
    </FormProvider>
  )
}

export default DeleteCompanyForm
