import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchBuildingQuery } from "../../../redux/api/buildings"
import { BuildingResponse } from "../../../redux/api/buildings/types"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import BuildingForm from "../../../components/Form/BuildingForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./EditBuildings.sass"

type ParamsType = {
  building_id: string
}

const DEFAULT_BUILDING = {
  name: "",
} as Partial<BuildingResponse>

const EditBuildings = () => {
  const { t } = useTranslation()

  const { building_id } = useParams<ParamsType>()

  const {
    data: building = DEFAULT_BUILDING,
    isLoading,
    error,
  } = useFetchBuildingQuery(
    building_id ? { id: building_id, stats: true } : skipToken,
  )

  const buildingNotFound =
    (!!building_id && !building.id) ||
    (error && "status" in error && error.status === 404)

  return (
    <View className="EditBuildings">
      {!isLoading && (
        <>
          <Breadcrumbs
            depth={2}
            values={[
              t("desktop.settings.buildings.title"),
              building?.id
                ? t("desktop.settings.buildings.edit_breadcrumb")
                : t("desktop.settings.buildings.add_breadcrumb"),
            ]}
          />

          <Intro isConstrained>
            {building?.id
              ? t("desktop.settings.buildings.edit_title")
              : t("desktop.settings.buildings.add_title")}
          </Intro>

          <Space size={0.75} />
        </>
      )}

      {isLoading ? (
        <Loader />
      ) : buildingNotFound ? (
        <p>{t("desktop.settings.buildings.not_found")}</p>
      ) : (
        <BuildingForm building={building} />
      )}
    </View>
  )
}

export default EditBuildings
