import React, { useEffect } from "react"

import classNames from "classnames"
import queryString from "query-string"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../analytics"
import { ApiResponseError } from "../api/apiUtils"
import { HOME_PATHS } from "../constants"
import { useTimeout } from "../hooks/useTimeout"
import { useToast } from "../hooks/useToast"
import { Children } from "../types/sharedTypes"
import Login from "./Login"
import { SerializedError } from "@reduxjs/toolkit"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchCompanyQuery } from "../redux/api/company"
import { useCheckinDeskReservationMutation } from "../redux/api/deskReservations"
import {
  useFetchEmployeeScreeningQuery,
  useSaveEmployeeScreeningMutation,
} from "../redux/api/screenings"
import { EmployeeScreening } from "../redux/api/screenings/types"
import { isApiResponseError, isRejected } from "../redux/api/types"
import { selectIsMobile } from "../redux/app/selectors"
import { fetchCheckIns } from "../redux/check_ins/checkInsSlice"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

import Button from "../components/advanced/Button"
import ButtonSelect from "../components/basic/ButtonSelect"
import Loader from "../components/basic/Loader"
import Field from "../components/Field"
import PageForm from "../components/Form/PageFormHook"
import Heading from "../components/Heading"
import Intro from "../components/Intro"
import { BottomNav } from "../components/Mobile/BottomNav"
import SafeViewArea from "../components/Mobile/SafeViewArea"
import { TopNav } from "../components/Mobile/TopNav"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"
import Space from "../components/Space"
import View from "../components/View"

import "./HealthScreening.sass"

type Params = {
  building_id?: string
}
type FormValues = {
  [question: string]: string
}

/**
 *  HealthScreening
 */
const HealthScreening = ({ match }: RouteComponentProps<Params>) => {
  const { params } = match || {}
  const { building_id } = params || {}
  const { data, isLoading, error } = useFetchEmployeeScreeningQuery(
    building_id ?? skipToken,
  )
  const history = useHistory()

  const access_token = useAppSelector((state) => state.auth.access_token)

  useEffect(() => {
    if (data?.passed || data?.passed === false) {
      history.replace(`/screening/employee/${building_id}/completed`)
    }
  }, [building_id, data, history])

  if (isLoading) {
    return (
      <ScreeningWrapper>
        <Loader variant="fullScreen" />
      </ScreeningWrapper>
    )
  }

  if (!access_token) {
    return <Login />
  }

  if (!data || error) {
    return <ScreeningError error={error} />
  }

  if (data.passed === null) {
    return <HealthSurvey buildingId={building_id ?? ""} screening={data} />
  }
  return null
}

/**
 *  ScreeningWrapper
 */
const ScreeningWrapper = ({
  children,
  className,
}: {
  children: Children
  className?: string
}) => {
  const history = useHistory()
  const isMobile = useAppSelector(selectIsMobile)

  const healthClassName = classNames(
    isMobile ? "HealthSurveyMobile" : "HealthScreening",
    className,
    { isConstrained: !isMobile },
  )

  if (isMobile) {
    return (
      <SafeViewArea className={healthClassName}>
        <TopNav backArrow onClose={() => history.push("/")} />
        <div className="HealthMiddle">{children}</div>
        <BottomNav />
      </SafeViewArea>
    )
  }
  return (
    <RouteView className={healthClassName}>
      <Screen>
        <View className="HealthSurveyResult">{children}</View>
      </Screen>
    </RouteView>
  )
}

/**
 *  HealthSurvey
 */
const HealthSurvey = ({
  screening,
  buildingId,
}: {
  screening: EmployeeScreening
  buildingId: string
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [saveEmployeeScreening, { isLoading }] =
    useSaveEmployeeScreeningMutation()
  const [checkinDeskReservation] = useCheckinDeskReservationMutation()
  const { data: { settings } = {} } = useFetchCompanyQuery()

  const { infoToast, errorToast } = useToast()

  const isMobile = useAppSelector(selectIsMobile)

  const actions = useActions({
    fetchCheckIns: () => fetchCheckIns(),
  })

  const defaultValues = screening.questions.reduce((obj, question) => {
    return {
      ...obj,
      [question.question]: "",
    }
  }, {})

  const methods = useForm<FormValues>({
    defaultValues,
  })
  const { control, handleSubmit } = methods

  async function saveQuestionnaire(questions: FormValues) {
    const response = await saveEmployeeScreening({
      buildingId,
      questions: Object.entries(questions).map(([key, value]) => ({
        question: key,
        answer: value,
      })),
    })

    if (isRejected(response)) {
      errorToast(response.error.message)
      return
    }

    if (response.data.passed === true) {
      infoToast(t("general.screening.toast.pass"))
      const query: { id?: string } = queryString.parse(
        history.location.search,
      ) as { id?: string }

      if (query.id) {
        if (isMobile && settings?.desk_check_in_location === "on_spot") {
          history.push(HOME_PATHS.scanQrCode)
          return
        }

        const response = await checkinDeskReservation(query.id)

        if (isRejected(response)) {
          errorToast(response.error.message)
          return
        }

        analyticsEvent(SupportedEvents.DESK_RESERVATION_CHECKIN, {
          id: query.id,
        })

        await actions.fetchCheckIns()
      }
      history.push("/")
    } else {
      history.replace(`/screening/employee/${buildingId}/failed`)
      errorToast(t("general.screening.toast.failed"))
    }
  }

  const surveyFormFields = screening.questions.map((q) => (
    <Field
      key={q.question}
      control={control}
      name={q.question}
      label={q.question}
      rules={{
        required: t("general.screening.field_required"),
      }}
    >
      {(props) => (
        <ButtonSelect
          {...props}
          disable={isLoading}
          options={[
            { label: t("general.yes"), value: "yes" },
            { label: t("general.no"), value: "no" },
          ]}
        />
      )}
    </Field>
  ))

  return (
    <ScreeningWrapper>
      <Heading>{screening.introduction_headline}</Heading>
      <Intro>{screening.introduction}</Intro>

      <Space size={1} />
      <FormProvider {...methods}>
        {isMobile ? (
          <form onSubmit={handleSubmit(saveQuestionnaire)}>
            {surveyFormFields}
            <Button
              isSubmit
              variant="mobile-action"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {t("general.screening.submit_button")}
            </Button>
          </form>
        ) : (
          <PageForm
            updateMode={true}
            onUpdate={saveQuestionnaire}
            disabled={isLoading}
          >
            {surveyFormFields}
          </PageForm>
        )}
      </FormProvider>
    </ScreeningWrapper>
  )
}

/**
 *  ScreeningFailed
 */
export const ScreeningFailed = ({ match }: RouteComponentProps<Params>) => {
  const { params } = match || {}
  const { building_id } = params || {}
  const { data } = useFetchEmployeeScreeningQuery(building_id ?? skipToken)
  const history = useHistory()

  useTimeout(() => {
    history.push(`/`)
  }, 10000)

  return (
    <ScreeningWrapper>
      <Heading>{data?.failure_msg_headline}</Heading>
      <Space size={1} />
      <Intro>{data?.failure_msg}</Intro>
    </ScreeningWrapper>
  )
}

/**
 *  ScreeningCompleted
 */
export const ScreeningCompleted = ({ match }: RouteComponentProps<Params>) => {
  const { params } = match || {}
  const { building_id } = params || {}
  const { data } = useFetchEmployeeScreeningQuery(building_id ?? skipToken)
  const history = useHistory()
  const { t } = useTranslation()

  useTimeout(() => {
    history.push(`/`)
  }, 10000)

  return (
    <ScreeningWrapper>
      <Heading>{t("general.screening.success.title")}</Heading>
      <Intro>{t("general.screening.success.text")}</Intro>
      {data?.passed === false && (
        <>
          <Space size={1} />
          <Heading>{data?.failure_msg_headline}</Heading>
          <Intro>{data?.failure_msg}</Intro>
        </>
      )}
    </ScreeningWrapper>
  )
}

/**
 *  NoScreening
 */
const ScreeningError = ({
  error,
}: {
  error?: ApiResponseError | SerializedError
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const isNotApplicable =
    error && isApiResponseError(error) && error.status === 404
  const isMobile = useAppSelector(selectIsMobile)

  useTimeout(() => {
    if (isNotApplicable) {
      history.push(`/`)
    }
  }, 10000)

  return (
    <ScreeningWrapper>
      <Heading>
        {t(
          isNotApplicable
            ? "general.screening.errors.not_applicable.title"
            : "general.screening.errors.general_error.title",
        )}
      </Heading>
      <Space size={1} />
      <Intro>
        {isNotApplicable ? (
          t("general.screening.errors.not_applicable.text")
        ) : (
          <>
            {t("general.screening.errors.general_error.text")}
            {error && <p> {error.message}</p>}
          </>
        )}
      </Intro>
      <Space size={1} />

      {!isNotApplicable && (
        <Button
          variant={isMobile ? "mobile-action" : "primary"}
          onClick={() => window.location.reload()}
        >
          {t("general.retry")}
        </Button>
      )}
    </ScreeningWrapper>
  )
}

export default HealthScreening
