import { PropsWithChildren } from "react"

import AppParamsProvider from "../AppParamsProvider"
import LocalizationProvider from "../LocalizationProvider"
import AuthProvider from "./AuthProvider"
import { ErrorHandlerProvider } from "./ErrorHandlerProvider"
import { NativeProviders } from "./Native/NativeProviders"
import RegistrationDataProvider from "./RegistrationDataProvider"
import TabletDataProvider from "./TabletDataProvider"
import WebSocketProvider from "./WebSocketProvider"
import { Capacitor } from "@capacitor/core"

/*
If we access visitor endpoints as native device (tablet).
First we load native provider which checks if there is camera permissions and some other native functionalities.
Tablet is authenticated and if not it is redirected to on boarding screens.
Then we load tablet and printer data and we enable web socket functionality (used for handling requests to update data or print badge).
At the end we load data that is needed for registration process (field inputs, health questions, document...).

If we access through browser.
We need building ID, tablet ID and touchless PIN or invite ID in URL as query parameter.
In this way requests for getting registration data are authenticated.
*/
export const VisitorProviders = ({ children }: PropsWithChildren<unknown>) => {
  return Capacitor.isNativePlatform() ? (
    <ErrorHandlerProvider>
      <AppParamsProvider>
        <LocalizationProvider>
          <NativeProviders>
            <AuthProvider>
              <TabletDataProvider>
                <WebSocketProvider />
                <RegistrationDataProvider>{children}</RegistrationDataProvider>
              </TabletDataProvider>
            </AuthProvider>
          </NativeProviders>
        </LocalizationProvider>
      </AppParamsProvider>
    </ErrorHandlerProvider>
  ) : (
    <ErrorHandlerProvider>
      <AppParamsProvider>
        <LocalizationProvider>
          <AuthProvider>
            <TabletDataProvider>
              <WebSocketProvider />
              <RegistrationDataProvider>{children}</RegistrationDataProvider>
            </TabletDataProvider>
          </AuthProvider>
        </LocalizationProvider>
      </AppParamsProvider>
    </ErrorHandlerProvider>
  )
}
