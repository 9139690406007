import React, { PropsWithChildren, SyntheticEvent, useEffect } from "react"

import classNames from "classnames"
import { useHistory } from "react-router-dom"

import { PERMISSIONS } from "../constants"
import { useCheckForPermission } from "../hooks/useCheckForPermission"
import { getLabel } from "../utils"
import LogoVariant from "./LogoVariant"
import { DESKS_PATHS } from "./Settings/Desks/constants"
import { captureMessage } from "@sentry/react"

import { selectConsent } from "../redux/consent/selectors"
import { useAppSelector } from "../redux/reducers"
import { isPortalAdmin } from "../redux/user/utils"
import { UserExperimentSettings } from "../redux/user_experiments/types"
import { giveUserConsent } from "../redux/user_experiments/userExperimentsSlice"
import { useActions } from "../redux/utils"

import Button from "../components/advanced/Button"

import "./GuardDisclaimer.sass"

type Props = {
  className: any
}

export default function GuardDisclaimer({
  className,
  children,
}: PropsWithChildren<Props>) {
  const guardDisclaimerClassName = classNames({
    GuardDisclaimer: true,
    [className]: !!className,
  })

  return <div className={guardDisclaimerClassName}>{children}</div>
}

export function IE11Disclaimer() {
  useEffect(() => {
    captureMessage("WARNING: IE 11 Detected")
  }, [])

  return (
    <GuardDisclaimer className="IE11Disclaimer">
      <p>
        <LogoVariant variant="portrait" />
      </p>
      <p>
        This web app requires a newer browser to run.
        <br />
        Please choose one of the evergreen browsers for Windows:
      </p>
      <p className="links">
        <a
          href="https://www.microsoft.com/en-us/edge"
          title="Download Microsoft Edge"
        >
          Microsoft Edge
        </a>
        ,{" "}
        <a
          href="https://www.mozilla.org/en-US/firefox/windows/"
          title="Download Mozilla Firefox"
        >
          Mozilla Firefox
        </a>
        ,{" "}
        <a href="https://www.google.com/chrome/" title="Download Google Chrome">
          Google Chrome
        </a>
        , and alike.
      </p>
    </GuardDisclaimer>
  )
}

export function UserConsentDisclaimer({
  children,
}: PropsWithChildren<unknown>) {
  const actions = useActions({
    giveUserConsent: () => giveUserConsent(),
  })
  const history = useHistory()

  const { entry: user, isLoaded: isUserLoaded } = useAppSelector(
    (state) => state.user,
  )

  const { entry: companyConsent, isLoaded: isCompanyConsentLoaded } =
    useAppSelector(selectConsent)

  const hasPermissions = useCheckForPermission(
    PERMISSIONS.dashboard.canChangeDashboardSettings,
  )

  async function enableJDBA() {
    if (!isUserLoaded) {
      return false
    }

    if (!hasPermissions) {
      return false
    }

    if (isCompanyConsentLoaded && companyConsent === true) {
      return false
    }
    history.push(DESKS_PATHS.general)
  }

  const handleAgreeClick = async (e: SyntheticEvent) => {
    e.preventDefault()

    await actions.giveUserConsent()
    await enableJDBA()
  }

  const { entries, isLoaded: areUserExperimentsLoaded } = useAppSelector(
    (state) => state.user_experiments,
  )

  const userConsent = entries.find((entry: UserExperimentSettings) => {
    if (entry.data.type === "USER_CONSENT") {
      return entry.data.value
    }
    return false
  })

  const isAuthPath = window.location.pathname.includes("/auth/")
  const isConsented =
    userConsent && userConsent.data && userConsent.data.value === true

  if (isConsented || isAuthPath || isPortalAdmin(user)) {
    return <>{children}</>
  } else if (!areUserExperimentsLoaded) {
    return null
  } else {
    return (
      <GuardDisclaimer className="UserConsentDisclaimer">
        <p>
          <LogoVariant variant="portrait" />
        </p>

        <p>
          Please confirm you agree with
          <br />
          our terms of use &amp; privacy policy:
        </p>

        <p>
          <a
            title="Visionect Terms of Use"
            href={getLabel("links.termsOfUseURL")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>{" "}
          &middot;{" "}
          <a
            title="Visionect Privacy Policy"
            href={getLabel("links.privacyPolicyURL")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>

        <p>
          {isUserLoaded && isCompanyConsentLoaded && (
            <Button onClick={handleAgreeClick}>I agree</Button>
          )}
        </p>
      </GuardDisclaimer>
    )
  }
}
