import React from "react"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

type Props = {
  value: string
  onChange: (v: string) => void
}

export default function RoleFilter({ value, onChange }: Props) {
  const roleOptions = [
    { label: "All roles", value: FilterSpecialValues.ALL },
    { label: "Users", value: "portal_user" },
    { label: "Office managers", value: "portal_officer_manager" },
    { label: "Admins", value: "portal_admin" },
  ]

  return (
    <Dropdown
      className="role-filter"
      options={roleOptions}
      value={value}
      onChange={onChange}
    />
  )
}
