import React, { useEffect, useMemo, useRef } from "react"

import { OptionType } from "../../types/sharedTypes"
import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import { selectBuildingsWithVM } from "../../redux/buildings/selectors"
import { BuildingResponse } from "../../redux/buildings/types"
import { useAppSelector } from "../../redux/reducers"

type Props = {
  value: string
  onChange: (v: string) => void
  showAll?: boolean
  hasVisitorManagement?: boolean
  showNotAssigned?: boolean
}

export default function BuildingFilter({
  value,
  onChange,
  showAll = false,
  hasVisitorManagement = false,
  showNotAssigned = false,
}: Props) {
  const {
    data: { results: entries = [] } = {},
    isSuccess: areBuildingsLoaded,
  } = useFetchBuildingsQuery()
  const { entries: buildingsWithVM } = useAppSelector(selectBuildingsWithVM)

  const buildings = useMemo(
    () => (hasVisitorManagement ? buildingsWithVM : entries),
    [hasVisitorManagement, entries, buildingsWithVM],
  )

  const initValueRef = useRef<string>(value)

  useEffect(() => {
    if (!areBuildingsLoaded) {
      return
    }
    // Handle if building deletes or if not selected
    const initValue = initValueRef.current

    if (
      initValue !== FilterSpecialValues.EMPTY &&
      initValue !== FilterSpecialValues.ALL
    ) {
      const hasBuilding =
        buildings.find((b: BuildingResponse) => b.id === initValue) !==
        undefined

      if (!hasBuilding) {
        if (showAll || buildings.length < 1) {
          onChange(FilterSpecialValues.ALL)
          initValueRef.current = FilterSpecialValues.ALL
        } else {
          onChange(buildings[0].id)
          initValueRef.current = buildings[0].id
        }
      }
    } else {
      if (
        (initValue === FilterSpecialValues.EMPTY ||
          (!showAll && initValue === FilterSpecialValues.ALL)) &&
        buildings.length > 0
      ) {
        onChange(buildings[0].id)
        initValueRef.current = buildings[0].id
      }
    }
  }, [showAll, buildings, areBuildingsLoaded])

  const buildingOptions: OptionType<string>[] = buildings.map(
    (b: BuildingResponse) => ({
      value: b.id,
      label: b.name,
    }),
  )

  const count = buildings.length

  showNotAssigned &&
    buildingOptions.unshift({
      label: "Not assigned",
      value: FilterSpecialValues.NOT_ASSIGNED,
    })

  showAll &&
    buildingOptions.unshift({
      label: (
        <>
          All buildings <span className="count">&middot; {count}</span>
        </>
      ),
      value: FilterSpecialValues.ALL,
    })

  return (
    <Dropdown
      className="building-filter"
      options={buildingOptions}
      value={value}
      onChange={onChange}
    />
  )
}
