import { PropsWithChildren } from "react"

import { useCheckForSWUpdates } from "../hooks/useCheckForSWUpdates"
import ServiceWorkerUpdateNotification from "../modals/ServiceWorkerUpdateNotification"

const AppUpdateProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { showReload, waitingWorker, reloadPage } = useCheckForSWUpdates()
  return (
    <>
      {showReload && waitingWorker && (
        <ServiceWorkerUpdateNotification reload={reloadPage} />
      )}
      {children}
    </>
  )
}

export default AppUpdateProvider
