import classNames from "classnames"
import { Link, NavLink, useHistory, useRouteMatch } from "react-router-dom"

import { portalAnalyticsURL } from "../api"
import { FEATURE_FLAGS } from "../constants"
import { useCheckForFeatureFlag } from "../hooks/useCheckForFeatureFlag"
import { useIsScheduleAvailable } from "../hooks/useIsScheduleAvailable"
import { useNavigation } from "../hooks/useNavigation"
import LogoVariant from "../screens/LogoVariant"
import { ORGANIZATION_PATHS } from "../screens/Settings/Organization/constants"
import Avatar from "./advanced/Avatar"
import Clock from "./advanced/Clock"

import { selectNeedsOnboarding } from "../redux/app/selectors"
import { useAppSelector } from "../redux/reducers"
import { selectUser } from "../redux/user/selectors"
import { formatUser, isPortalAdmin } from "../redux/user/utils"

import "./Header.sass"

type Props = {
  showNav?: boolean
  showAvatar?: boolean
  isCentered?: boolean
}

function Header({ showNav = true, showAvatar = true, isCentered }: Props) {
  const { push } = useNavigation()

  const { entry: user } = useAppSelector(selectUser)

  const isAdmin = isPortalAdmin(user)

  const isPortalMergerEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.PORTAL_MERGER,
  )

  const showSchedule = useIsScheduleAvailable()

  const isFloorPlan = useRouteMatch("/manage/floor-plan")
  const isDeleteSurvey = useRouteMatch(
    ORGANIZATION_PATHS.company_details_survey,
  )
  const needsOnboarding = useAppSelector(selectNeedsOnboarding)

  const { location } = useHistory()
  const isVisitor = location.pathname.startsWith("/manage/visitor")

  const { isLoaded, showWeekends } = useAppSelector((state) => ({
    isLoaded: state.user.isLoaded,
    showWeekends: state.app.showWeekends,
  }))

  const headerClassName = classNames({
    Header: true,
    isConstrained: (!showWeekends && !isFloorPlan) || isVisitor,
    isCentered,
  })

  if (isDeleteSurvey) {
    showNav = false
    showAvatar = false
  }

  function handleHomeClick() {
    push("/manage")
  }

  return (
    <>
      <div className={headerClassName}>
        <div className="row">
          <div className="logo-box" onClick={handleHomeClick}>
            <LogoVariant variant="landscape" />
          </div>

          {showNav && !needsOnboarding && (
            <div className="nav">
              {showSchedule && <NavLink to="/manage">Schedule</NavLink>}
              {isAdmin && isPortalMergerEnabled && (
                <a target="_blank" rel="noreferrer" href={portalAnalyticsURL()}>
                  Analytics
                </a>
              )}
              <NavLink to="/settings">Settings</NavLink>
            </div>
          )}

          {!isCentered && <div className="stretch" />}

          {showAvatar && (
            <>
              <Clock />
              {isLoaded && (
                <Link className="user" to={"/settings/profile"} title="Log out">
                  <Avatar user={user} size="medium" />
                  <span className="name">{formatUser(user)}</span>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Header
