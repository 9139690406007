import React from "react"
import { useCallback } from "react"

import dayjs from "dayjs"
import { saveAs } from "file-saver"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { RangeFromTo } from "../../types/sharedTypes"
import { DatePicker } from "../basic/DatePicker"
import Field from "../Field"
import ModalForm from "./ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import { fetchReservationsCSV } from "../../redux/reservations/reservationsSlice"
import { useActions } from "../../redux/utils"

import "./ExportForm.sass"

type Props = {
  range: RangeFromTo
}

type FormValues = {
  start: Date
  end: Date
}

const ExportForm = ({ range }: Props) => {
  const { closeModal } = useModals()
  const { t } = useTranslation()

  const methods = useForm<FormValues>({
    defaultValues: {
      start: range.from.toDate(),
      end: range.to.toDate(),
    },
  })

  const {
    control,
    formState: { isSubmitting },
    watch,
  } = methods

  const actions = useActions({
    downloadCSV: (start: string, end: string) =>
      fetchReservationsCSV({
        start,
        end,
      }),
  })

  const onSubmit = useCallback(
    async ({ start, end }: FormValues) => {
      const startStr = dayjs(start).format("YYYY-MM-DD")
      const endStr = dayjs(end).format("YYYY-MM-DD")
      const csv = await actions.downloadCSV(startStr, endStr)

      if (fetchReservationsCSV.fulfilled.match(csv)) {
        const blob = new Blob([csv.payload], {
          type: "text/plain;charset=utf-8",
        })

        await saveAs(
          blob,
          `${t(
            "desktop.manage.download_form.report",
          )} ${startStr} - ${endStr}.csv`,
        )

        closeModal()
      }
    },
    [actions, t, closeModal],
  )

  return (
    <>
      <FormProvider {...methods}>
        <ModalForm<FormValues>
          className="ExportForm"
          onCreate={onSubmit}
          title={t("desktop.manage.download_form.title")}
          createButtonText={t("general.download")}
        >
          <Field
            control={control}
            className="double-date-field"
            name="start"
            label={t("desktop.manage.download_form.from")}
          >
            {(props) => (
              <DatePicker
                {...props}
                maxDate={watch("end") ?? undefined}
                disabled={isSubmitting}
              />
            )}
          </Field>
          <Field
            control={control}
            className="double-date-field"
            name="end"
            label={t("desktop.manage.download_form.to")}
          >
            {(props) => (
              <DatePicker
                {...props}
                minDate={watch("start") ?? undefined}
                disabled={isSubmitting}
              />
            )}
          </Field>
        </ModalForm>
      </FormProvider>
    </>
  )
}

export default ExportForm
