import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"

import Loader from "../basic/Loader"
import NoDataFound from "../NoDataFound"

import { reservationDateStringFormat } from "../../redux/reservations/utils"

import "./ReservationTable.sass"

type TableHeadProps = {
  day: Dayjs
}

function TableHead({ day }: TableHeadProps) {
  return (
    <th>
      <div
        className={classNames({
          Day: true,
          today: dayjs(day).isSame(dayjs(), "day"),
        })}
      >
        <span className="day">{dayjs(day).format("ddd DD MMM")}</span>
      </div>
    </th>
  )
}

/**
 * Represents an entry data that has the 'date' string property,
 * used for comparison and day detection
 */
export interface TableDataEntryDate {
  date: string
}

export type ReservationTableRow<
  HeaderData,
  TableData extends TableDataEntryDate,
> = {
  /**
   * Data type that represents the head of the row
   */
  header: HeaderData
  /**
   * Data for each single day inside the row
   */
  data: TableData[]
}

type Props<RowHeader, Data extends TableDataEntryDate> = {
  /**Day from which starting rendering the week */
  weekStartDay: Dayjs
  /**Array of data to display. Data must conform to the indicated in the interface */
  entries: ReservationTableRow<RowHeader, Data>[]
  /**
   * Return a component that will be rendered for the given table row
   */
  renderHead: (d: { row: RowHeader; index: number }) => JSX.Element
  /**
   * Return the content of the cell at the day passed as parameter.
   * Callback provide all the contextual data for the given day and row
   */
  renderCell?: (d: {
    data: Data[]
    day: Dayjs
    row: RowHeader
    rowIndex: string
  }) => JSX.Element

  /**
   * Call to render the top left cell of the table used as decorator
   */
  renderTableHeadDecorator?: () => JSX.Element
  /**Provide a Component that renders at the bottom of every column (day)
   * All the data related to that day are passed to the component, to make eventual aggregation
   * Data passed as parameter are all the data from all rows for the corresponding day
   */
  renderSummary?: (d: {
    day: Dayjs
    data: Data[]
    index: number
  }) => JSX.Element
  noResultsMessage?: JSX.Element
  /**Show loading indicator */
  isLoading?: boolean
  showWeekends?: boolean
  showSummary?: boolean
  /**Element that shows pagination information */
  pagination?: JSX.Element
  startCount?: number
}

export default function ReservationTable<
  RowHeader,
  Data extends TableDataEntryDate,
>({
  weekStartDay: weekStart,
  renderHead,
  renderCell,
  renderSummary,
  renderTableHeadDecorator,
  entries = [],
  noResultsMessage,
  isLoading = false,
  showWeekends = false,
  showSummary = true,
  pagination,
  startCount = 0,
}: Props<RowHeader, Data>) {
  let days: Dayjs[] = []
  const dayCount = showWeekends ? 7 : 5

  for (let i = 0; i < dayCount; i++) {
    const day = dayjs(weekStart).add(i, "day")
    days.push(day)
  }

  const tableClassName = classNames({
    ReservationTable: true,
    isLoading: !!isLoading,
    "show-pagination": !!pagination,
  })

  return (
    <div className={tableClassName}>
      <table>
        <thead>
          <tr>
            <th>{renderTableHeadDecorator && renderTableHeadDecorator()}</th>

            {days.map((day, i) => (
              <TableHead day={day} key={`th-${i}`} />
            ))}
          </tr>
        </thead>

        <tbody>
          {isLoading && (
            <tr className="loader-container">
              <td colSpan={showWeekends ? 8 : 6}>
                <Loader className="loader" />
              </td>
            </tr>
          )}

          {entries.length === 0 &&
            !isLoading &&
            (noResultsMessage ? (
              <tr className="error error-no-results">
                <td colSpan={showWeekends ? 8 : 6}>{noResultsMessage}</td>
              </tr>
            ) : (
              <tr className="error error-no-results">
                <td colSpan={showWeekends ? 8 : 6}>
                  <NoDataFound />
                </td>
              </tr>
            ))}

          {!isLoading &&
            entries.map((entry, i) => (
              <tr
                className={classNames({
                  row: true,
                  "first-row": i === 0,
                  "last-row": i + 1 === entries.length,
                })}
                key={`row-${i}`}
              >
                <td className="desk-column">
                  {renderHead({
                    row: entry.header,
                    index: startCount + i,
                  })}
                </td>

                {days.map((day, j) => {
                  return (
                    <td key={`day-${i}-${j}`}>
                      {!!renderCell &&
                        renderCell({
                          data: entry.data.filter(
                            (d) => d.date === reservationDateStringFormat(day),
                          ),
                          day,
                          row: entry.header,
                          rowIndex: `${i}-${j}`,
                        })}
                    </td>
                  )
                })}
              </tr>
            ))}
        </tbody>

        {!isLoading && entries.length > 0 && (
          <tfoot>
            {showSummary && (
              <tr className="summary-row">
                <td />
                {days.map(
                  (day, i) =>
                    !!renderSummary &&
                    renderSummary({
                      day,
                      data: entries
                        .flatMap((e) => e.data)
                        .filter(
                          (d) => d.date === reservationDateStringFormat(day),
                        ),
                      index: i,
                    }),
                )}
              </tr>
            )}

            {pagination && (
              <tr className="pagination-row">
                <td colSpan={showWeekends ? 8 : 6}>{pagination}</td>
              </tr>
            )}
          </tfoot>
        )}
      </table>
    </div>
  )
}
