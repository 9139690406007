import dayjs from "dayjs"

import { ScheduleReservation } from "./types/sharedTypes"

import { Settings } from "./redux/settings/types"
import { MeResponse } from "./redux/user/types"

export class ReservationCheckinUtils {
  /**
   * Perform checks that tells if we should enable checkin or checkout
   * for the given reservation
   */
  static isEnabled(
    type: "checkin" | "checkout",
    settings: Settings | null,
    user?: MeResponse,
    reservation?: ScheduleReservation,
  ): boolean {
    if (!reservation) {
      return false
    }

    // Is the booking owned by the logged in user?
    if (user && reservation.user.email !== user.email) {
      return false
    }

    // Is company checkin enabled?
    const {
      desk_check_in_enabled,
      desk_checkin_period,
      desk_mandatory_check_in,
    } = settings ?? {}

    if (!desk_check_in_enabled) {
      return false
    }

    if (type === "checkin") {
      // Has already checkout?
      if (
        reservation.checked_in?.check_out_at ||
        reservation.checked_in?.check_in_at
      ) {
        return false
      }

      // Is expired?
      const startDate = dayjs(reservation.start)
      const endDate = dayjs(reservation.end)
      const expirationDate = startDate.add(desk_checkin_period!, "second")
      const isSameDay = startDate.isSame(dayjs(), "date")
      const isExpired =
        (desk_mandatory_check_in && dayjs().isAfter(expirationDate)) ||
        dayjs().isAfter(endDate)

      if (isExpired || !isSameDay) {
        return false
      }
    } else if (type === "checkout") {
      // Has reservation checked in?
      if (!reservation.checked_in?.check_in_at) {
        return false
      }

      // Has reservatuin already been checked out?
      if (reservation.checked_in?.check_out_at) {
        return false
      }
    }

    return true
  }
}
