import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  useRef,
  useState,
} from "react"

import classNames from "classnames"

import { ReactComponent as MinusSVG } from "../../../assets/images/icons/Minus.svg"
import { ReactComponent as PlusSVG } from "../../../assets/images/icons/Plus.svg"

import "./style.sass"

type CommonProps = {
  disabled?: boolean
  className?: string
  onChange?: (value: number) => void
  maxValue?: number
  minValue?: number
  value?: number
  step?: number
  hasError?: boolean
}
type ConditionalProps = HTMLProps<HTMLInputElement>

export type InputNumberProps = CommonProps & Omit<ConditionalProps, "onChange">

enum Direction {
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
}

export const InputNumber = forwardRef<HTMLDivElement, InputNumberProps>(
  (
    {
      className,
      onChange,
      disabled,
      minValue = Number.MIN_SAFE_INTEGER,
      maxValue = Number.MAX_SAFE_INTEGER,
      value,
      step = 1,
      hasError,
      ...props
    },
    forwardedRef,
  ) => {
    const ref = useRef<HTMLInputElement>(null)
    const [innerValue, setInnerValue] = useState(value ?? 0)

    const innerOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInnerValue(parseFloat(e.target.value))
    }

    const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
      let newValue = parseFloat(e.target.value)
      newValue = Math.min(maxValue, Math.max(minValue, newValue))
      setInnerValue(newValue)
      onChange && onChange(newValue)
    }

    const updateValue = (direction: Direction) => {
      if (!ref.current) {
        return
      }

      const currentValue = Number(ref.current.value)

      let newValue = 0

      if (direction === Direction.INCREMENT) {
        newValue = currentValue + step
      } else if (direction === Direction.DECREMENT) {
        newValue = currentValue - step
      }

      newValue = Math.min(maxValue, Math.max(minValue, newValue))

      if (newValue !== undefined) {
        setInnerValue(newValue)
        onChange && onChange(newValue)
      }
    }

    const isMinDisabled = value !== undefined && value <= minValue
    const isMaxDisabled = value !== undefined && value >= maxValue

    return (
      <div
        ref={forwardedRef}
        className={classNames("InputNumber", className, {
          disabled: !!disabled,
          error: hasError,
        })}
      >
        <button
          className="NumberMinus"
          type="button"
          disabled={disabled || isMinDisabled}
          onClick={() => updateValue(Direction.DECREMENT)}
        >
          <MinusSVG />
        </button>
        <input
          {...(props as HTMLProps<HTMLInputElement>)}
          ref={ref as ForwardedRef<HTMLInputElement>}
          disabled={disabled}
          className="InputNumberField"
          type="number"
          min={minValue}
          max={maxValue}
          step={step}
          value={innerValue}
          onChange={innerOnChange}
          onBlur={onBlur}
          onClick={() => ref.current?.select()} // Highlight the whole input field for better UX
        />
        <button
          className="NumberPlus"
          type="button"
          disabled={disabled || isMaxDisabled}
          onClick={() => updateValue(Direction.INCREMENT)}
        >
          <PlusSVG />
        </button>
      </div>
    )
  },
)

export default InputNumber
