import { isBlank } from "../utils"

import "./BranchName.sass"

/**
 * Will display the branch name in the upper right corner if it exists in the env.
 */
export const BranchName = () => {
  if (isBlank(process.env.REACT_APP_BRANCH_NAME)) return null

  return <span className="BranchName">{process.env.REACT_APP_BRANCH_NAME}</span>
}
