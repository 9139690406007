import React from "react"

import dayjs from "dayjs"

import { License } from "../redux/users/types"

import "./LicenseIndicator.sass"

const LICENSE_NAME = "Desk & asset"

type LicenseIndicatorProps = {
  license?: License | null
}

const LicenseIndicator = ({ license }: LicenseIndicatorProps) => {
  if (license === undefined) {
    return null
  }

  const expiresAt = license?.expires_at ? dayjs(license.expires_at) : null

  if (license === null || (expiresAt && dayjs().isAfter(expiresAt))) {
    const expired = expiresAt ? ` / expired ${expiresAt.format("ddd, ll")}` : ""

    return (
      <div className="license-indicator">
        <div className="indicator inactive" />
        <div>{`${LICENSE_NAME} inactive${expired}`}</div>
      </div>
    )
  }

  const expires = expiresAt ? ` / expires ${expiresAt.format("ddd, ll")}` : ""

  return (
    <div className="license-indicator">
      <div className="indicator" />
      <div>{`${LICENSE_NAME} active${expires}`}</div>
    </div>
  )
}

export default LicenseIndicator
