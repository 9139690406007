import { ThunkApiConfig } from "RootType"

import { resetPosthog } from "../../analytics"
import {
  invalidateAuthToken,
  loginURL,
  oAuthAuthorizeURL,
  oAuthCallbackURL,
  postForm,
  postJSON,
  requestLoginURL,
  tabletAuthenticateURL,
  tokenURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import { getErrorObject } from "../reduxUtils"
import { AuthenticateTabletRequest } from "../tablet/types"
import { AuthResponse } from "./types"
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit"

const CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_OAUTH_CLIENT_SECRET

const PWA_CLIENT_ID = process.env.REACT_APP_OAUTH_PWA_CLIENT_ID
const PWA_CLIENT_SECRET = process.env.REACT_APP_OAUTH_PWA_CLIENT_SECRET

/**
 *  Thunks
 */
export const fetchAuthToken = createAsyncThunk<
  AuthResponse,
  void,
  ThunkApiConfig
>("auth/fetchAuthToken", async (_, { getState, rejectWithValue }) => {
  const {
    auth: { code },
  } = getState()

  const body = {
    grant_type: "authorization_code",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    code: code,
    redirect_uri: oAuthCallbackURL(),
  }

  const response = await postForm(tokenURL(), { body })
  const json = await response.json()

  if (response.ok) {
    return json
  }

  return rejectWithValue({ error: true, payload: json })
})

export const authenticateTablet = createAsyncThunk<
  AuthResponse,
  AuthenticateTabletRequest,
  ThunkApiConfig
>("auth/authenticateTablet", async (body, { rejectWithValue }) => {
  const response = await postJSON(tabletAuthenticateURL(), {
    body,
  })

  const json = await response.json()

  if (response.ok) {
    return json
  }

  return rejectWithValue({ error: true, payload: json })
})

export const requestLogin = createAsyncThunk<
  string,
  string,
  ThunkApiConfig<ResponseError>
>("auth/requestLogin", async (email, { rejectWithValue }) => {
  const body = {
    email: email,
  }

  const response = await postForm(requestLoginURL(), { body })

  if (response.ok) {
    return email
  }

  return rejectWithValue(await getErrorObject(response))
})

export const postLogin = createAsyncThunk<AuthResponse, string, ThunkApiConfig>(
  "auth/postLogin",
  async (password, { getState, rejectWithValue }) => {
    const {
      auth: { email },
    } = getState()

    const body = {
      grant_type: "password",
      client_id: PWA_CLIENT_ID,
      client_secret: PWA_CLIENT_SECRET,
      username: email,
      password: password,
    }

    const response = await postForm(loginURL(), { body })
    const json = await response.json()

    if (response.ok) {
      return json
    }

    return rejectWithValue({ error: true, payload: json })
  },
)

/**
 * Currently the function will invalidate the token in
 * case of the mobile app, otherwise it simply clears all
 * local state.
 */
export const logout = createAsyncThunk<void, void, ThunkApiConfig>(
  "auth/logout",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const {
      auth: { access_token, refresh_token },
    } = getState()

    if (access_token && refresh_token) {
      const response = await postJSON(
        invalidateAuthToken(),
        { body: {} },
        access_token,
      )

      if (response.ok) {
        dispatch(clearAuth())
        resetPosthog()
        return
      }

      return rejectWithValue({ error: true })
    }

    dispatch(clearAuth())

    return
  },
)

export const fetchAuthCode = () => () => {
  window.location.href = oAuthAuthorizeURL()
}

/**
 *  Slice
 */
export type AuthReducerState = {
  email: string | null
  code: string | null
  access_token: string | null
  refresh_token: string | null
  error: boolean | string | null
  isLogin: boolean
}

const initialState: AuthReducerState = {
  email: null,
  code: null,
  access_token: localStorage.getItem("access_token"),
  refresh_token: localStorage.getItem("refresh_token"),
  error: false,
  isLogin: false,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: (state) => {
      if (localStorage) {
        localStorage.clear()
      }

      state.access_token = null
      state.refresh_token = null
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      const { payload } = action

      state.isLogin = payload
    },
    fetchEmailSuccess: (state, action: PayloadAction<string>) => {
      const { payload } = action

      state.email = payload
    },
    fetchAuthCodeSuccess: (state, action: PayloadAction<string>) => {
      const { payload } = action

      state.code = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuthToken.rejected, (state) => {
      state.code = null
      state.error = true
    })
    builder.addCase(authenticateTablet.rejected, (state) => {
      state.code = null
      state.error = true
    })
    builder.addCase(requestLogin.rejected, (state) => {
      state.email = null
      state.error = true
    })
    builder.addCase(requestLogin.fulfilled, (state, action) => {
      const { payload } = action

      state.email = payload
      state.error = false
    })
    builder.addCase(postLogin.rejected, (state) => {
      state.error = true
    })
    builder.addMatcher(
      isAnyOf(
        fetchAuthToken.fulfilled,
        postLogin.fulfilled,
        authenticateTablet.fulfilled,
      ),
      (state, action) => {
        const { payload } = action

        const { access_token, refresh_token } = payload

        if (!access_token || !refresh_token) {
          return
        }

        if (localStorage) {
          localStorage.setItem("access_token", access_token)
          localStorage.setItem("refresh_token", refresh_token)
        }

        state.access_token = access_token
        state.refresh_token = refresh_token
        state.code = null
        state.error = false
      },
    )
  },
})

export const authReducer = authSlice.reducer
export const {
  clearAuth,
  setIsLogin,
  fetchEmailSuccess,
  fetchAuthCodeSuccess,
} = authSlice.actions
