import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { RoomDeviceResponse } from "../../redux/api/devices/types"

type Props = {
  value: string
  onChange: (v: string) => void
}

const DeviceWifiStatusValues = {
  STRONG_WIFI: "STRONG_WIFI",
  WEAK_WIFI: "WEAK_WIFI",
  DISCONNECTED: "DISCONNECTED",
}

export const { STRONG_WIFI, WEAK_WIFI, DISCONNECTED } = DeviceWifiStatusValues

export const filterDevicesByWifiLvlConnection = (
  value: string,
  devices: RoomDeviceResponse[],
) => {
  switch (value) {
    case DISCONNECTED:
      return devices.filter((d) => d.wifi_quality === null)
    case WEAK_WIFI:
      return devices.filter(
        (d) =>
          d.wifi_quality !== null && d.wifi_quality >= 1 && d.wifi_quality < 60,
      )
    case STRONG_WIFI:
      return devices.filter(
        (d) => d.wifi_quality !== null && d?.wifi_quality >= 60,
      )
    default:
      return devices
  }
}

const DeviceWiFiFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const options = [
    {
      label: t("desktop.settings.rooms.devices.filters.wifi.all"),
      value: FilterSpecialValues.ALL,
    },
    ...Object.values(DeviceWifiStatusValues).map((value) => ({
      label: t(
        `desktop.settings.rooms.devices.filters.wifi.${value.toLowerCase()}` as ParseKeys,
      ),
      value,
    })),
  ]

  return (
    <Dropdown
      className="device-wifi-filter"
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export default DeviceWiFiFilter
