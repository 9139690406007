import { Children } from "../../types/sharedTypes"

import { ReactComponent as PencilSVG } from "../../assets/images/icons/Pencil.svg"

import "./ValueSelect.sass"

type ValueSelectProps = {
  value: Children
  onClick: () => void
}

const ValueSelect = ({ value, onClick }: ValueSelectProps) => {
  return (
    <div className="ValueSelect" onClick={onClick}>
      <div className="value">{value}</div>
      <PencilSVG />
    </div>
  )
}

export default ValueSelect
