import dayjs from "dayjs"
import { ParseKeys } from "i18next"

import { PLAN_GROUPS } from "../../../redux/api/billing/constants"
import { PlanGroup } from "../../../redux/api/billing/types"
import { Solutions, SolutionTypes } from "../../../redux/api/solutions/types"

/**
 * Checks if a trial is available for a given solution.
 * @param solution - The solution to check for trial availability.
 * @param solutions - The solutions object containing information about active solutions and trial end dates.
 * @returns A boolean indicating whether a trial is available for the given solution.
 */
export const hasTrialAvailable = (
  solution?: SolutionTypes,
  solutions?: Solutions,
): boolean => {
  if (!solutions || !solution) {
    return true
  }

  if (solutions[solution].active === false && !solutions[solution].trial_end) {
    return true
  }

  if (
    solutions[solution].active === false &&
    solutions[solution].trial_end &&
    dayjs().isBefore(solutions[solution].trial_end ?? "")
  ) {
    return true
  }

  return false
}

/**
 * Converts a solution type to a plan group.
 * @param solution - The solution type.
 * @returns The corresponding plan group.
 */
export const solutionToPlanGroup = (solution: SolutionTypes) => {
  const planGroupMap: Record<SolutionTypes, PlanGroup> = {
    room: PLAN_GROUPS.ROOM,
    desk: PLAN_GROUPS.DESK_BOOKING,
    visitor: PLAN_GROUPS.VISITOR,
    sign: PLAN_GROUPS.SIGN,
    home: PLAN_GROUPS.HOME,
  }

  return planGroupMap[solution] ?? PLAN_GROUPS.ROOM
}

/**
 * Retrieves the translation key for a given solution type.
 * @param solution - The solution type.
 * @returns The translation key for the solution type.
 */
export const getSolutionNameTranslationKey = (solution?: SolutionTypes) => {
  const translationKeys: Record<SolutionTypes, ParseKeys> = {
    room: "desktop.components.subscription_card.joan_room",
    desk: "desktop.components.subscription_card.joan_desk_asset",
    visitor: "desktop.components.subscription_card.joan_visitor",
    sign: "desktop.components.subscription_card.joan_sign",
    home: "desktop.components.subscription_card.joan_home",
  }

  return solution
    ? translationKeys[solution] ?? "general.not_found.standard"
    : "general.not_found.standard"
}

// TODO: what happens for JoD and JOT here?
/**
 * Converts a plan group to a corresponding solution type.
 * @param planGroup The plan group to convert.
 * @returns The corresponding solution type.
 */
export const planGroupToSolutionType = (
  planGroup: PlanGroup,
): SolutionTypes => {
  const solutionMap: Record<PlanGroup, SolutionTypes> = {
    [PLAN_GROUPS.ROOM]: "room",
    [PLAN_GROUPS.DESK_BOOKING]: "desk",
    [PLAN_GROUPS.VISITOR]: "visitor",
    [PLAN_GROUPS.SIGN]: "sign",
    [PLAN_GROUPS.HOME]: "home",
    [PLAN_GROUPS.JOAN_ON_DISPLAY]: "room",
    [PLAN_GROUPS.JOAN_ON_TABLET]: "room",
    [PLAN_GROUPS.DEPRECATED]: "room",
  }

  return solutionMap[planGroup] ?? "room"
}
