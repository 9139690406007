import { useTranslation } from "react-i18next"

import { LabelPaths } from "../../constants"
import { getLabel } from "../../utils"
import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { DEVICE_TYPES } from "../../redux/api/devices/constants"

type Props = {
  value: string
  onChange: (v: string) => void
}

const DeviceTypeFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const options = [
    {
      label: t("desktop.settings.rooms.devices.filters.types.all_devices"),
      value: FilterSpecialValues.ALL,
    },
    ...Object.entries(DEVICE_TYPES).map(([key, value]) => ({
      label: getLabel(`deviceTypes.${key}` as LabelPaths),
      value,
    })),
  ]

  return (
    <Dropdown
      className="device-type-filter"
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export default DeviceTypeFilter
