import {
  objectToOptions,
  objectValuesToOptions,
  randomizeArray,
  recordToOptions,
} from "../../../utils"

export const JOB_TITLES = {
  OPERATIONS: "Operations",
  IT_ADMIN: "IT Admin",
  OFFICE_MANAGER: "Office Manager",
  FACILITY_MANAGER: "Facility Manager",
  HUMAN_RESOURCES: "Human resources",
  FOUNDER_OR_CEO: "Founder / CEO",
  FINANCE: "Finance",
  MARKETING: "Marketing",
  PRODUCT_DESIGN: "Product Design",
  INTERNAL_COMMUNICATION: "Internal Communication",
  SALES: "Sales",
  ENGINEERING: "Engineering",
  PRODUCT_MANAGEMENT: "Product Management",
  STUDENT: "Student",
  OTHER: "Other",
}

export const JOB_TITLES_OPTIONS = randomizeArray(
  objectValuesToOptions(JOB_TITLES),
)

export const JOB_ROLES = {
  EXECUTIVE: "Executive (C-level/VP)",
  DEPARTMENT_LEAD: "Department lead",
  TEAM_MANAGER: "Team Manager",
  TEAM_MEMBER: "Team Member",
  FREELANCER: "Freelancer",
  MYSELF: "Using just for myself",
} as const

export const JOB_ROLES_OPTIONS = randomizeArray(
  objectValuesToOptions(JOB_ROLES),
)

export const DISCOVERIES = {
  FRIEND_OR_WORD_OF_MOUTH: {
    value: "Friend / Word of mouth",
    label: "Word of mouth (friend, work)",
  },
  GOOGLE: { value: "Google (Search engine)", label: "Google (Search engine)" },
  YOUTUBE: { value: "Youtube", label: "Youtube" },
  LINKEDIN: { value: "LinkedIn", label: "LinkedIn" },
  TWITTER: { value: "Twitter", label: "Twitter" },
  FACEBOOK_OR_INSTAGRAM: {
    value: "Facebook / Instagram",
    label: "Facebook / Instagram",
  },
  PODCAST_OR_RADIO: { value: "Podcast / Radio", label: "Podcast / Radio" },
  G2: { value: "G2", label: "G2" },
  OTHER: { value: "Other", label: "Other" },
} as const

export const DISCOVERIES_OPTIONS = randomizeArray(objectToOptions(DISCOVERIES))

export const NONE_OF_THOSE = "NONE_OF_THOSE"

export const USE_CASES = {
  ROOMS: "Manage Meeting Rooms",
  ASSETS: "Manage Company Assets",
  DESKS: "Manage Desk Reservations",
  VISITORS: "Manage Visitors",
  [NONE_OF_THOSE]: "None of those",
} as const

export type UseCases = keyof typeof USE_CASES

export const isUseCase = (value: string): value is UseCases =>
  Object.keys(USE_CASES).includes(value)

export const USE_CASES_OPTIONS = recordToOptions(USE_CASES)
