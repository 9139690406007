import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useRouteMatch } from "react-router-dom"

import { PERMISSIONS } from "../../../constants"
import { timeZone } from "../../../dayjs"
import { useCalculateBookable } from "../../../hooks/useCalculateBookable"
import { useCheckForPermission } from "../../../hooks/useCheckForPermission"
import useCheckReservationWindowLength from "../../../hooks/useCheckReservationWindowLength"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import DeskBookingModal from "../../../modals/DeskBookingModal"
import { parseQueryWithDefault, updateHistory } from "../../../utils"
import { BUILDINGS_PATHS } from "../../Settings/Buildings/constants"
import DeskList from "./DeskList"
import FloorPlanFilters, { Filter } from "./Filters"
import {
  FLOOR_PLAN_SCHEDULE_PATHNAME,
  useFloorPlanContext,
} from "./FloorPlanProvider"
import Header from "./Header"
import { DeskWithReservations } from "./types"
import { useModals } from "@mattjennings/react-modal-stack"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { useLazyFetchDesksQuery } from "../../../redux/api/desks"
import {
  useFetchFloorQuery,
  useFetchFloorsQuery,
} from "../../../redux/api/floors"
import { selectAppDates } from "../../../redux/app/selectors"
import { fetchDesksSchedule } from "../../../redux/desk_schedule/deskScheduleSlice"
import { selectDeskSchedule } from "../../../redux/desk_schedule/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"

import Card from "../../../components/basic/Card"
import Loader from "../../../components/basic/Loader"
import { FilterSpecialValues } from "../../../components/Filter/types"
import { createScheduleOption } from "../../../components/Form/options"
import Map from "../../../components/Map"
import NoDataFound from "../../../components/NoDataFound"
import OccupancyInfo from "../../../components/OccupancyInfo"
// import { OccupancyWarning } from "../../../components/OccupancyWarning"
import Place from "../../../components/Place"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./style.sass"

export const ENTRIES_PER_PAGE = 1000
export const FILTER_LOCAL_STORE_NAME = "manage-floor-plan_filters"

type Params = {
  id?: string
}

type Props = {
  add?: boolean
}

const MissingValueCard = ({
  type,
}: {
  type: "building" | "floor" | "floorPlan"
}) => {
  const { t } = useTranslation()

  const canAddFloorPlans = useCheckForPermission(
    PERMISSIONS.floorPlans.canAddFloorPlan,
  )
  const canAddFloor = useCheckForPermission(PERMISSIONS.floorPlans.canAddFloor)
  const canAddBuilding = useCheckForPermission(
    PERMISSIONS.buildings.canAddBuilding,
  )

  const noPermissionsText = t("desktop.manage.floor_plan.no_permissions")

  let permission, title, linkText, pathDescriptor, path

  switch (type) {
    case "building":
      permission = canAddBuilding
      title = t("desktop.manage.floor_plan.no_buildings_configured")
      linkText = t("desktop.manage.floor_plan.add_new_buildings")
      pathDescriptor = "Settings / Buildings"
      path = BUILDINGS_PATHS.add
      break
    case "floor":
      permission = canAddFloor
      title = t("desktop.manage.floor_plan.no_floors_configured")
      linkText = t("desktop.manage.floor_plan.add_new_floors")
      pathDescriptor = "Settings / Floor plans"
      path = "/settings/floor-plans"
      break
    default:
      permission = canAddFloorPlans
      title = t("desktop.manage.floor_plan.no_floor_plan_configured")
      linkText = t("desktop.manage.floor_plan.add_new_floor_plan")
      pathDescriptor = "Settings / Floor plans"
      path = "/settings/floor-plans"
      break
  }

  return (
    <NoDataFound warning className="floor-plan-missing">
      <p className="error">
        {title}
        <br />
        {permission ? (
          <>
            {linkText}{" "}
            <Link
              to={{
                pathname: path,
              }}
            >
              {pathDescriptor}
            </Link>
            .
          </>
        ) : (
          <>{noPermissionsText}</>
        )}
      </p>
    </NoDataFound>
  )
}

/**
 * Component: ManageFloorPlan
 */
const ManageFloorPlan = ({ add = false }: Props) => {
  const { currentDate } = useAppSelector(selectAppDates)
  const currentDateRef = useRef(currentDate.toISOString())
  const { schedule, isLoading: isDeskScheduleLoading } =
    useAppSelector(selectDeskSchedule)
  const { entry: currentUser } = useAppSelector(selectUser)
  const {
    entry: { building },
  } = useAppSelector(selectUser)
  const [
    fetchDesks,
    {
      data: { results: desks = [] } = {},
      isFetching: isDeskLoading = false,
    } = {},
  ] = useLazyFetchDesksQuery()

  const desk_reservation_window_length = useCheckReservationWindowLength({
    globalSettings: true,
  })
  const today = dayjs().format("YYYY-MM-DD")
  const lastDay = dayjs(today)
    .add(useCheckReservationWindowLength() ?? 7, "day")
    .endOf("day")

  const isAdminManager =
    isOfficeManager(currentUser) || isPortalAdmin(currentUser)
  const canBookDesks = isAdminManager || currentDate < lastDay

  const isLoading = isDeskLoading && isDeskScheduleLoading

  const defaultFilter: Filter = {
    department_id: FilterSpecialValues.ALL,
    building: building ? building.id : FilterSpecialValues.ALL,
    floor: FilterSpecialValues.ALL,
    amenity_id: [FilterSpecialValues.ALL],
  }

  const [storedFilterValues, saveFilter] = useStoredFilter({
    filterName: FILTER_LOCAL_STORE_NAME,
    defaultFilterValues: defaultFilter,
  })

  if (typeof storedFilterValues.amenity_id === "string") {
    storedFilterValues.amenity_id = [storedFilterValues.amenity_id]
  }

  const reqParams = useRef<Filter>(storedFilterValues)
  const [isPanningDisabled, setPanningDisabled] = useState(false)
  const [filters, setFilters] = useState<Filter>(storedFilterValues)
  const [users, setUsers] = useState<string[]>([])

  const { data: floor = null, isLoading: floorIsLoading } = useFetchFloorQuery(
    filters.floor !== FilterSpecialValues.ALL
      ? {
          id: filters.floor,
          stats: true,
        }
      : skipToken,
  )

  const hasCapacityLimit = floor?.capacity_limit || floor?.capacity_limit === 0
  const mainFloorPlanContentClasses = classNames("main-floorplan-content", {
    "capacity-limit": hasCapacityLimit,
  })

  const {
    clearHoveredDesk,
    setHoveredDesk,
    hoveredDesk,
    setOpenedDesk,
    createNewReservation,
    clearOpenedDesk,
    openedDesk,
  } = useFloorPlanContext()
  /**
   * Hooks
   */
  const {
    params: { id },
  } = useRouteMatch<Params>()
  const { openModal, closeAllModals } = useModals()
  const history = useHistory()
  const { search, pathname } = history.location

  const desksWithReservations: DeskWithReservations[] = useMemo(() => {
    return desks
      .map((d) => {
        const reservations =
          schedule.find((s) => s.id === d.id)?.schedule[0]?.reservations ?? []
        return { ...d, reservations }
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  }, [desks, schedule])

  const desksBookable = useCalculateBookable({
    date: currentDate.toISOString(),
    floorId: filters.floor,
    departmentId: filters.department_id,
  })
  /**
   * This allows the user to search for different users and highlight their reservations.
   */
  const desksWithReservationsHighlighted = useMemo(() => {
    return desksWithReservations.map((desk) => {
      if ((users?.length ?? 0) < 1)
        return { ...desk, isDisabled: !desksBookable[desk.id] }

      const wasMadeBySearchedUser = desk.reservations.find((reservation) => {
        return users.find((user) => user === reservation.user.email)
      })

      if (wasMadeBySearchedUser) {
        return {
          ...desk,
          highlight: true,
          isDisabled: !desksBookable[desk.id],
        }
      }

      return { ...desk, isDisabled: !desksBookable[desk.id] }
    })
  }, [desksWithReservations, users, desksBookable])

  const actions = useActions({
    fetchDesksSchedule: ({ building, floor, ...filter }: Filter, date: Dayjs) =>
      fetchDesksSchedule({
        ...filter,
        building_id: building,
        floor_id: floor,
        start: date.startOf("day").toISOString(),
        end: date.endOf("day").toISOString(),
        limit: ENTRIES_PER_PAGE,
      }),
  })

  /**
   * Handlers
   */
  const handleFilterChange = useCallback(
    async (filter: Filter) => {
      reqParams.current = filter
      updateHistory(pathname, filter)

      fetchDesks({ ...filter, limit: ENTRIES_PER_PAGE })
      actions.fetchDesksSchedule(filter, currentDate)
      setFilters(filter)
    },
    [actions, currentDate, fetchDesks, pathname],
  )

  const handleMouseOver = (id: string) => {
    setHoveredDesk(id)
    setPanningDisabled(true)
  }

  const handleMouseOut = () => {
    clearHoveredDesk()
    setPanningDisabled(false)
  }

  const handleDeskClick = (desk: DeskWithReservations) => {
    setOpenedDesk(desk.id, true)

    if (!desk.isDisabled && !desk.reservations.length && canBookDesks) {
      createNewReservation(desk)
    }
  }

  const handleModalClose = () => {
    history.push(FLOOR_PLAN_SCHEDULE_PATHNAME)
  }

  /**
   * useEffects
   */

  /**
   * Main routing logic for create and edit modal views.
   **/
  useEffect(() => {
    if (add) {
      const queryFormData = parseQueryWithDefault(search, {
        start: "",
        end: "",
        date: currentDate.toString(),
        schedule: "Once",
        desk: "",
        deskName: "",
        scheduleDate: dayjs()
          .add(desk_reservation_window_length ?? 7, "day")
          .toString(),
        tz: timeZone,
      })

      openModal(DeskBookingModal, {
        formData: {
          ...queryFormData,
          date: dayjs(queryFormData.date),
          schedule: createScheduleOption(
            queryFormData.schedule,
            queryFormData.date,
          ),
          scheduleDate: dayjs(queryFormData.scheduleDate),
        },
        onClose: handleModalClose,
      })
    } else if (id) {
      openModal(DeskBookingModal, {
        reservationId: id,
        onClose: handleModalClose,
      })
    } else {
      updateHistory(pathname, reqParams.current)
      fetchDesks({ ...reqParams.current, limit: ENTRIES_PER_PAGE })
      actions.fetchDesksSchedule(reqParams.current, currentDate)
      closeAllModals()
    }
  }, [add, id, search])

  // saves the current params into the locale store
  useEffect(
    () => () => {
      if (pathname.endsWith(FLOOR_PLAN_SCHEDULE_PATHNAME)) {
        saveFilter(reqParams.current)
      }
    },
    [saveFilter, pathname],
  )

  useEffect(() => {
    clearOpenedDesk()
  }, [filters, currentDate])

  useEffect(() => {
    const date = currentDate.toISOString()

    if (currentDateRef.current !== date) {
      actions.fetchDesksSchedule(reqParams.current, currentDate)
      currentDateRef.current = date
    }
  }, [actions, currentDate])

  const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

  const { data: { results: entries = [] } = {} } = useFetchFloorsQuery({
    building: filters.building ? filters.building : null,
  })

  const hasNoBuildings = buildings.length === 0
  const hasNoFloors = !hasNoBuildings && entries.length === 0
  const hasFloorImage = !hasNoFloors && floor && floor.image
  const hasNoFloorImage = !hasNoFloors && floor && !floor.image
  const showCapacityLimit = hasCapacityLimit && floor

  const HeaderWithFloorPlanFilters = (
    <>
      <Header />
      <FloorPlanFilters
        onChange={handleFilterChange}
        onUsersChange={setUsers}
        defaultValues={storedFilterValues}
      />
    </>
  )

  if (floorIsLoading || isLoading) {
    return (
      <View className="ManageFloorPlan">
        {HeaderWithFloorPlanFilters}

        <Loader className="loader" />
      </View>
    )
  }

  return (
    <View className="ManageFloorPlan">
      {HeaderWithFloorPlanFilters}
      {showCapacityLimit && (
        <>
          <Space size={0.25} />
          <OccupancyInfo type="Floor" floor={floor} />
        </>
      )}

      <Space size={0.5} />

      {hasNoBuildings && <MissingValueCard type="building" />}

      {hasNoFloors && <MissingValueCard type="floor" />}

      {hasNoFloorImage && <MissingValueCard type="floorPlan" />}

      {hasFloorImage && (
        <div className={mainFloorPlanContentClasses}>
          <Card isFloorPlan className="floor-plan">
            <Map
              map={floor}
              showZoomControls
              showPanControls
              isDisabled={isPanningDisabled}
              key={`building-${filters.building}-floor-{${filters.floor}}`}
            >
              {!isLoading &&
                desksWithReservationsHighlighted.map((desk, i: number) => {
                  return (
                    <Place
                      key={`seat-${i}`}
                      x={desk.coord_x}
                      y={desk.coord_y}
                      mapWidth={floor.width!}
                      mapHeight={floor.height!}
                      onClick={() => {
                        handleDeskClick(desk)
                      }}
                      onMouseOver={() => handleMouseOver(desk.id)}
                      onMouseOut={handleMouseOut}
                      title={desk.name}
                      amenities={desk.amenities}
                      departments={desk.departments}
                      reservations={desk.reservations}
                      isAvailable={(desk.reservations?.length ?? 0) === 0}
                      isOccupied={
                        !desk.isDisabled && (desk.reservations?.length ?? 0) > 0
                      }
                      isDisabled={desk.isDisabled}
                      isHovered={desk.id === hoveredDesk}
                      isFocused={desk.id === openedDesk}
                      isHighlighted={desk.highlight}
                    />
                  )
                })}
            </Map>
          </Card>
          <Card isFloorPlan className="user-list">
            {!isLoading && desksWithReservationsHighlighted.length > 0 && (
              <DeskList
                canBookDesks={canBookDesks}
                desksWithReservations={desksWithReservationsHighlighted}
              />
            )}
            {/* we will reimplement this when we have a BE ready
						{floor && (
							<OccupancyWarning
								id={filters.floor}
								company={company}
								day={currentDate}
							/>
						)} */}
          </Card>
        </div>
      )}
    </View>
  )
}

export default ManageFloorPlan
