import React, {
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react"

import classNames from "classnames"
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Button from "../advanced/Button"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import Card from "../basic/Card"
import { useModals } from "@mattjennings/react-modal-stack"

import "./PageForm.sass"

type PageActionsProps = {
  backUrl?: string
  onBack?: () => void
}

export const PageActions = ({
  children,
  backUrl,
  onBack,
}: PropsWithChildren<PageActionsProps>) => {
  const { t } = useTranslation()

  return (
    <div className="PageActions">
      <Button
        variant="link"
        to={backUrl}
        isVisible={backUrl !== undefined}
        onClick={() => onBack?.()}
      >
        {t("general.back")}
      </Button>

      <div className="spacer"></div>

      {children}
    </div>
  )
}

type Props<T extends FieldValues> = {
  className?: string
  updateMode: boolean
  onCreate?: (values: T) => Promise<void>
  onUpdate?: (values: T) => Promise<void>
  onDelete?: (e: MouseEvent) => Promise<void>
  onBack?: () => void
  additionalButton?: JSX.Element
  backUrl?: string
  disabled?: boolean
  header?: ReactNode
  footer?: ReactNode
  hide?: boolean
  deleteButtonText?: string
  submitButtonText?: string
  confirmationPrompt?: boolean
}

const PageForm = <T extends FieldValues>({
  children,
  className,
  updateMode,
  onCreate,
  onUpdate,
  onDelete,
  additionalButton,
  backUrl,
  disabled = false,
  header,
  footer,
  hide = false,
  deleteButtonText,
  submitButtonText,
  confirmationPrompt = true,
  onBack,
}: PropsWithChildren<Props<T>>) => {
  const { t } = useTranslation()
  const { openModal, closeModal } = useModals()

  const [isDeleting, setIsDeleting] = useState(false)

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext()

  const pageFormClass = classNames("PageForm", className)

  const hasAction = updateMode ? onUpdate : onCreate

  const onSubmit: SubmitHandler<T> = async (values: T) => {
    if (updateMode) {
      await onUpdate?.(values)
    } else {
      await onCreate?.(values)
    }
  }

  const handleDeleteConfirmation = (e: MouseEvent) => {
    openModal(ConfirmationModal, {
      title: t("general.confirm_action"),
      onConfirm: async () => {
        setIsDeleting(true)
        await onDelete?.(e)
        closeModal()
        setIsDeleting(false)
      },
    })
  }

  if (hide && header) {
    return (
      <form
        className={pageFormClass}
        onSubmit={handleSubmit && handleSubmit(onSubmit as any)}
      >
        <Card>
          <div className="PageHeader">{header}</div>
        </Card>
      </form>
    )
  }

  if (hide) {
    return null
  }

  const isDisabled = disabled || isDeleting || isSubmitting

  return (
    <form
      className={pageFormClass}
      onSubmit={handleSubmit && handleSubmit(onSubmit as any)}
    >
      <Card>
        {header && <div className="PageHeader">{header}</div>}
        <div className="fields">{children}</div>
        {footer && <div className="PageFooter">{footer}</div>}
      </Card>

      <PageActions backUrl={backUrl} onBack={onBack}>
        {additionalButton}

        <Button
          className="delete"
          variant="danger-pop"
          onClick={confirmationPrompt ? handleDeleteConfirmation : onDelete}
          isDisabled={isDisabled}
          isLoading={isDeleting}
          isVisible={updateMode && onDelete !== undefined}
          noConfirm
        >
          {deleteButtonText ?? t("general.delete_button")}
        </Button>

        {hasAction && (
          <Button
            isSubmit
            variant="submit"
            isDisabled={isDisabled}
            isLoading={isSubmitting}
          >
            {submitButtonText ?? t("general.save_button")}
          </Button>
        )}
      </PageActions>
    </form>
  )
}

export default PageForm
