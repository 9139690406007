import { Trans, useTranslation } from "react-i18next"

import { scimSettingsURL } from "../../../../api"
import { useCheckForFeatureFlag } from "../../../../hooks/useCheckForFeatureFlag"
import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { BILLING_PATHS } from "../../Billing/constants"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  useFetchScimAccessTokenQuery,
  useFetchScimSettingsQuery,
  useRegenerateScimAccessTokenMutation,
} from "../../../../redux/api/scim"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import { AsyncSwitch } from "../../../../components/advanced/AsyncToggle"
import Button from "../../../../components/advanced/Button"
import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import CopyToClipboard from "../../../../components/advanced/CopyToClipboard"
import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import Card from "../../../../components/basic/Card"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./Scim.sass"

const ScimIntegration = () => {
  const { push } = useNavigation()
  const { openModal, closeModal } = useModals()
  const { t } = useTranslation()
  const { data: scimSettings, refetch: refreshScimSettings } =
    useFetchScimSettingsQuery()
  const { enabled: scimEnabled } = scimSettings ?? {}
  const { data: { token } = {}, isLoading: isTokenLoading } =
    useFetchScimAccessTokenQuery()
  const [generateToken] = useRegenerateScimAccessTokenMutation({})
  const { errorToast, infoToast } = useToast()

  const hasScimFeature = useCheckForFeatureFlag("scim.isEnabled")

  const handleGenerateTokenClick = async () => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await generateTokenHandler()
        infoToast(t("desktop.settings.integrations.scim.token_generated"))
        closeModal()
      },
    })
  }

  const generateTokenHandler = async () => {
    const response = await generateToken({})

    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        errorToast(error.message)
        return
      }
    }
  }

  return (
    <View className="ScimIntegration">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.integrations.title"),
          t("desktop.settings.integrations.scim.title"),
        ]}
      />
      <Intro isConstrained>
        <Trans i18nKey="desktop.settings.integrations.scim.intro">
          <RedirectLink to="scim-kb" />
        </Trans>
      </Intro>

      <Space size={0.75} />

      <Card className="ScimToggleCard">
        <div className="ToggleContainer">
          <AsyncSwitch
            disabled={!hasScimFeature}
            type="put"
            updateMode={true}
            value={scimEnabled ?? false}
            urlGenerator={() => {
              return scimSettingsURL()
            }}
            bodyGenerator={(value) => ({
              enabled: value,
            })}
            label={t("desktop.settings.integrations.scim.enable_scim")}
            refresh={() => refreshScimSettings()}
          ></AsyncSwitch>
        </div>
        <div className="TokenContainer">
          <span className="TokenTitle">
            {t("desktop.settings.integrations.scim.secret_token")}
          </span>
          <span className="TokenDescription">
            {t("desktop.settings.integrations.scim.secret_token_description")}
          </span>

          {(!scimEnabled || !hasScimFeature || (!token && !isTokenLoading)) && (
            <Button
              isDisabled={!scimEnabled}
              className="TokenButton"
              onClick={handleGenerateTokenClick}
            >
              {t("desktop.settings.integrations.scim.generate_token")}
            </Button>
          )}

          {scimEnabled && hasScimFeature && token && !isTokenLoading && (
            <div className="ExistingTokenContainer">
              <CopyToClipboard
                text={token}
                successText={t("desktop.settings.integrations.scim.copied")}
              />
              <Button
                className="RegenerateTokenButton"
                onClick={handleGenerateTokenClick}
                variant="danger"
                noConfirm
              >
                {t("desktop.settings.integrations.scim.regenerate")}
              </Button>
            </div>
          )}
        </div>
        {!hasScimFeature && (
          <div className="SCIMFeatureDisabled">
            <span className="NotAvailable">
              {t("desktop.settings.integrations.scim.feature_disabled")}
            </span>
            <Button
              onClick={() => push(BILLING_PATHS.overview.root)}
              variant="link"
            >
              {t("desktop.settings.integrations.scim.upgrade_cta")}
            </Button>
          </div>
        )}
      </Card>
    </View>
  )
}

export default ScimIntegration
