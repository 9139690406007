import React, { PropsWithChildren, useEffect } from "react"

import { useHistory } from "react-router-dom"

import { openInAppBrowser } from "../../../utils"
import { App as CapApp, URLOpenListenerEvent } from "@capacitor/app"

export const DeepLinkContext = React.createContext({})

const DeepLinkProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  let history = useHistory()

  const isFrontdesk = (path: string) => {
    return (
      path.startsWith("/visitor-login") ||
      (path.startsWith("/health-screening") && !path.includes("employee"))
    )
  }

  const isVisitorPath = (path: string) => {
    return path.startsWith("/visitors")
  }

  useEffect(() => {
    CapApp.addListener("appUrlOpen", async (data: URLOpenListenerEvent) => {
      const url = new URL(data.url)

      if (url.pathname) {
        // We need to redirect on window level if it is front desk URL
        if (isFrontdesk(url.pathname)) {
          window.location.href =
            window.location.origin + url.pathname + url.search
        } else if (isVisitorPath(url.pathname)) {
          await openInAppBrowser(data.url)
        } else {
          history.push(url.pathname)
        }
      }
      // If no match, do nothing - let regular routing
      // logic take over
    })
  }, [history])

  return (
    <DeepLinkContext.Provider value={{}}>{children}</DeepLinkContext.Provider>
  )
}

export default DeepLinkProvider
