import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { VISITOR_BASE_URL } from "../../screens/Visitors/constants"
import { isNative } from "../../utils"

import { appError } from "../../redux/app/appSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectTablet } from "../../redux/tablet/selectors"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import {
  checkinRegistration,
  finishRegistration,
  resetRegistration,
} from "../../redux/visitor_registration/visitorRegistrationSlice"

const FLOW_SCREEN_TIMEOUT = 2 * 60 * 1000
const FINISH_SCREEN_TIMEOUT = 10 * 1000

export enum Step {
  START = "start",
  NAME = "name",
  SCAN = "scan",
  CHECKOUT = "checkout",
  FIELDS = "fields",
  HEALTH_INFO = "health_info",
  HEALTH = "health",
  DOCUMENT_INFO = "document_info",
  DOCUMENT = "document",
  FINISH = "finish",
}

export enum FinishType {
  CHECK_IN = "check_in",
  CHECK_OUT = "check_out",
  HEALTH_FAILED = "health_failed",
  QR_CODE = "qr_code",
}

export type FlowContextType = {
  startStep: () => void
  finishStep: (type: FinishType, registrationId?: string) => void
  nextStep: (registrationId?: string) => void
  previousStep: () => void
  goToStep: (step: Step) => void
}

export const FlowContext = createContext<FlowContextType>({
  startStep: () => {},
  finishStep: (type: FinishType, registrationId?: string) => {},
  nextStep: (registrationId?: string) => {},
  previousStep: () => {},
  goToStep: (step: Step) => {},
})

const RegistrationFlowProvider = ({ children }: PropsWithChildren<unknown>) => {
  const history = useHistory()
  const { t } = useTranslation()
  const stepIndex = useRef<number>(0)
  const redirectTimeout = useRef<NodeJS.Timeout | null>(null)

  const [step, setStep] = useState(Step.START)
  const [finishType, setFinishType] = useState(FinishType.CHECK_IN)
  const [steps, setSteps] = useState<Step[]>([Step.START])

  const { data } = useAppSelector(selectVisitorRegistration)
  const {
    touchlessPin,
    inviteId,
    buildingId,
    id: tabletId,
  } = useAppSelector(selectTablet)

  const actions = useActions({
    appError: (message: string) => appError(message),
    resetRegistration: () => resetRegistration(),
    checkinRegistration: (
      buildingId: string,
      tabletId: string,
      id: string,
      printBadge: boolean,
    ) => checkinRegistration({ buildingId, tabletId, id, printBadge }),
    finishRegistration: (id: string) => finishRegistration(id),
  })

  const startStep = useCallback(() => {
    stepIndex.current = 0
    if ((touchlessPin || inviteId) && !isNative()) {
      actions.resetRegistration()
      setStep(Step.NAME)
    } else {
      setStep(Step.START)
    }
  }, [actions, touchlessPin, inviteId])

  const finishStep = useCallback(
    (type: FinishType, registrationId?: string) => {
      if (type === FinishType.CHECK_IN) {
        if (buildingId && tabletId && registrationId)
          if (inviteId) {
            actions.finishRegistration(registrationId).then((response) => {
              if (finishRegistration.fulfilled.match(response)) {
                setFinishType(FinishType.QR_CODE)
                setStep(Step.FINISH)
              } else {
                actions.appError(
                  response.error.message ?? t("tablet.general_error"),
                )
              }
            })
          } else {
            actions
              .checkinRegistration(
                buildingId,
                tabletId,
                registrationId,
                touchlessPin !== null,
              )
              .then((response) => {
                if (checkinRegistration.fulfilled.match(response)) {
                  analyticsEvent(SupportedEvents.VISITOR_CHECK_IN, {
                    id: response?.payload?.id,
                    building_id: buildingId,
                    tablet_id: tabletId,
                    visitor_email: response?.payload?.visitor?.email,
                    host_email: response?.payload?.host?.email,
                  })
                } else {
                  if (response.error.message?.startsWith("health_screening")) {
                    setFinishType(FinishType.HEALTH_FAILED)
                    setStep(Step.FINISH)
                    return
                  }
                  actions.appError(
                    response.error.message ?? t("tablet.general_error"),
                  )
                }

                setFinishType(FinishType.CHECK_IN)
                setStep(Step.FINISH)
              })
          }
      } else {
        setFinishType(type)
        setStep(Step.FINISH)
      }
    },
    [t, actions, buildingId, tabletId, inviteId, touchlessPin],
  )

  const nextStep = useCallback(
    (registrationId?: string) => {
      stepIndex.current = stepIndex.current + 1
      const nextStep = steps[stepIndex.current]

      if (nextStep === Step.FINISH) {
        finishStep(FinishType.CHECK_IN, registrationId)
      } else {
        setStep(nextStep)
      }
    },
    [steps, finishStep],
  )

  const previousStep = useCallback(() => {
    stepIndex.current = stepIndex.current - 1

    setStep(steps[stepIndex.current])
  }, [steps])

  const goToStep = useCallback((step: Step) => {
    setStep(step)
  }, [])

  // Navigate
  useEffect(() => {
    if (step === Step.START) {
      actions.resetRegistration()
    }

    if (step === Step.FINISH) {
      history.push(`${VISITOR_BASE_URL}/${step}/${finishType}`)
    } else {
      history.push(`${VISITOR_BASE_URL}/${step}`)
    }
  }, [actions, history, step, finishType])

  // Redirect to name step on touchless or pre registration
  useEffect(() => {
    if (touchlessPin || inviteId) {
      if (!isNative()) {
        setStep(Step.NAME)
      }
    }
  }, [touchlessPin, inviteId])

  // Build reservation steps
  useEffect(() => {
    if (data) {
      let stepsFlow = [Step.START, Step.NAME]

      if (touchlessPin || inviteId) {
        if (!isNative()) {
          stepsFlow = [Step.NAME]
        }
      }

      if (data?.signin_fields && data?.signin_fields.length > 1) {
        stepsFlow.push(Step.FIELDS)
      }

      if (data?.health_screening) {
        stepsFlow.push(Step.HEALTH_INFO)
        stepsFlow.push(Step.HEALTH)
      }

      if (data?.document) {
        stepsFlow.push(Step.DOCUMENT_INFO)
        stepsFlow.push(Step.DOCUMENT)
      }

      stepsFlow.push(Step.FINISH)

      setSteps(stepsFlow)
    }
  }, [data, touchlessPin, inviteId])

  // Redirect to begining of flow on timeout for tablet
  useEffect(() => {
    if (redirectTimeout.current) {
      clearTimeout(redirectTimeout.current)
    }

    if (step === Step.SCAN || step === Step.START || !isNative()) {
      return
    }

    let time = FLOW_SCREEN_TIMEOUT
    if (step === Step.FINISH) {
      time = FINISH_SCREEN_TIMEOUT
    }

    redirectTimeout.current = setTimeout(() => {
      startStep()
    }, time)

    return () => {
      if (redirectTimeout.current) {
        clearTimeout(redirectTimeout.current)
      }
    }
  }, [actions, startStep, step])

  return (
    <FlowContext.Provider
      value={{ startStep, finishStep, nextStep, previousStep, goToStep }}
    >
      {children}
    </FlowContext.Provider>
  )
}

export default RegistrationFlowProvider
