import { ParseKeys, t } from "i18next"

import { PLAN_GROUPS, PLAN_TYPES } from "../../../redux/api/billing/constants"
import { Subscription } from "../../../redux/api/billing/subscriptions/types"
import { PlanGroup, PlanType } from "../../../redux/api/billing/types"
import { getPlanGroup } from "../../../redux/api/billing/utils"

import { ReactComponent as BadgeSVG } from "../../../assets/images/icons/BadgeOutline.svg"
import { ReactComponent as ChairSVG } from "../../../assets/images/icons/Chair.svg"
import { ReactComponent as RoomSVG } from "../../../assets/images/icons/Room.svg"
import { ReactComponent as SignSVG } from "../../../assets/images/icons/Sign.svg"

export const PLAN_TYPES_MAP: Record<
  string,
  {
    title: ParseKeys
    name: ParseKeys
    description: ParseKeys
    features: string
    featuresCount: number
    icon: (props: { className: string }) => JSX.Element
    isRecommended?: boolean
  }
> = {
  [PLAN_TYPES.JOAN_ON_TABLET]: {
    title: "desktop.components.subscription_card.joan_on_tablets",
    name: "desktop.components.subscription_card.joan_on_tablets",
    description:
      "desktop.components.plan_variation_card.joan_on_tablet.description",
    features: "desktop.components.plan_variation_card.joan_on_tablet.features",
    featuresCount: 0,
    icon: (props: { className: string }) => <SignSVG {...props} />,
  },
  [PLAN_TYPES.JOAN_ON_DISPLAY]: {
    title: "desktop.components.subscription_card.joan_on_display",
    name: "desktop.components.subscription_card.joan_on_display",
    description:
      "desktop.components.plan_variation_card.joan_on_display.description",
    features: "desktop.components.plan_variation_card.joan_on_display.features",
    featuresCount: 0,
    icon: (props: { className: string }) => <SignSVG {...props} />,
  },
  [PLAN_TYPES.SIGN_ESSENTIALS]: {
    title: "desktop.components.subscription_card.joan_sign",
    name: "desktop.components.subscription_card.essentials",
    description:
      "desktop.components.plan_variation_card.sign_essentials.description",
    features: "desktop.components.plan_variation_card.sign_essentials.features",
    featuresCount: 4,
    icon: (props: { className: string }) => <SignSVG {...props} />,
  },
  [PLAN_TYPES.SIGN_PROFESSIONAL]: {
    title: "desktop.components.subscription_card.joan_sign",
    name: "desktop.components.subscription_card.professional",
    description:
      "desktop.components.plan_variation_card.sign_professional.description",
    features:
      "desktop.components.plan_variation_card.sign_professional.features",
    featuresCount: 2,
    icon: (props: { className: string }) => <SignSVG {...props} />,
    isRecommended: true,
  },
  [PLAN_TYPES.DESK_ESSENTIALS]: {
    title: "desktop.components.subscription_card.joan_desk_asset",
    name: "desktop.components.subscription_card.essentials",
    description:
      "desktop.components.plan_variation_card.desk_and_asset_essentials.description",
    features:
      "desktop.components.plan_variation_card.desk_and_asset_essentials.features",
    featuresCount: 13,
    icon: (props: { className: string }) => <ChairSVG {...props} />,
  },
  [PLAN_TYPES.DESK_AND_ASSET_ESSENTIALS]: {
    title: "desktop.components.subscription_card.joan_desk_asset",
    name: "desktop.components.subscription_card.essentials",
    description:
      "desktop.components.plan_variation_card.desk_and_asset_essentials.description",
    features:
      "desktop.components.plan_variation_card.desk_and_asset_essentials.features",
    featuresCount: 13,
    icon: (props: { className: string }) => <ChairSVG {...props} />,
  },
  [PLAN_TYPES.ROOM_ESSENTIALS]: {
    title: "desktop.components.subscription_card.joan_room",
    name: "desktop.components.subscription_card.essentials",
    description:
      "desktop.components.plan_variation_card.room_essentials.description",
    features: "desktop.components.plan_variation_card.room_essentials.features",
    featuresCount: 7,
    icon: (props: { className: string }) => <RoomSVG {...props} />,
  },
  [PLAN_TYPES.ROOM_PROFESSIONAL]: {
    title: "desktop.components.subscription_card.joan_room",
    name: "desktop.components.subscription_card.professional",
    description:
      "desktop.components.plan_variation_card.room_professional.description",
    features:
      "desktop.components.plan_variation_card.room_professional.features",
    featuresCount: 7,
    icon: (props: { className: string }) => <RoomSVG {...props} />,
    isRecommended: true,
  },
  [PLAN_TYPES.ROOM_ENTERPRISE]: {
    title: "desktop.components.subscription_card.joan_room",
    name: "desktop.components.subscription_card.enterprise",
    description:
      "desktop.components.plan_variation_card.room_enterprise.description",
    features: "desktop.components.plan_variation_card.room_enterprise.features",
    featuresCount: 5,
    icon: (props: { className: string }) => <RoomSVG {...props} />,
  },
  [PLAN_TYPES.VISITOR_PROFESSIONAL]: {
    title: "desktop.components.subscription_card.joan_visitor",
    name: "desktop.components.subscription_card.professional",
    description:
      "desktop.components.plan_variation_card.visitor_professional.description",
    features:
      "desktop.components.plan_variation_card.visitor_professional.features",
    featuresCount: 7,
    icon: (props: { className: string }) => <BadgeSVG {...props} />,
  },
  [PLAN_TYPES.VISITOR_PROFESSIONAL_EAP]: {
    title: "desktop.components.subscription_card.joan_visitor",
    name: "desktop.components.subscription_card.early_bird",
    description:
      "desktop.components.plan_variation_card.visitor_professional.description",
    features:
      "desktop.components.plan_variation_card.visitor_professional.features",
    featuresCount: 7,
    icon: (props: { className: string }) => <BadgeSVG {...props} />,
  },
} as const

export const PLAN_GROUP_DESCRIPTION: Record<PlanGroup, string> = {
  [PLAN_GROUPS.DESK_BOOKING]:
    "desktop.components.subscription_card.desk_booking_description",
  [PLAN_GROUPS.ROOM]:
    "desktop.components.subscription_card.room_booking_description",
  [PLAN_GROUPS.SIGN]:
    "desktop.components.subscription_card.joan_sign_description",
  [PLAN_GROUPS.VISITOR]:
    "desktop.components.subscription_card.visitor_management_description",
  [PLAN_GROUPS.HOME]: "",
  [PLAN_GROUPS.JOAN_ON_DISPLAY]: "",
  [PLAN_GROUPS.JOAN_ON_TABLET]: "",
  [PLAN_GROUPS.DEPRECATED]: "",
} as const

export const getPlanTypeObject = (planType: PlanType) =>
  PLAN_TYPES_MAP[planType]

export const toFriendlyName = (planType: PlanType) =>
  PLAN_TYPES_MAP[planType]?.name
    ? t(PLAN_TYPES_MAP[planType]?.name)
    : `<${t("desktop.components.subscription_card.unknown")}>`

export const toFriendlyTitle = (planType: PlanType) =>
  PLAN_TYPES_MAP[planType]?.title
    ? t(PLAN_TYPES_MAP[planType]?.title)
    : `<${t("desktop.components.subscription_card.unknown")}>`

export const getSubscriptionIcon = (planType: PlanType) =>
  PLAN_TYPES_MAP[planType]?.icon ??
  ((props: { className: string }) => <BadgeSVG {...props} />)

export const getPlanGroupDescription = (planType: PlanType) => {
  const planDescription = PLAN_GROUP_DESCRIPTION[getPlanGroup(planType)]
  return planDescription ? t(planDescription as ParseKeys) : ""
}

export const isSubscription = (
  value: Subscription | { plan_type: PlanType },
): value is Subscription => "subscription_id" in value
