import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchTimeslotQuery } from "../../../../redux/api/timeslots"
import { TimeslotResponse } from "../../../../redux/timeslots/types"

import Loader from "../../../../components/basic/Loader"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import SlotForm from "../../../../components/Form/SlotForm"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

type ParamsType = {
  slot_id: string
}

const DEFAULT_SLOT = {
  name: "All day",
  from: "09:00",
  to: "17:00",
  is_default: true,
  type: "desk",
  active: true,
} as Partial<TimeslotResponse>

const EditTimeSlots = () => {
  const { t } = useTranslation()
  const params = useParams<ParamsType>()

  const { data: slot = DEFAULT_SLOT, isLoading } = useFetchTimeslotQuery(
    params.slot_id ?? skipToken,
  )

  const timeslotNotFound = !!params.slot_id && !slot.id

  return (
    <View className="EditTimeSlots">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.settings.desks.title"),
          t("desktop.settings.timeslots.title"),
          slot?.id
            ? t("desktop.settings.timeslots.edit_breadcrumb")
            : t("desktop.settings.timeslots.add_breadcrumb"),
        ]}
      />

      <Intro isConstrained>
        {slot?.id
          ? t("desktop.settings.timeslots.edit_title")
          : t("desktop.settings.timeslots.add_title")}
      </Intro>

      <Space size={0.75} />

      {isLoading ? (
        <Loader />
      ) : timeslotNotFound ? (
        <p>{t("desktop.settings.timeslots.not_found")}</p>
      ) : (
        <SlotForm slot={slot} />
      )}
    </View>
  )
}

export default EditTimeSlots
