import dayjs from "dayjs"

import { shortUserTimeFormat } from "../../utils"

import "./CheckinSummary.sass"

type Props = {
  checkin_at?: string
  checkout_at?: string
}

export const CheckinSummary = ({ checkin_at, checkout_at }: Props) => {
  return checkin_at || checkout_at ? (
    <div className="CheckinSummary">
      <p className="check-in-status">
        {checkin_at && (
          <span>
            Checked in at {dayjs(checkin_at).format(shortUserTimeFormat())}.
          </span>
        )}
        {checkout_at && (
          <span>
            Checked out at {dayjs(checkout_at).format(shortUserTimeFormat())}.
          </span>
        )}
      </p>
    </div>
  ) : (
    <></>
  )
}
