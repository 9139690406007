import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"
import { selectVisitor } from "../../../redux/visitor/selectors"
import { fetchVisitor } from "../../../redux/visitor/visitorSlice"
import { selectVisitorSettings } from "../../../redux/visitor_settings/selectors"
import { fetchVisitorSettings } from "../../../redux/visitor_settings/visitorSettingsSlice"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import VisitorEditForm from "../../../components/Form/Visitors/VisitorEditForm"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./VisitorEdit.sass"

type ParamsType = {
  id?: string
}

const VisitorEdit = () => {
  const { t } = useTranslation()
  const { id } = useParams<ParamsType>()

  const { entry: visitor, isLoading } = useAppSelector(selectVisitor)
  const { isLoading: settingsLoading } = useAppSelector(selectVisitorSettings)

  const actions = useActions({
    fetchVisitor: (id: string) => fetchVisitor(id),
    fetchVisitorSettings: () => fetchVisitorSettings(),
  })

  useEffect(() => {
    if (id && visitor?.id !== id) {
      actions.fetchVisitor(id)
    }
  }, [actions, id, visitor?.id])

  useEffect(() => {
    actions.fetchVisitorSettings()
  }, [actions])

  return (
    <View className="VisitorEdit">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.manage.visitors.title"),
          t(`desktop.manage.visitors.${id ? "edit_visitor" : "new_visitor"}`),
        ]}
      />

      <Space size={1} />

      {(id && (!visitor || visitor.id !== id || isLoading)) ||
      settingsLoading ? (
        <div className="loading">
          <Loader />
        </div>
      ) : (id && visitor) || !id ? (
        <VisitorEditForm visitor={id ? visitor : undefined} />
      ) : (
        <div>{t("desktop.manage.visitors.no_visitor")}</div>
      )}
    </View>
  )
}
export default VisitorEdit
