import dayjs, { Dayjs } from "dayjs"
import { ThunkApiConfig } from "RootType"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import {
  deleteJSON,
  get,
  postJSON,
  putJSON,
  recurringReservationsURL,
  reservationsCSVURL,
  reservationsURL,
  reservationURL,
} from "../../api"
import { timeZone } from "../../dayjs"
import { api } from "../api"
import {
  getErrorMessage,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import {
  DeleteType,
  RecurringReservationResponse,
  ReservationRequest,
  ReservationResponse,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

const formatDate = (date: Dayjs | string) => {
  return dayjs(date).format("YYYY-MM-DD")
}

/**
 *  Thunks
 */
type FetchReservationProps = {
  cid: string
  start: string
  end: string
}

export const fetchReservations = createAsyncThunk<
  ReservationResponse[],
  FetchReservationProps,
  ThunkApiConfig
>("reservations/fetch", async ({ cid, start: from, end: to }, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(
    reservationsURL(cid, {
      from: formatDate(from),
      to: formatDate(to),
      tz: timeZone,
    }),
    {},
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

type FetchReservationLocalProps = FetchReservationProps & {
  buildingId?: string
  floorId?: string
}

/*
	This call isn't affecting global state with the exception of logging the user out.
	It's only used for information gathering on component level.
*/
export const fetchReservationsLocal = createAsyncThunk<
  ReservationResponse[],
  FetchReservationLocalProps,
  ThunkApiConfig
>(
  "reservations/fetchLocal",
  async ({ cid, start: from, end: to, buildingId, floorId }, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await get(
      reservationsURL(cid, {
        from: formatDate(from),
        to: formatDate(to),
        tz: timeZone,
        ...(buildingId ? { building: buildingId } : {}),
        ...(floorId ? { floor: floorId } : {}),
      }),
      {},
      access_token,
    )

    if (response.ok) {
      return await response.json()
    }

    throw new Error(await getErrorMessage(response))
  },
)

type CreateReservationProps = {
  date: string
  seat_id: string
  start: string
  end: string
  user_id: string
  tz?: string | null
}

export const createReservation = createAsyncThunk<
  ReservationResponse,
  CreateReservationProps,
  ThunkApiConfig
>(
  "reservations/create",
  async (
    { date, seat_id, start: from, end: to, user_id, tz },
    { getState, dispatch },
  ) => {
    const {
      app: { company, lang },
      auth: { access_token },
    } = getState()

    const response = await postJSON(
      reservationsURL(company, {
        lang,
      }),
      {
        body: {
          date: formatDate(date),
          seat_id,
          from,
          to,
          user_id,
          tz: tz ?? timeZone,
        },
      },
      access_token,
    )

    if (response.ok) {
      const reservation = await response.json()

      analyticsEvent(SupportedEvents.DESK_BOOKED, {
        id: seat_id,
        name: reservation?.seat?.name ?? "",
      })

      dispatch(api.util.invalidateTags(["DeskReservations"]))

      return reservation
    }

    throw new Error(await getErrorMessage(response))
  },
)

type CreateRecurringReservationProps = CreateReservationProps & {
  freq: string
  until: string
}

export const createRecurringReservation = createAsyncThunk<
  RecurringReservationResponse,
  CreateRecurringReservationProps,
  ThunkApiConfig<{ error: boolean; payload: { failed: any[] } }>
>(
  "reservations/createRecurring",
  async (
    { date, seat_id, start: from, end: to, user_id, freq, until, tz },
    { getState, rejectWithValue, dispatch },
  ) => {
    const {
      app: { lang },
      auth: { access_token },
    } = getState()

    const [startHour, startMinute] = from.split(":")
    const [endHour, endMinute] = to.split(":")

    const start = dayjs(date)
      .hour(parseInt(startHour))
      .minute(parseInt(startMinute))
    const end = dayjs(date).hour(parseInt(endHour)).minute(parseInt(endMinute))

    const response = await postJSON(
      recurringReservationsURL({
        lang,
      }),
      {
        body: {
          seat_id,
          start,
          end,
          user_id,
          freq,
          until: dayjs(until).hour(12),
          include_parking: false,
          tz: tz ?? timeZone,
        },
      },
      access_token,
    )

    if (response.ok) {
      const reservation = await response.json()
      analyticsEvent(SupportedEvents.RESERVATION_ADD, {
        seat_id,
        type: freq,
      })
      dispatch(api.util.invalidateTags(["DeskReservations"]))

      return reservation
    }

    const json = await response.json()

    if (json.failed) {
      return rejectWithValue({ error: true, payload: json })
    }

    throw new Error(await getErrorMessage(response))
  },
)

export const updateReservation = createAsyncThunk<
  void,
  ReservationRequest,
  ThunkApiConfig
>(
  "reservations/update",
  async ({ id, timezone, ...params }, { getState, dispatch }) => {
    const {
      app: { lang },
      auth: { access_token },
    } = getState()

    const response = await putJSON(
      reservationURL(id, {
        lang,
      }),
      { body: { ...params, tz: timezone } },
      access_token,
    )

    if (response.ok) {
      analyticsEvent(SupportedEvents.RESERVATION_UPDATE, {
        id,
      })

      dispatch(api.util.invalidateTags(["DeskReservations"]))

      return
    }

    throw new Error(await getErrorMessage(response))
  },
)

type DeleteReservationProps = {
  id: string
  type: DeleteType
}

export const deleteReservation = createAsyncThunk<
  void,
  DeleteReservationProps,
  ThunkApiConfig
>("reservations/delete", async ({ id, type }, { getState }) => {
  const {
    app: { lang },
    auth: { access_token },
  } = getState()

  const response = await deleteJSON(
    reservationURL(id, {
      lang,
      type,
    }),
    { body: {} },
    access_token,
  )

  if (response.ok) {
    analyticsEvent(SupportedEvents.RESERVATION_DELETE, {
      id,
      type,
    })

    return
  }

  throw new Error(await getErrorMessage(response))
})

type FetchReservationsCSVProps = {
  start: string
  end: string
}

export const fetchReservationsCSV = createAsyncThunk<
  string,
  FetchReservationsCSVProps,
  ThunkApiConfig
>("reservations/fetchCSV", async ({ start: from, end: to }, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(
    reservationsCSVURL({
      from,
      to,
      tz: timeZone,
    }),
    {},
    access_token,
  )

  if (response.ok) {
    return await response.text()
  }

  throw new Error(await getErrorMessage(response))
})

/**
 *  Slice
 */
export interface ReservationsState extends SliceState {
  entries: ReservationResponse[]
}

const initialState: ReservationsState = {
  entries: [],
  ...sliceInitialState,
}

const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReservations.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchReservations.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchReservations.fulfilled, (state, { payload }) => {
      state.entries = payload

      setFetchSuccessState(state)
      return state
    })

    builder.addCase(deleteReservation.fulfilled, (state, { meta }) => {
      state.entries = state.entries.filter((res) => res.id !== meta.arg.id)

      setFetchSuccessState(state)
      return state
    })

    builder.addMatcher(
      isAnyOf(
        createReservation.pending,
        createRecurringReservation.pending,
        updateReservation.pending,
        deleteReservation.pending,
      ),
      (state) => {
        state.isSubmitting = true
      },
    )

    builder.addMatcher(
      isAnyOf(
        createReservation.rejected,
        createRecurringReservation.rejected,
        updateReservation.rejected,
        deleteReservation.rejected,
      ),
      (state, action) => {
        setSubmitErrorState(state, action)
      },
    )
    builder.addMatcher(
      isAnyOf(
        createReservation.fulfilled,
        createRecurringReservation.fulfilled,
        updateReservation.fulfilled,
      ),
      (state) => {
        setSubmitSuccessState(state)
      },
    )
  },
})

export const reservationsReducer = reservationsSlice.reducer
