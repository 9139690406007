import React, { Fragment, useCallback, useState } from "react"

import classNames from "classnames"
import { t } from "i18next"
import queryString from "query-string"
import { useHistory } from "react-router-dom"

import { ENTRIES_PER_PAGE_KEY, PERMISSIONS } from "../../../constants"
import { useBackendPagination } from "../../../hooks/useBackendPagination"
import { useCheckForPermission } from "../../../hooks/useCheckForPermission"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { parseQueryWithDefault } from "../../../utils"
import { BUILDINGS_PATHS } from "./constants"

import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { BuildingResponse } from "../../../redux/buildings/types"

import Button from "../../../components/advanced/Button"
import GoogleMap from "../../../components/advanced/GoogleMap"
import Card from "../../../components/basic/Card"
import Divider from "../../../components/basic/Divider"
import Loader from "../../../components/basic/Loader"
import Filters from "../../../components/Filter/Filters"
import FilterSpace from "../../../components/Filter/FilterSpace"
import SearchFilter from "../../../components/Filter/SearchFilter"
import Heading from "../../../components/Heading"
import Intro from "../../../components/Intro"
import OccupancyInfo from "../../../components/OccupancyInfo"
import Pagination from "../../../components/Pagination"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./Buildings.sass"

const { stringify } = queryString

type BuildingProps = {
  building: BuildingResponse
}

const ENTRIES_PER_PAGE = 4

function Building({ building }: BuildingProps) {
  const history = useHistory()

  const canEditBuilding = useCheckForPermission(
    PERMISSIONS.buildings.canChangeBuilding,
  )

  function handleEditClick() {
    history.push(BUILDINGS_PATHS.edit.replace(":building_id", building.id))
  }

  /*
		Sometimes lat and long are saved as null, sometimes as 0. If it's
		0, then ternary return 0 and we get 0 in JSX, which is wrong.
	*/
  let doesLatAndLongExistAndNotNull = false

  if (building.longitude && building.latitude) {
    doesLatAndLongExistAndNotNull = true
  }

  return (
    <Card className="building-card">
      <div className="building">
        <div className="main">
          <div className="data">
            <div className="primary">{building.name}</div>
            <div className="secondary">{building.address}</div>
          </div>

          {canEditBuilding && (
            <div className="actions">
              <Button onClick={() => handleEditClick()} variant="gray">
                {t("general.edit")}
              </Button>
            </div>
          )}
        </div>

        {doesLatAndLongExistAndNotNull && (
          <GoogleMap
            className="map"
            position={{ lat: building.latitude!, lng: building.longitude! }}
          />
        )}

        <div className="statistic">
          <div className="info">
            <div className="number">{building.floors_count || 0}</div>
            <div className="name">
              {t("desktop.settings.buildings.statistics.floors")}
            </div>
          </div>

          <div className="info">
            <div className="number">{building.departments_count || 0}</div>
            <div className="name">
              {t("desktop.settings.buildings.statistics.departments")}
            </div>
          </div>

          <div className="info">
            <div className="number">{building.desks_count || 0}</div>
            <div className="name">
              {t("desktop.settings.buildings.statistics.desks")}
            </div>
          </div>

          <div className="info">
            <div className="number">{building.rooms_count || 0}</div>
            <div className="name">
              {t("desktop.settings.buildings.statistics.rooms")}
            </div>
          </div>

          <div className="info">
            <div className="number">{building.employees_count || 0}</div>
            <div className="name">
              {t("desktop.settings.buildings.statistics.employees")}
            </div>
          </div>
        </div>

        {building &&
          building.settings &&
          (building.settings.desk_capacity_limit ||
            building.settings.desk_capacity_limit === 0) && (
            <OccupancyInfo type="Building" building={building} />
          )}
      </div>
    </Card>
  )
}

function SettingsBuildings() {
  const history = useHistory()

  const { search } = history.location
  const { page: queryPage, search: querySearch } = parseQueryWithDefault(
    search,
    {
      search: "",
      page: 1,
    },
  )

  const [page, setCurrentPage] = useState(queryPage)
  const [searchText, setSearchQuery] = useState(querySearch)

  const { value: entriesPerPage, onChange: setEntriesPerPage } =
    useLocalStorage(ENTRIES_PER_PAGE_KEY, ENTRIES_PER_PAGE.toString())
  const entriesPerPageNum = parseInt(entriesPerPage)

  const offset = (page - 1) * entriesPerPageNum

  const {
    data: { results: buildings = [], count = 0 } = {},
    isSuccess: isLoaded,
    isFetching: isLoading,
    refetch: refetchBuildings,
  } = useFetchBuildingsQuery({
    stats: true,
    offset,
    limit: entriesPerPageNum,
    /* remove search from query if empty this ensures that the query is not called with search="" */
    ...(searchText && { search: searchText }),
  })

  const { from, to, hasNext, hasPrevious, paginationLinks } =
    useBackendPagination({
      offset,
      totalNumberOfItems: count,
      entriesPerPage: entriesPerPageNum,
      maxLinks: 3,
      maxTrailingLinks: 2,
    })

  const setPage = useCallback(
    (nextPage: number) => {
      history.push(
        `${BUILDINGS_PATHS.overview}?${stringify({
          page: nextPage,
          search: searchText,
        })}`,
      )
      setCurrentPage(nextPage)
      refetchBuildings()
    },
    [history, searchText, refetchBuildings],
  )

  const handleSearchChange = useCallback(
    (search: string) => {
      history.push(
        `${BUILDINGS_PATHS.overview}?${stringify({
          page: 1,
          search,
        })}`,
      )
      setCurrentPage(1)
      setSearchQuery(search)
      refetchBuildings()
    },
    [history, refetchBuildings],
  )

  const canAddBuilding = useCheckForPermission(
    PERMISSIONS.buildings.canAddBuilding,
  )

  function handleNewClick() {
    history.push(BUILDINGS_PATHS.add)
  }

  const cardClasses = classNames("buildings", {
    isLoading: !!isLoading,
  })

  return (
    <View className="SettingsBuildings SettingsPage">
      <Heading>{t("desktop.settings.buildings.title")}</Heading>
      <Intro isConstrained>{t("desktop.settings.buildings.subtitle")}</Intro>

      <Space size={0.75} />

      <Filters>
        <SearchFilter
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Filter buildings"
        />

        <FilterSpace />
        {canAddBuilding && (
          <Button onClick={handleNewClick} isSmall>
            {t("desktop.settings.buildings.add_button")}
          </Button>
        )}
      </Filters>

      <Space size={0.75} />

      <Card className={cardClasses}>
        {isLoading && <Loader className="loader" />}
        {isLoaded && buildings.length > 0 && (
          <>
            {buildings.map((building: BuildingResponse, i: number) => (
              <Fragment key={building.id}>
                <Building building={building} />
                {i < buildings.length - 1 && (
                  <Divider className="building-divider" hasMargin={false} />
                )}
              </Fragment>
            ))}
            <Divider hasMargin={false} />

            <div className="pagination">
              <Pagination
                links={paginationLinks}
                setPage={setPage}
                onPrevious={() => setPage(page - 1)}
                onNext={() => setPage(page + 1)}
                hasNext={hasNext}
                hasPrevious={hasPrevious}
                from={from}
                to={to}
                total={count}
                items={t("desktop.settings.buildings.buildings", {
                  count,
                })}
                entriesPerPage={entriesPerPageNum}
                setEntriesPerPage={setEntriesPerPage}
              />
            </div>
          </>
        )}
        {isLoaded && buildings.length === 0 && (
          <Card className="building-card">
            <p className="no-buildings">
              {t("desktop.settings.buildings.no_buildings")}
            </p>
          </Card>
        )}
      </Card>
    </View>
  )
}

export default SettingsBuildings
