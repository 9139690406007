import React, { useCallback, useMemo } from "react"

import classNames from "classnames"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useToast } from "../../../../hooks/useToast"
import { PROFILE_PATHS } from "../constants"

import { useUpdatePasswordMutation } from "../../../../redux/api/me"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import { Input } from "../../../../components/basic/Input"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"
import PageForm from "../../../../components/Form/PageFormHook"

import "./styles.sass"

const FORM_MAPPING = {
  new: "newPassword",
} as const

type FormValues = {
  newPassword: string
  confirmation: string
}

const PasswordForm = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()

  const [updatePassword] = useUpdatePasswordMutation()

  const methods = useForm<FormValues>({
    defaultValues: {
      newPassword: "",
      confirmation: "",
    },
  })

  const { setError, control, watch } = methods

  const onUpdateClick = useCallback(
    async ({ newPassword, confirmation }: FormValues) => {
      const response = await updatePassword({
        newPassword,
        confirmation,
      })

      if (isRejected(response)) {
        const { error } = response

        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast, FORM_MAPPING)
        }

        return
      }

      infoToast(
        t("desktop.settings.profile.password.form.update_success_toast"),
      )

      history.push(PROFILE_PATHS.root)
    },
    [updatePassword, infoToast, t, history, setError, errorToast],
  )

  const newPassword = watch("newPassword")

  return (
    <FormProvider {...methods}>
      <PageForm
        className="PasswordForm"
        updateMode
        onUpdate={onUpdateClick}
        backUrl={PROFILE_PATHS.root}
        submitButtonText={t(
          "desktop.settings.profile.password.form.change_password_button",
        )}
      >
        <Field
          control={control}
          name="newPassword"
          label={t("desktop.settings.profile.password.form.password")}
          required
        >
          {(props) => (
            <>
              <Input
                {...props}
                type="password"
                placeholder={t(
                  "desktop.settings.profile.password.form.password",
                )}
              />

              <PasswordStrengthMeter password={newPassword} />
            </>
          )}
        </Field>

        <Field
          control={control}
          name="confirmation"
          label={t(
            "desktop.settings.profile.password.form.password_confirmation",
          )}
          required
        >
          {(props) => (
            <Input
              {...props}
              type="password"
              placeholder={t(
                "desktop.settings.profile.password.form.password_confirmation",
              )}
            />
          )}
        </Field>
      </PageForm>
    </FormProvider>
  )
}

const PasswordStrengthMeter = ({ password }: { password: string }) => {
  const { t } = useTranslation()

  const PASSWORD_STRENGTH_OPTIONS = {
    WEAK: {
      label: t("desktop.settings.profile.password.form.password_meter.weak"),
      className: "Weak",
    },
    NORMAL: {
      label: t("desktop.settings.profile.password.form.password_meter.normal"),
      className: "Normal",
    },
    STRONG: {
      label: t("desktop.settings.profile.password.form.password_meter.strong"),
      className: "Strong",
    },
  }

  const { WEAK, NORMAL, STRONG } = PASSWORD_STRENGTH_OPTIONS

  const { label, className } = useMemo(() => {
    if (password.length < 9) {
      return WEAK
    } else if (password.match(/[A-Z]/) && password.match(/[0-9]/)) {
      if (password.match(/[!@#$%^&*()_+{}|:"<>?]/)) {
        return STRONG
      }

      return NORMAL
    } else {
      return WEAK
    }
  }, [password, WEAK, NORMAL, STRONG])

  if (!password) {
    return null
  }

  return (
    <>
      <div
        className={classNames("PasswordStrength", {
          [className]: !!className,
        })}
      >
        <div className="MeterList">
          <div className="MeterItem" />
          <div className="MeterItem" />
          <div className="MeterItem" />
        </div>

        <div className="MeterLabel">{label}</div>
      </div>
    </>
  )
}

export default PasswordForm
