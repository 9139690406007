import React, { useMemo } from "react"

import classNames from "classnames"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"

import { LabelPaths } from "../../../../constants"
import { useCheckForFeatureFlag } from "../../../../hooks/useCheckForFeatureFlag"
import { useToast } from "../../../../hooks/useToast"
import { generateString, getLabel } from "../../../../utils"
import {
  DEVICES_WITHOUT_STATUS,
  ROOMS_PATHS,
  SETTINGS_GROUPS_PROPS,
} from "../constants"
import { CUSTOM_CONTENT_STATUS } from "../CustomContent/constants"
import { DEVICE_SETTINGS_FEATURES } from "../DeviceSettings/constants"
import { PartialSettingsKeys } from "../DeviceSettings/types"
import DeviceDetailForm from "./DeviceDetailForm"
import { useModals } from "@mattjennings/react-modal-stack"
import { skipToken } from "@reduxjs/toolkit/query"

import { useFetchBuildingQuery } from "../../../../redux/api/buildings"
import { useFetchCustomContentListQuery } from "../../../../redux/api/customContent"
import {
  useFetchRoomDeviceQuery,
  useRepairRoomDeviceMutation,
  useUnpairRoomDeviceMutation,
} from "../../../../redux/api/devices"
import { useFetchRoomQuery } from "../../../../redux/api/rooms"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import Button from "../../../../components/advanced/Button"
import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import Loader from "../../../../components/basic/Loader"
import { Tooltip } from "../../../../components/basic/Tooltip"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import { FilterSpecialValues } from "../../../../components/Filter/types"
import Space from "../../../../components/Space"
import View from "../../../../components/View"
import RoomDeviceStatus from "../../../../components/Visitors/RoomDeviceStatus"

import "./DeviceDetail.sass"

type ParamsType = {
  device_id: string
}

const DeviceDetail = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { device_id: deviceId } = useParams<ParamsType>()
  const { openModal, closeModal } = useModals()
  const { errorToast, infoToast } = useToast()

  const [repairRoomDevice] = useRepairRoomDeviceMutation()
  const [unpairRoomDevice] = useUnpairRoomDeviceMutation()

  const { data: roomDevice, isFetching: isLoading } = useFetchRoomDeviceQuery(
    deviceId ?? skipToken,
  )

  const { id, name = "", type, rooms = [] } = roomDevice ?? {}

  const { data: room } = useFetchRoomQuery(
    rooms.length > 0 ? rooms[0].key : skipToken,
  )

  const buildingId = useMemo(() => {
    if (room && room?.building) {
      return room.building.id
    }

    return
  }, [room])

  const { data: building } = useFetchBuildingQuery(
    { id: buildingId ?? "" },
    { skip: !buildingId },
  )
  const { data: { results: customContentEntries = [] } = {} } =
    useFetchCustomContentListQuery({
      status: String(CUSTOM_CONTENT_STATUS.enabled),
    })

  const hasCustomContent = useMemo(() => {
    return customContentEntries.some((content) =>
      content.devices.includes(deviceId),
    )
  }, [customContentEntries, deviceId])

  const settingsPath = (buildingId?: string, deviceId?: string) => {
    const building = buildingId || FilterSpecialValues.ALL
    const device = deviceId || FilterSpecialValues.ALL

    return `${ROOMS_PATHS.deviceSettings}?building_id=${building}&device_id=${device}`
  }

  const isBuildingSettingsEnabled = useCheckForFeatureFlag(
    DEVICE_SETTINGS_FEATURES.PER_BUILDING,
  )
  const isDeviceSettingsEnabled = useCheckForFeatureFlag(
    DEVICE_SETTINGS_FEATURES.PER_DEVICE,
  )
  const buildingSettingsDisabled = !buildingId || !isBuildingSettingsEnabled
  const deviceSettingsDisabled = !deviceId || !isDeviceSettingsEnabled

  const getEffectiveSettingsLocation = (keys: PartialSettingsKeys) => {
    if (keys?.some((key) => !!roomDevice?.settings?.[key])) {
      return t(
        "desktop.settings.rooms.devices.form.settings_statuses.using_device_settings",
      )
    }
    if (keys?.some((key) => !!building?.settings?.[key])) {
      return t(
        "desktop.settings.rooms.devices.form.settings_statuses.using_building_settings",
      )
    }

    return t(
      "desktop.settings.rooms.devices.form.settings_statuses.using_global_settings",
    )
  }

  const settingsStatuses = [
    {
      label: t("desktop.settings.rooms.devices.form.settings_options.features"),
      value: getEffectiveSettingsLocation(SETTINGS_GROUPS_PROPS.GENERAL),
      show: true,
    },
    {
      label: t(
        "desktop.settings.rooms.devices.form.settings_options.custom_button",
      ),
      value: getEffectiveSettingsLocation(SETTINGS_GROUPS_PROPS.CUSTOM_BUTTON),
      show: true,
    },
    {
      label: t(
        "desktop.settings.rooms.devices.form.settings_options.office_hours",
      ),
      value: getEffectiveSettingsLocation(SETTINGS_GROUPS_PROPS.OFFICE_HOURS),
      show: true,
    },
    {
      label: t("desktop.settings.rooms.devices.form.settings_options.logo"),
      value: getEffectiveSettingsLocation(SETTINGS_GROUPS_PROPS.CUSTOM_LOGO),
      show: true,
    },
    {
      label: t(
        "desktop.settings.rooms.devices.form.settings_options.localization",
      ),
      value: getEffectiveSettingsLocation(SETTINGS_GROUPS_PROPS.LOCALIZATION),
      show: true,
    },
    {
      label: t(
        "desktop.settings.rooms.devices.form.settings_options.custom_content",
      ),
      value: t(
        "desktop.settings.rooms.devices.form.settings_statuses.using_device_settings",
      ),
      show: hasCustomContent,
    },
  ]

  const handleRepair = async () => {
    if (roomDevice?.id) {
      const response = await repairRoomDevice([roomDevice.id])

      if (isRejected(response)) {
        const { error } = response

        if (isApiResponseError(error)) {
          errorToast(error.message)
        }
        return
      }

      infoToast(
        t("desktop.settings.rooms.devices.form.toasts.device_repaired_one"),
      )
    }
  }
  const handleUnpair = async () => {
    if (roomDevice?.id) {
      const response = await unpairRoomDevice([roomDevice.id])

      if (isRejected(response)) {
        const { error } = response

        if (isApiResponseError(error)) {
          errorToast(error.message)
        }
        return
      }

      infoToast(
        t("desktop.settings.rooms.devices.form.toasts.device_unpaired_one"),
      )
      history.push(ROOMS_PATHS.devices)
    }
  }

  const handleUnpairConfirmation = () => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await handleUnpair()
        closeModal()
      },
    })
  }
  const displayStatus = !DEVICES_WITHOUT_STATUS.includes(type ?? "")

  return (
    <View className="RoomDeviceDetail">
      <Breadcrumbs
        depth={name ? 3 : 2}
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.devices.title"),
          name,
        ]}
        includeParamsAsPath
      />

      <Space size={0.75} />

      {isLoading ? (
        <div className="loading">
          <Loader />
        </div>
      ) : roomDevice ? (
        <div className="device-detail-content">
          <DeviceDetailForm roomDevice={roomDevice} />

          <div className="info-panel">
            <div className="infos">
              <div className="info">
                <div className="info-name">
                  {t("desktop.settings.rooms.devices.uuid")}
                </div>
                <div className="info-data">{id}</div>
              </div>
              <div className="info">
                <div className="info-name">
                  {t("desktop.settings.rooms.devices.model")}
                </div>
                <div className="info-data">
                  {type &&
                    getLabel(`deviceTypes.${type.toLowerCase()}` as LabelPaths)}
                </div>
              </div>
              {displayStatus && (
                <div className="info">
                  <div className="info-name">
                    {t("desktop.settings.rooms.devices.status")}
                  </div>
                  <div className="info-data">
                    <RoomDeviceStatus device={roomDevice} />
                  </div>
                </div>
              )}
            </div>

            <div className="settings">
              {settingsStatuses.map(({ label, value, show }, i) => {
                if (!show) {
                  return null
                }
                return (
                  <div className="settings-item" key={i}>
                    <div className="settings-item-label">{label}</div>
                    <div className="settings-item-value">{value}</div>
                  </div>
                )
              })}

              <div className="settings-actions">
                <div className="settings-action">
                  <span>
                    <Tooltip
                      uniqueId={generateString(4)}
                      content={
                        deviceSettingsDisabled
                          ? t(
                              "desktop.settings.rooms.devices.form.tooltips.unavailable_feature",
                            )
                          : ""
                      }
                    >
                      <Link
                        className={classNames({
                          disabled: deviceSettingsDisabled,
                        })}
                        to={settingsPath(buildingId, deviceId)}
                      >
                        {t(
                          "desktop.settings.rooms.devices.form.settings_actions.configure_device_settings",
                        )}
                      </Link>
                    </Tooltip>
                  </span>
                </div>

                <div className="settings-action">
                  <span>
                    <Tooltip
                      uniqueId={generateString(4)}
                      content={
                        buildingSettingsDisabled
                          ? t(
                              `desktop.settings.rooms.devices.form.tooltips.${!isBuildingSettingsEnabled ? "unavailable_feature" : "no_building_assigned"}` as ParseKeys,
                            )
                          : ""
                      }
                    >
                      <Link
                        className={classNames({
                          disabled: buildingSettingsDisabled,
                        })}
                        to={settingsPath(buildingId)}
                      >
                        {t(
                          "desktop.settings.rooms.devices.form.settings_actions.configure_building_settings",
                        )}
                      </Link>
                    </Tooltip>
                  </span>
                </div>

                <div className="settings-action">
                  <span>
                    <Link to={settingsPath()}>
                      {t(
                        "desktop.settings.rooms.devices.form.settings_actions.configure_global_settings",
                      )}
                    </Link>
                  </span>
                </div>
              </div>
            </div>

            <div className="actions">
              <Button
                isSmall
                variant="danger-pop"
                noConfirm
                onClick={() => handleUnpairConfirmation()}
              >
                {t("desktop.settings.rooms.devices.actions.un_pair")}
              </Button>
              <Button
                isSmall
                variant="secondary-white"
                onClick={() => handleRepair()}
              >
                {t("desktop.settings.rooms.devices.actions.re_pair")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>{t("desktop.settings.rooms.devices.no_device")}</div>
      )}
    </View>
  )
}

export default DeviceDetail
