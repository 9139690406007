import React, { useState } from "react"

import { t } from "i18next"
import { Trans } from "react-i18next"
import { useHistory } from "react-router-dom"

import { PERMISSIONS } from "../../../constants"
import { useCheckForPermission } from "../../../hooks/useCheckForPermission"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { DEPARTMENT_PATHS } from "./constants"

import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { useFetchDepartmentsQuery } from "../../../redux/api/departments"
import { DepartmentResponse } from "../../../redux/api/departments/types"

import Button from "../../../components/advanced/Button"
import Card from "../../../components/basic/Card"
import Loader from "../../../components/basic/Loader"
import BuildingFilter from "../../../components/Filter/BuildingFilter"
import Filters from "../../../components/Filter/Filters"
import FilterSpace from "../../../components/Filter/FilterSpace"
import SearchFilter from "../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../components/Filter/types"
import Heading from "../../../components/Heading"
import Intro from "../../../components/Intro"
import NoDataFound from "../../../components/NoDataFound"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./Departments.sass"

type DepartmentProps = {
  department: DepartmentResponse
}

function Department({ department }: DepartmentProps) {
  const history = useHistory()

  const canEditDepartment = useCheckForPermission(
    PERMISSIONS.departments.canChangeDepartment,
  )

  const handleEditClick = () =>
    history.push(`${DEPARTMENT_PATHS.edit}/${department.id}`)

  return (
    <Card>
      <div className="department">
        <div className="main">
          <div className="data">
            <div className="primary">
              <p className="department_name">{department.name}</p>
              <span className="secondary">
                &nbsp;/&nbsp;
                <Trans
                  i18nKey={`desktop.settings.departments.overview.users_in_department${
                    department.users?.length === 1 ? "_one" : ""
                  }`}
                  values={{ count: department.users?.length }}
                />
              </span>
            </div>
            {department &&
              (department.capacity_limit ||
                department.capacity_limit === 0) && (
                <div className="capacity_limit">
                  <Trans
                    i18nKey={`desktop.settings.departments.overview.capacity_limit`}
                    values={{ capacity: department.capacity_limit }}
                  />
                </div>
              )}
          </div>

          {canEditDepartment && (
            <Button
              onClick={() => handleEditClick()}
              className="edit_department"
              variant="secondary"
            >
              {t("desktop.settings.departments.overview.edit_department")}
            </Button>
          )}
        </div>
        {department && department.stats && department.stats.length > 0 && (
          <div className="statistics">
            <div className="applied">
              {t("desktop.settings.departments.overview.stats.applied")}
            </div>
            {department.stats.map((stat) => (
              <div key={stat.building_id}>
                {stat.building_name}: {stat.floor_count}{" "}
                {t("desktop.settings.departments.overview.stats.floors")},{" "}
                {stat.desk_count}{" "}
                {t("desktop.settings.departments.overview.stats.desks")}
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  )
}

function SettingsDepartments() {
  const history = useHistory()

  const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

  const [search, setSearch] = useState<string>("")

  const { value: buildingFilter, onChange: setBuildingFilter } =
    useLocalStorage(
      "departments-building-filter",
      FilterSpecialValues.NOT_ASSIGNED,
    )

  const selectedBuilding =
    buildings.find((building) => building.id === buildingFilter) ?? buildings[0]

  const fetchOptions = {
    stats: true,
    search,
    building_id:
      buildingFilter !== FilterSpecialValues.NOT_ASSIGNED &&
      buildingFilter !== FilterSpecialValues.ALL
        ? selectedBuilding?.id
        : undefined,
  }

  const { data: { results: departments = [] } = {}, isLoading } =
    useFetchDepartmentsQuery(fetchOptions)

  const canAddDepartment = useCheckForPermission(
    PERMISSIONS.departments.canAddDepartment,
  )

  const handleNewClick = () => history.push(DEPARTMENT_PATHS.add)

  const noDesksFoundAllBuildings =
    buildingFilter === FilterSpecialValues.ALL && departments.length === 0

  return (
    <View className="SettingsDepartments SettingsPage">
      <Heading>{t("desktop.settings.departments.title")}</Heading>

      <Intro isConstrained>
        {t("desktop.settings.departments.description")}
      </Intro>

      <Space size={0.75} />

      <Filters>
        <BuildingFilter
          value={buildingFilter}
          onChange={setBuildingFilter}
          showNotAssigned
          showAll
        />

        <SearchFilter
          value={search}
          onChange={setSearch}
          placeholder="Filter departments"
        />
        <FilterSpace />

        {canAddDepartment && (
          <Button onClick={handleNewClick} isSmall>
            {t("desktop.settings.departments.overview.add_department")}
          </Button>
        )}
      </Filters>

      <Space size={0.75} />

      {isLoading && <Loader className="loader" />}
      {!isLoading && noDesksFoundAllBuildings && (
        <NoDataFound>
          {t(
            "desktop.settings.departments.overview.no_departments_added_any_building",
          )}
        </NoDataFound>
      )}
      {!isLoading &&
        !noDesksFoundAllBuildings &&
        departments.length > 0 &&
        departments.map((department: DepartmentResponse) => {
          if (
            buildingFilter === FilterSpecialValues.NOT_ASSIGNED &&
            department.stats &&
            department.stats.length !== 0
          ) {
            return null
          }
          return <Department key={department.id} department={department} />
        })}
      {!isLoading &&
        !noDesksFoundAllBuildings &&
        departments.length === 0 &&
        search.length === 0 && (
          <Card className="small-font">
            {t("desktop.settings.departments.overview.no_departments_added")}
          </Card>
        )}
      {!isLoading &&
        !noDesksFoundAllBuildings &&
        departments.length === 0 &&
        search.length > 0 && (
          <Card className="small-font">
            {t("desktop.settings.departments.overview.no_departments")}
          </Card>
        )}
    </View>
  )
}

export default SettingsDepartments
