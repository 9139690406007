import classNames from "classnames"

import { AdvancedMarker, Map } from "@vis.gl/react-google-maps"

import { ReactComponent as PinSVG } from "../../../assets/images/icons/Pin.svg"

import "./style.sass"

type GoogleMapProps = {
  position: { lat: number; lng: number }
  className?: string
  zoom?: number
  controlSize?: number
}

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID ?? "google-map-vnct"

const GoogleMap = ({
  position,
  className,
  zoom = 16,
  controlSize = 32,
}: GoogleMapProps) => {
  if (!GOOGLE_API_KEY) {
    return null
  }

  const cn = classNames("GoogleMap", className)

  return (
    <div className={cn}>
      <Map
        center={position}
        zoom={zoom}
        gestureHandling="greedy"
        controlSize={controlSize}
        mapTypeControl={false}
        mapId={GOOGLE_MAP_ID}
      >
        <AdvancedMarker position={position}>
          <PinSVG className="GoogleMap__pin" />
        </AdvancedMarker>
      </Map>
    </div>
  )
}

export default GoogleMap
