import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import { ConfirmationModal } from "./advanced/ConfirmationModal"
import { Draggable } from "@hello-pangea/dnd"
import { useModals } from "@mattjennings/react-modal-stack"

import Card from "../components/basic/Card"

import { ReactComponent as DragHandleSVG } from "../assets/images/icons/DragHandle.svg"
import { ReactComponent as TrashSVG } from "../assets/images/icons/Trash.svg"

import "./SortableCard.sass"

type Props = {
  index: number
  disabled?: boolean
  onCardDeleted: (index: number) => void
}

export default function SortableCard({
  children,
  index,
  disabled,
  onCardDeleted,
}: PropsWithChildren<Props>) {
  const { openModal, closeModal } = useModals()

  const handleOnItemDelete = (index: number) => {
    if (disabled) {
      return
    }
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await deleteItem(index)
        closeModal()
      },
    })
  }

  const deleteItem = (index: number) => {
    onCardDeleted(index)
  }

  const SortableCardClasses = classNames("SortableCard", {
    disabled,
  })

  return (
    <Draggable
      isDragDisabled={disabled}
      draggableId={"item-" + index}
      index={index}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Card className={SortableCardClasses}>
            <div className="Content">{children}</div>
            <div className="Actions">
              <div className="DragHandle" {...provided.dragHandleProps}>
                <DragHandleSVG />
              </div>
              <div className="Delete" onClick={() => handleOnItemDelete(index)}>
                <TrashSVG />
              </div>
            </div>
          </Card>
        </div>
      )}
    </Draggable>
  )
}
