import React, { useCallback, useEffect, useMemo, useState } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { buildingsURL, departmentsURL, meURL } from "../../../../api"
import { PERMISSIONS } from "../../../../constants"
import { useCheckForPermission } from "../../../../hooks/useCheckForPermission"
import { useToast } from "../../../../hooks/useToast"
import { OptionType } from "../../../../types/sharedTypes"
import { getOption } from "../../../../utils"
import { PROFILE_PATHS, ROLES } from "../constants"

import { DepartmentResponse } from "../../../../redux/api/departments/types"
import { useFetchMeQuery } from "../../../../redux/api/me"
import { MeResponse } from "../../../../redux/api/me/types"
import { useFetchScimSettingsQuery } from "../../../../redux/api/scim"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"
import { useUpdateUserMutation } from "../../../../redux/api/users"
import {
  JOB_ROLES_OPTIONS,
  JOB_TITLES_OPTIONS,
} from "../../../../redux/api/users/constants"
import { UserProfileRequest } from "../../../../redux/api/users/types"
import { BuildingResponse } from "../../../../redux/buildings/types"
import { uploadFileCompat } from "../../../../redux/files/filesSlice"
import { removeDotsAndSpaces } from "../../../../redux/files/utils"
import { useAppSelector } from "../../../../redux/reducers"
import { selectSettingsEffective } from "../../../../redux/settings/selectors"
import { ImageResponse } from "../../../../redux/settings/types"
import { UserGroup } from "../../../../redux/user/types"
import { isPortalAdmin } from "../../../../redux/user/utils"
import { useActions } from "../../../../redux/utils"

import { AsyncCheckbox } from "../../../../components/advanced/AsyncCheckbox"
import AsyncSelect from "../../../../components/advanced/AsyncSelect"
import Button from "../../../../components/advanced/Button"
import { ImageSelector } from "../../../../components/basic/ImageSelector"
import { Input } from "../../../../components/basic/Input"
import { Select } from "../../../../components/basic/Select"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"
import PageForm from "../../../../components/Form/PageFormHook"
import { SsoProviders } from "../../../../components/SsoProviders"

import { ReactComponent as InfoSVG } from "../../../../assets/images/icons/InfoOutlined.svg"

type Props = {
  user?: MeResponse
}

type FormValues = {
  picture?: ImageResponse
  first_name?: string
  last_name?: string
  job_title?: OptionType
  job_role?: OptionType
  email?: string
  building?: BuildingResponse
  groups: string[]
  departments?: DepartmentResponse[]
  privacy_mode_enabled?: boolean
}

const formMapping = {
  "profile.picture_id": "picture",
  "profile.job_role": "job_role",
  "profile.job_title": "job_title",
  building_id: "building",
} as const

const GeneralForm = ({ user }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { errorToast, infoToast } = useToast()

  const [picture, setPicture] = useState<File | null>()

  const [saveUser] = useUpdateUserMutation()

  const { refetch } = useFetchMeQuery()

  const methods = useForm<FormValues>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      groups: [],
      departments: [],
      privacy_mode_enabled: false,
    },
  })

  const { control, reset, setError, watch } = methods

  const actions = useActions({
    uploadFile: (logo: File) => uploadFileCompat(logo),
  })

  const { data: scimSettings } = useFetchScimSettingsQuery()
  const { enabled: isSCIMEnabled } = scimSettings ?? {}

  const isEditingEnabled = !isSCIMEnabled

  const isAdmin = isPortalAdmin(user ?? { groups: [] })

  const [groups, email] = watch(["groups", "email"])

  const { entry: companySettings } = useAppSelector(selectSettingsEffective)

  const isCurrentUser = email === user?.email
  const hasPermissions =
    useCheckForPermission(PERMISSIONS.users.canChangeUser) || isCurrentUser

  const userRole = useMemo(
    () =>
      groups.includes(UserGroup.ADMIN)
        ? ROLES[UserGroup.ADMIN]
        : groups.includes(UserGroup.OFFICE_MANAGER)
          ? ROLES[UserGroup.OFFICE_MANAGER]
          : ROLES[UserGroup.USER],
    [groups],
  )

  const onUpdateClick = async (values: FormValues) => {
    const profile: UserProfileRequest = {
      job_title: values.job_title?.value ?? null,
      job_role: values.job_role?.value ?? null,
      ...(isAdmin && {
        use_case: user?.profile?.use_case,
        discovery: user?.profile?.discovery,
      }),
      picture_id: user?.profile?.picture?.id ?? null,
    }

    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email ?? "",
      building_id: values.building?.id,
      groups: values.groups,
      departments: values.departments?.map((d: DepartmentResponse) => d.id),
      privacy_mode_enabled: values.privacy_mode_enabled,
      profile,
    }

    if (picture) {
      const pictureWithFixedName = new File(
        [picture],
        removeDotsAndSpaces(picture.name),
      )

      const file = await actions.uploadFile(pictureWithFixedName)

      if (uploadFileCompat.fulfilled.match(file)) {
        payload.profile.picture_id = file.payload.id
      } else {
        errorToast(file.error.message)
        return
      }
    } else if (picture === null) {
      payload.profile.picture_id = null
    }

    const response = await saveUser(payload)

    if (response && isRejected(response)) {
      if (isApiResponseError(response.error)) {
        setErrors(response.error.formError, setError, errorToast, formMapping)
      }
      return
    }

    infoToast(t("desktop.settings.profile.general.form.update_success_toast"))
  }

  const refresh = useCallback(
    (value: boolean) => {
      const translationKey = value
        ? "desktop.settings.profile.general.form.update_privacy_settings_enabled_toast"
        : "desktop.settings.profile.general.form.update_privacy_settings_disabled_toast"

      infoToast(t(translationKey))

      refetch()
    },
    [infoToast, refetch, t],
  )

  useEffect(() => {
    if (!user) {
      return
    }

    const {
      first_name,
      last_name,
      email,
      building,
      departments,
      groups,
      privacy_mode_enabled,
      profile,
    } = user

    const job_title = getOption(JOB_TITLES_OPTIONS, profile?.job_title)
    const job_role = getOption(JOB_ROLES_OPTIONS, profile?.job_role)

    reset({
      picture: profile?.picture,
      first_name,
      last_name,
      job_title,
      job_role,
      email,
      building,
      departments,
      groups,
      privacy_mode_enabled,
    })
  }, [reset, user, isAdmin])

  return (
    <FormProvider {...methods}>
      <PageForm
        className="GeneralForm"
        updateMode
        onUpdate={onUpdateClick}
        additionalButton={
          <Button
            variant="secondary-white"
            onClick={() => history.push("/auth/logout")}
          >
            {t("desktop.settings.account.log_out_button")}
          </Button>
        }
      >
        <Field
          className="UserDetails__picture"
          control={control}
          name="picture"
          label={<PictureLabel />}
        >
          {({ value, ...props }) => (
            <ImageSelector
              {...props}
              label={t("desktop.onboarding.user_info.form.picture")}
              onChange={setPicture}
              image={value}
              accept="image/png,image/jpeg,image/jpg"
            />
          )}
        </Field>

        <Field
          control={control}
          name="first_name"
          label={t("desktop.settings.profile.general.form.first_name")}
          className="field-width-50"
        >
          {(props) => (
            <Input
              {...props}
              placeholder={t(
                "desktop.settings.profile.general.form.first_name",
              )}
              disabled={!(hasPermissions && isEditingEnabled)}
            />
          )}
        </Field>

        <Field
          control={control}
          name="last_name"
          label={t("desktop.settings.profile.general.form.last_name")}
          className="field-width-50"
        >
          {(props) => (
            <Input
              {...props}
              placeholder={t("desktop.settings.profile.general.form.last_name")}
              disabled={!(hasPermissions && isEditingEnabled)}
            />
          )}
        </Field>

        <Field
          control={control}
          name="job_title"
          label={t("desktop.settings.profile.general.form.job_title")}
          className="field-width-50"
        >
          {(props) => (
            <Select
              {...props}
              options={JOB_TITLES_OPTIONS}
              placeholder={t("desktop.settings.profile.general.form.job_title")}
              disabled={!(hasPermissions && isEditingEnabled)}
            />
          )}
        </Field>

        <Field
          control={control}
          name="job_role"
          label={t("desktop.settings.profile.general.form.job_role")}
          className="field-width-50"
        >
          {(props) => (
            <Select
              {...props}
              options={JOB_ROLES_OPTIONS}
              placeholder={t("desktop.settings.profile.general.form.job_role")}
              disabled={!(hasPermissions && isEditingEnabled)}
            />
          )}
        </Field>

        <Field
          control={control}
          name="email"
          label={t("desktop.settings.profile.general.form.current_email")}
          className="field-width-50"
        >
          {(props) => (
            <Input
              {...props}
              placeholder={t(
                "desktop.settings.profile.general.form.current_email",
              )}
              disabled
            />
          )}
        </Field>

        <Button variant="secondary" to={PROFILE_PATHS.email}>
          {t("desktop.settings.profile.general.form.change_email_button")}
        </Button>

        <hr />

        <div className="Label">
          {t("desktop.settings.profile.general.form.account_password")}
        </div>

        <div className="Subtext">
          {t("desktop.settings.profile.general.form.account_password_info")}
        </div>

        <Button
          variant="secondary"
          className="field-width-50"
          to={PROFILE_PATHS.password}
        >
          {t(
            "desktop.settings.profile.general.form.change_account_password_button",
          )}
        </Button>

        <hr />

        <Field
          control={control}
          name="building"
          className="field-width-50"
          label={t("desktop.settings.profile.general.form.default_building")}
        >
          {(props) => (
            <AsyncSelect
              {...props}
              urlGenerator={(fetchOptions) => buildingsURL(fetchOptions)}
              nothingFoundMessage={t(
                "desktop.settings.profile.general.form.no_building_found",
              )}
              getOptionLabel={(building) => building.name}
              getOptionValue={(building) => building.id}
              disabled={!(hasPermissions && isEditingEnabled)}
              isPaginated
            />
          )}
        </Field>

        <Field
          control={control}
          name="groups"
          label={t("desktop.settings.profile.general.form.user_role")}
          className="field-width-50"
        >
          {() => (
            <Input
              placeholder={t("desktop.settings.profile.general.form.user_role")}
              value={userRole}
              disabled
            />
          )}
        </Field>

        <Field
          control={control}
          name="departments"
          label={t("desktop.settings.profile.general.form.departments")}
        >
          {({ ref, ...props }) => (
            <AsyncSelect<DepartmentResponse, true>
              urlGenerator={(fetchOptions) => departmentsURL(fetchOptions)}
              nothingFoundMessage={t(
                "desktop.settings.profile.general.form.no_departments_found",
              )}
              getOptionLabel={(department) => department.name}
              getOptionValue={(department) => department.id}
              isMulti
              disabled={!(hasPermissions && isEditingEnabled)}
              {...props}
            />
          )}
        </Field>

        <hr />

        <div className="FieldWrapper">
          <div className="Label">
            {t("desktop.settings.profile.general.form.privacy")}
          </div>

          <div>
            <AsyncCheckbox
              label={t(
                "desktop.settings.profile.general.form.book_desks_anonymously",
              )}
              urlGenerator={() => meURL()}
              bodyGenerator={(value: boolean) => ({
                privacy_mode_enabled: value,
              })}
              type="put"
              value={
                companySettings?.privacy_mode_enabled
                  ? user?.privacy_mode_enabled
                  : false
              }
              refresh={refresh}
              isSecondary
              disabled={!companySettings?.privacy_mode_enabled}
            />
          </div>
          {!companySettings?.privacy_mode_enabled && (
            <div className="Subtext info">
              <InfoSVG />
              <div>
                {t(
                  "desktop.settings.profile.general.form.privacy_mode_disabled_info",
                )}
              </div>
            </div>
          )}
        </div>

        <hr />

        <div className="Label">
          {t("desktop.settings.profile.general.form.connected_accounts")}
        </div>

        <div className="Subtext">
          {t("desktop.settings.profile.general.form.connected_accounts_info")}
        </div>

        <SsoProviders />
      </PageForm>
    </FormProvider>
  )
}

const PictureLabel = () => {
  const { t } = useTranslation()
  return (
    <div>
      <p>{t("desktop.onboarding.user_info.form.picture_info.title")}</p>
      <p className="picture__text">
        {t("desktop.onboarding.user_info.form.picture_info.guidelines")}
      </p>
      <p className="picture__text">
        {t("desktop.onboarding.user_info.form.picture_info.avatar_text")}
      </p>
    </div>
  )
}

export default GeneralForm
