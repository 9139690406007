import { ParseKeys } from "i18next"

import { Children } from "../../../../types/sharedTypes"

import {
  isExchangeCalendar,
  isGoogleCalendar,
  isICalendar,
  isOffice365Calendar,
} from "../../../../redux/api/calendars"
import {
  AvailableCalendars,
  CalendarType,
} from "../../../../redux/api/calendars/types"

import { ReactComponent as ExchangeSVG } from "../../../../assets/images/calendars/Exchange.svg"
import { ReactComponent as GoogleSVG } from "../../../../assets/images/calendars/Google.svg"
import { ReactComponent as ICalendarSVG } from "../../../../assets/images/calendars/ICalendar.svg"
import { ReactComponent as Office365SVG } from "../../../../assets/images/calendars/Microsoft365.svg"

export const CALENDAR_PROVIDERS: Record<
  CalendarType,
  { title: string; icon: Children; description: ParseKeys; kbKey: string }
> = {
  [CalendarType.GOOGLE]: {
    title: "Google Workspace",
    icon: <GoogleSVG />,
    description:
      "desktop.settings.integrations.calendars.providers.description",
    kbKey: "prepare-google",
  },
  [CalendarType.EXCHANGE]: {
    title: "Microsoft Exchange",
    icon: <ExchangeSVG />,
    description:
      "desktop.settings.integrations.calendars.providers.description",
    kbKey: "prepare-exchange",
  },
  [CalendarType.ICALENDAR]: {
    title: "iCalendar",
    icon: <ICalendarSVG />,
    description:
      "desktop.settings.integrations.calendars.providers.description",
    kbKey: "prepare-icalendar",
  },
  [CalendarType.OFFICE365]: {
    title: "Office 365",
    icon: <Office365SVG />,
    description:
      "desktop.settings.integrations.calendars.providers.description",
    kbKey: "prepare-office365",
  },
}

export const checkCalendarCompletion = (calendar: AvailableCalendars) => {
  if (isGoogleCalendar(calendar)) {
    return !!calendar.user
  }

  if (isExchangeCalendar(calendar)) {
    return !!calendar.user && !!calendar.url
  }

  if (isICalendar(calendar)) {
    return true
  }

  if (isOffice365Calendar(calendar)) {
    return !!calendar.user
  }

  return false
}
