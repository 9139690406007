import React, { useCallback, useMemo, useState } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"

import { LabelPaths } from "../../../../../constants"
import { KeyAndName } from "../../../../../types/sharedTypes"
import { getLabel } from "../../../../../utils"
import { useCustomContentContext } from "../CustomContentContext"

import { useFetchRoomsDevicesQuery } from "../../../../../redux/api/devices"
import { DEVICE_TYPES } from "../../../../../redux/api/devices/constants"
import { RoomDeviceResponse } from "../../../../../redux/api/devices/types"

import Button from "../../../../../components/advanced/Button"
import Table from "../../../../../components/advanced/Table"
import { Column } from "../../../../../components/basic/Table"
import { Tooltip } from "../../../../../components/basic/Tooltip"
import BuildingFilter from "../../../../../components/Filter/BuildingFilter"
import DeviceBatteryFilter from "../../../../../components/Filter/DeviceBatteryFilter"
import DeviceWiFiFilter, {
  DISCONNECTED,
  filterDevicesByWifiLvlConnection,
  STRONG_WIFI,
  WEAK_WIFI,
} from "../../../../../components/Filter/DeviceWiFiFilter"
import Filters from "../../../../../components/Filter/Filters"
import FloorFilter from "../../../../../components/Filter/FloorFilter"
import SearchFilter from "../../../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../../../components/Filter/types"
import PageForm from "../../../../../components/Form/PageFormHook"
import Space from "../../../../../components/Space"
import RoomDeviceStatus from "../../../../../components/Visitors/RoomDeviceStatus"

import { ReactComponent as InfoSVG } from "../../../../../assets/images/icons/InfoOutlined.svg"

import "./EditDevicesForm.sass"

const DevicesEmptyTableRows = () => {
  const { t } = useTranslation()

  return (
    <div className="DevicesTableNoView">
      <div className="DevicesTableNoViewTop">
        <InfoSVG />
        <p>{t("desktop.settings.visitors.devices.no_devices.title")}</p>
      </div>
      <div className="DevicesTableNoViewBottom">
        {t("desktop.settings.visitors.devices.no_devices.description")}
        <Button
          className="DevicesTableNoViewBottomButton"
          variant="link"
          to="/settings/rooms/devices/pair"
        >
          {t("desktop.settings.rooms.devices.no_devices.description_link")}
        </Button>
      </div>
    </div>
  )
}

const EditDevicesForm = () => {
  const { t } = useTranslation()

  const history = useHistory()

  const { search } = useLocation()

  const query = useMemo(() => new URLSearchParams(search), [search])

  const { devices, onChangeDevices, paths } = useCustomContentContext()

  // Filters
  const [buildingFilter, setBuildingFilter] = useState<string>(
    query.get("building") || FilterSpecialValues.ALL,
  )

  const [floorFilter, setFloorFilter] = useState<string>(
    query.get("floor") || FilterSpecialValues.ALL,
  )

  const [batteryFilter, setBatteryFilter] = useState<string>(
    FilterSpecialValues.ALL,
  )

  const [wiFiFilter, setWiFiFilter] = useState<string>(FilterSpecialValues.ALL)

  const [searchFilter, setSearchFilter] = useState<string>("")

  const [selectedRows, setSelectedRows] = useState<RoomDeviceResponse[]>([])

  const selectedIdsDevices = useMemo(
    () => selectedRows.map((sd) => sd.id),
    [selectedRows],
  )

  const { data: { results: roomsDevices = [] } = {}, isFetching: isLoading } =
    useFetchRoomsDevicesQuery({
      building: buildingFilter,
      floor: floorFilter,
      search: searchFilter,
      battery: batteryFilter,
    })

  const devicesWithCustomContent = useMemo(
    () =>
      roomsDevices.filter(
        (d) =>
          d.type === DEVICE_TYPES.joan_6 || d.type === DEVICE_TYPES.joan_13,
      ),
    [roomsDevices],
  )

  const handleSelectRow = (d: RoomDeviceResponse[]) => setSelectedRows(d)

  // TODO: When BE is ready, need to delete and modify the useFetchRoomsDevicesQuery
  const filteredRoomsDevices = useMemo(() => {
    if (wiFiFilter === FilterSpecialValues.ALL) return devicesWithCustomContent

    let filteredDevices = devicesWithCustomContent

    if (wiFiFilter.includes(STRONG_WIFI)) {
      filteredDevices = filterDevicesByWifiLvlConnection(
        STRONG_WIFI,
        filteredDevices,
      )
    }

    if (wiFiFilter.includes(WEAK_WIFI)) {
      filteredDevices = filterDevicesByWifiLvlConnection(
        WEAK_WIFI,
        filteredDevices,
      )
    }

    if (wiFiFilter.includes(DISCONNECTED)) {
      filteredDevices = filterDevicesByWifiLvlConnection(
        DISCONNECTED,
        filteredDevices,
      )
    }

    return filteredDevices
  }, [wiFiFilter, devicesWithCustomContent])

  const initialSelectedRows = useMemo(
    () => filteredRoomsDevices.filter((d) => devices.includes(d.id)),
    [devices, filteredRoomsDevices],
  )

  const tableColumns = useMemo<Column<RoomDeviceResponse>[]>(
    () => [
      {
        field: "name",
        label: t("desktop.settings.rooms.devices.table.device_name"),
        renderCell: ({ name, type }) => {
          const deviceType = getLabel(
            `deviceTypes.${type.toLowerCase()}` as LabelPaths,
          )

          return deviceType + (name && ` - ${name}`)
        },
      },
      {
        cellClassName: "rooms",
        field: "rooms",
        label: t("desktop.settings.rooms.devices.table.calendar"),
        renderCell: ({ rooms }) => roomsDevicesCell(rooms),
      },
      {
        field: "type",
        label: t("desktop.settings.rooms.devices.table.device_status"),
        renderCell: (device) => <RoomDeviceStatus device={device} />,
      },
      {
        field: "status_sent_at",
        cellClassName: "last-reported",
        label: t("desktop.settings.rooms.devices.table.last_reported"),
        renderCell: ({ status_sent_at }) =>
          status_sent_at ? dayjs(status_sent_at).fromNow() : "-",
      },
    ],
    [t],
  )

  const roomsDevicesCell = (rooms: KeyAndName[]) => {
    if (rooms.length) {
      return (
        <>
          {rooms.length === 1 ? (
            <Tooltip
              className="single-room"
              key={rooms[0].key}
              uniqueId={`${rooms[0].key}`}
              content={rooms[0].name}
            >
              {rooms[0].name}
            </Tooltip>
          ) : (
            rooms.map((room, index, array) => {
              const isLastElement = index === array.length - 1

              return (
                <span key={room?.name ?? index}>
                  {room?.name ?? ""}
                  {!isLastElement ? ", " : ""}
                </span>
              )
            })
          )}
        </>
      )
    } else return "-"
  }

  const onUpdateClick = useCallback(async () => {
    onChangeDevices(selectedIdsDevices)

    history.push(paths.root)
  }, [history, onChangeDevices, paths.root, selectedIdsDevices])

  return (
    <div className="EditDevicesForm">
      <PageForm updateMode onUpdate={onUpdateClick} backUrl={paths.root}>
        <div>
          <Filters>
            <BuildingFilter
              onChange={setBuildingFilter}
              value={buildingFilter}
              showAll
            />
            <FloorFilter
              onChange={setFloorFilter}
              value={floorFilter}
              buildingId={buildingFilter}
              showAll
            />

            <DeviceBatteryFilter
              onChange={setBatteryFilter}
              value={batteryFilter}
            />

            <DeviceWiFiFilter onChange={setWiFiFilter} value={wiFiFilter} />

            <SearchFilter
              placeholder={t(
                "desktop.settings.visitors.devices.search_devices",
              )}
              onChange={setSearchFilter}
              value={searchFilter}
            />
          </Filters>

          <Space size={0.75} />

          <Table
            isSelectable
            loading={isLoading}
            rows={filteredRoomsDevices}
            columns={tableColumns}
            initialSelectedRows={initialSelectedRows}
            onSelectedRows={handleSelectRow}
            emptyTableCell={<DevicesEmptyTableRows />}
          />
        </div>
      </PageForm>
    </div>
  )
}

export default EditDevicesForm
