import React, { useLayoutEffect, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { internalTimeFormat, userTimeFormat } from "../../utils"
import { FromToTimePicker } from "./FromToTimePicker"

import { useFetchTimeslotsQuery } from "../../redux/api/timeslots"
import { useAppSelector } from "../../redux/reducers"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { TimeslotResponse } from "../../redux/timeslots/types"
import {
  areSlotsOverlapping,
  timeslotComparator,
} from "../../redux/timeslots/utils"

import "./TimeslotPicker.sass"

type TimeslotPickerProps = {
  selectedSlot: Partial<TimeslotResponse> | null
  onSelect: (d: Partial<TimeslotResponse> | null) => void
  customFrom: string
  onCustomFrom: (d: string) => void
  customTo: string
  onCustomTo: (d: string) => void
  preferredTime: string
  defaultTimeSpanMinutes?: number
  onlyInput?: boolean
  unavailableSlots?: Array<Partial<TimeslotResponse>>
}

export const TimeslotPicker: React.FC<TimeslotPickerProps> = ({
  selectedSlot,
  onSelect,
  customFrom,
  onCustomFrom,
  customTo,
  onCustomTo,
  preferredTime,
  defaultTimeSpanMinutes = 480,
  onlyInput = false,
  unavailableSlots,
}) => {
  const { t } = useTranslation()

  const [filteredSlots, setFilteredSlots] = useState<TimeslotResponse[]>([])
  const { data: slots = [] } = useFetchTimeslotsQuery()
  const { entry: settings } = useAppSelector(selectSettingsEffective)

  useLayoutEffect(() => {
    if (unavailableSlots !== undefined) {
      setFilteredSlots(
        slots
          .filter((s) => {
            return (
              unavailableSlots.find((us) =>
                areSlotsOverlapping(
                  { from: s.from, to: s.to },
                  { from: us.from, to: us.to },
                ),
              ) === undefined
            )
          })
          .sort(timeslotComparator),
      )
    } else {
      setFilteredSlots(slots.slice(0).sort(timeslotComparator))
    }
  }, [slots, unavailableSlots])

  useLayoutEffect(() => {
    if (selectedSlot === null && settings) {
      if (!onlyInput && filteredSlots.length > 0) {
        onSelect(filteredSlots[0])
      } else {
        onSelect({ from: customFrom, to: customTo })
      }
    }
  }, [
    onSelect,
    onCustomFrom,
    onCustomTo,
    onlyInput,
    selectedSlot,
    preferredTime,
    defaultTimeSpanMinutes,
    filteredSlots,
    settings,
    customFrom,
    customTo,
  ])

  const handleSlotChange = (slot: TimeslotResponse) => {
    onSelect(slot)

    onCustomFrom(slot.from)
    onCustomTo(slot.to)
  }

  const handleCustomFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect({ from: e.target.value, to: customTo })
    onCustomFrom(e.target.value)
  }

  const handleCustomToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect({ from: customFrom, to: e.target.value })
    onCustomTo(e.target.value)
  }

  return (
    <div className="TimeslotPicker">
      {!onlyInput && filteredSlots.length < 1 ? (
        <span className="no-slots">{t("mobile.general.no_timeslots")}</span>
      ) : (
        <div className="scroll-vertical">
          {filteredSlots.map((slot) => {
            const itemClass = classNames({
              "scroll-item": true,
              active:
                slot.from === selectedSlot?.from &&
                slot.to === selectedSlot?.to,
            })

            return (
              <div
                className={itemClass}
                key={slot.id}
                onClick={() => {
                  handleSlotChange(slot)
                }}
              >
                <div className="slot-name">{slot.name}</div>
                <div className="slot-time">
                  {dayjs(slot.from, internalTimeFormat()).format(
                    userTimeFormat(),
                  )}
                  {" - "}
                  {dayjs(slot.to, internalTimeFormat()).format(
                    userTimeFormat(),
                  )}
                </div>
              </div>
            )
          })}
        </div>
      )}
      {(!settings?.desk_force_timeslot_use || onlyInput) && (
        <div className="custom-slot">
          {!onlyInput && (
            <div className="subtext">
              {t("mobile.general.choose_custom_time")}
            </div>
          )}
          <FromToTimePicker
            from={customFrom}
            to={customTo}
            onFromChange={handleCustomFromChange}
            onToChange={handleCustomToChange}
          />
        </div>
      )}
    </div>
  )
}
