import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react"

import { DEFAULT_APP_PARAMS, WHITELABEL_APP_PARAMS } from "../constants"
import { useCheckForFeatureFlag } from "../hooks/useCheckForFeatureFlag"
import { Capacitor } from "@capacitor/core"

import {
  AppParamsType,
  changeAppParams,
} from "../redux/appParams/appParamsSlice"
import { getAppParams } from "../redux/appParams/selectors"
import { fetchFeatureFlags } from "../redux/feature_flags/featureFlagsSlice"
import { selectFeatureFlags } from "../redux/feature_flags/selectors"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

import { MemoizedErrorFallback } from "../components/RouteView"

function AppParamsProvider({ children }: PropsWithChildren<unknown>) {
  const actions = useActions({
    fetchFeatureFlags: () => fetchFeatureFlags(),
    changeAppParams: (appParams: AppParamsType) => changeAppParams(appParams),
  })

  const { appName, appManifest } = useAppSelector(getAppParams)

  const isWhitelabelEnabled = useCheckForFeatureFlag(
    "enterprise.whitelabel.isEnabled",
  )

  const isWhitelabelBuildEnabled =
    process.env.REACT_APP_MOBILE_MODE === "WHITELABEL"

  const { isLoaded: areFeatureFlagsLoaded, error } =
    useAppSelector(selectFeatureFlags)

  const memoizedError = useMemo(() => (error ? Error(error) : null), [error])

  const isWebPlatform = Capacitor.getPlatform() === "web"

  const reloadPage = useCallback(() => window.location.reload(), [])

  const setAppPageMetaInfo = useCallback((): void => {
    const webAppNameTag: HTMLInputElement | null = document.querySelector(
      'meta[name="apple-mobile-web-app-title"]',
    )
    const webAppManifestTag: HTMLInputElement | null = document.querySelector(
      'link[rel="manifest"]',
    )

    document.title = isWhitelabelEnabled ? "MyRoomBooking" : "MyJoan"

    if (webAppNameTag) {
      webAppNameTag.setAttribute("content", appName)
    }

    if (webAppManifestTag) {
      webAppManifestTag.setAttribute("href", appManifest)
    }
  }, [appName, appManifest, isWhitelabelEnabled])

  const initAppParams = useCallback(() => {
    let appParams

    if (!isWebPlatform) {
      appParams = isWhitelabelBuildEnabled
        ? WHITELABEL_APP_PARAMS
        : DEFAULT_APP_PARAMS
    } else {
      if (areFeatureFlagsLoaded && !error) {
        appParams = isWhitelabelEnabled
          ? WHITELABEL_APP_PARAMS
          : DEFAULT_APP_PARAMS
      }
    }

    if (appParams) {
      actions.changeAppParams(appParams)
    }

    setAppPageMetaInfo()
  }, [
    isWebPlatform,
    setAppPageMetaInfo,
    isWhitelabelBuildEnabled,
    areFeatureFlagsLoaded,
    error,
    isWhitelabelEnabled,
    actions,
  ])

  useEffect(() => {
    if (!areFeatureFlagsLoaded) {
      actions.fetchFeatureFlags()
    }
  }, [areFeatureFlagsLoaded, actions])

  useEffect(() => {
    initAppParams()
  }, [initAppParams])

  if (!areFeatureFlagsLoaded) return null

  if (memoizedError) {
    return (
      <MemoizedErrorFallback resetError={reloadPage} error={memoizedError} />
    )
  }

  return <>{children}</>
}

export default AppParamsProvider
