import { forwardRef, useEffect } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import {
  BasicDateTimePicker,
  BasicDateTimePickerProps,
} from "../../basic/DateTimePicker"
import { getTimeFormat, loadLocaleData } from "./utils"

export type DateTimePickerProps = BasicDateTimePickerProps

export const DateTimePicker = forwardRef<HTMLInputElement, DateTimePickerProps>(
  ({ withTime = true, ...props }: DateTimePickerProps, ref) => {
    const { t } = useTranslation()

    const timeCaption = t(
      "desktop.settings.rooms.custom_content.forms.general.time_label",
    )

    const locale = dayjs.locale()

    const timeFormat = getTimeFormat(locale)

    const dateFormat = withTime ? `MMMM d, yyyy ${timeFormat}` : "MMMM d, yyyy"

    useEffect(() => {
      loadLocaleData(locale)
    }, [locale])

    return (
      <BasicDateTimePicker
        ref={ref}
        {...props}
        withTime={withTime}
        locale={locale}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        timeCaption={timeCaption}
      />
    )
  },
)
