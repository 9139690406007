import React, { useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import useCheckReservationWindowLength from "../../hooks/useCheckReservationWindowLength"

import { ReactComponent as CalendarSVG } from "../../assets/images/icons/Calendar.svg"

import "./DatePicker.sass"

type DatePickerProps = {
  selectedDate: Dayjs
  onSelect: (d: Dayjs) => void
}

export const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  onSelect,
}) => {
  const { t } = useTranslation()
  const [customDate, setCustomDate] = useState(false)

  const dateClass = classNames({
    "scroll-item": true,
    active: customDate,
  })

  const desk_reservation_window_length = useCheckReservationWindowLength()

  const reservationWindowLength = desk_reservation_window_length || 7
  const daysRange = Array.from(Array(reservationWindowLength).keys())

  const handleDateChange = (event: React.ChangeEvent<{ value: string }>) => {
    const newDay = dayjs(event.target.value)
    if (newDay.isValid()) {
      onSelect(newDay)
      setCustomDate(true)
    } else {
      onSelect(dayjs())
      setCustomDate(false)
    }
  }

  return (
    <div className="DatePicker">
      <div className="scroll-vertical">
        <div className={dateClass}>
          <div className="date">
            <CalendarSVG />
            {customDate && (
              <div>
                <div className="slot-name">{selectedDate.format("ddd")}</div>
                <div className="slot-time">{selectedDate.format("MMM D")}</div>
              </div>
            )}
            <input
              type="date"
              className="date-input"
              min={dayjs().format("YYYY-MM-DD")}
              max={dayjs()
                .add(desk_reservation_window_length ?? 7, "day")
                .format("YYYY-MM-DD")}
              onChange={handleDateChange}
            />
          </div>
        </div>
        {daysRange.map((i) => {
          const today = dayjs()
          const d = today.add(i, "day")

          const isToday = today.isSame(d, "day")
          const isTomorrow = today.add(1, "day").isSame(d, "day")
          const formattedDay = dayjs(d).format("ddd")

          const day = isToday
            ? t("mobile.general.today")
            : isTomorrow
              ? t("mobile.general.tomorrow")
              : formattedDay

          const itemClass = classNames({
            "scroll-item": true,
            active: d.isSame(selectedDate, "day") && !customDate,
          })

          return (
            <div
              className={itemClass}
              key={i}
              onClick={() => {
                onSelect(d)
                setCustomDate(false)
              }}
            >
              <div className="slot-name">{day}</div>
              <div className="slot-time">{d.format("MMM D")}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
