import React, { useCallback } from "react"

import cn from "classnames"
import { useTranslation } from "react-i18next"

import { useNavigation } from "../../../../hooks/useNavigation"
import { CUSTOM_CONTENT_TYPES } from "./constants"
import { useCustomContentContext } from "./CustomContentContext"
import GalleryContentPreview from "./Previews/GalleryContentPreview"
import TextContentPreview from "./Previews/TextContentPreview"
import WebpageContentPreview from "./Previews/WebpageContentPreview"
import { CONTENT_TYPES_OPTIONS, getSectionContentTypeAsString } from "./utils"

import {
  Section,
  SectionContentTypeEnum,
} from "../../../../redux/api/customContent/types"

import { ReactComponent as PencilSVG } from "../../../../assets/images/icons/Pencil.svg"
import { ReactComponent as PlusSVG } from "../../../../assets/images/icons/PlusThin.svg"
import { ReactComponent as TrashSVG } from "../../../../assets/images/icons/Trash.svg"

import "./ContentGridSection.sass"

type Props = {
  index: number
  section: Section
}

const ContentGridSection = ({ index, section }: Props) => {
  const { t } = useTranslation()
  const { push } = useNavigation()

  const { paths, onDeleteSectionContent, setSelectedSection } =
    useCustomContentContext()

  const sectionContent = CONTENT_TYPES_OPTIONS.find(
    (o) => o.type === section.type,
  )

  const handleCreateContentTypeClick = useCallback(() => {
    push(paths.contentTypes)

    setSelectedSection(index)
  }, [index, paths.contentTypes, push, setSelectedSection])

  const handleUpdateContentTypeClick = useCallback(() => {
    if (section.type !== null) {
      if (section.type !== CUSTOM_CONTENT_TYPES.roomStatus) {
        const contentType = getSectionContentTypeAsString(section.type)

        push(paths[contentType])
      } else if (section.type === CUSTOM_CONTENT_TYPES.roomStatus) {
        push(paths.contentTypes)
      } else {
        console.warn("Unknown section")
      }
    }

    setSelectedSection(index)
  }, [index, paths, push, section.type, setSelectedSection])

  if (section.type == null || !section.content) {
    return (
      <div
        className={cn("ContentGridSection", "NoContent")}
        onClick={handleCreateContentTypeClick}
      >
        <PlusSVG />
        <div>
          {t(
            "desktop.settings.rooms.custom_content.upload_custom_content_button",
          )}
        </div>
      </div>
    )
  }

  if (section.type === SectionContentTypeEnum.Webpage) {
    return (
      <WebpageContentPreview
        index={index}
        sectionContent={section.content}
        onDeleteSectionContent={onDeleteSectionContent}
        onUpdateSectionContent={handleUpdateContentTypeClick}
      />
    )
  } else if (section.type === SectionContentTypeEnum.Gallery) {
    return (
      <GalleryContentPreview
        index={index}
        sectionContent={section.content}
        onDeleteSectionContent={onDeleteSectionContent}
        onUpdateSectionContent={handleUpdateContentTypeClick}
        fallbackIcon={sectionContent?.icon}
      />
    )
  } else if (section.type === SectionContentTypeEnum.Text) {
    return (
      <TextContentPreview
        index={index}
        sectionContent={section.content}
        onDeleteSectionContent={onDeleteSectionContent}
        onUpdateSectionContent={handleUpdateContentTypeClick}
      />
    )
  } else
    return (
      <div
        className="ContentGridSection"
        onClick={handleUpdateContentTypeClick}
      >
        <div className="ContentSectionActions">
          <button type="button" className="UpdateSectionContent">
            <PencilSVG />
          </button>
          <button
            type="button"
            className="DeleteSectionContent"
            onClick={(e) => {
              e.stopPropagation()
              onDeleteSectionContent(index)
            }}
          >
            <TrashSVG />
          </button>
        </div>
        <div className="ContentSectionIcon">{sectionContent?.icon}</div>
      </div>
    )
}

export default ContentGridSection
