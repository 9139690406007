import React, { useEffect, useRef } from "react"

import queryString from "query-string"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { Redirect } from "react-router-dom"

import { getAppParams } from "../redux/appParams/selectors"
import {
  clearAuth,
  fetchAuthCodeSuccess,
  setIsLogin,
} from "../redux/auth/authSlice"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

import Heading from "../components/Heading"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"

const { parse } = queryString

function AuthCallback() {
  const dispatch = useDispatch()
  const oldCode = useAppSelector((state) => state.auth.code)
  const actions = useActions({
    fetchAuthCodeSuccess: (code: string) => fetchAuthCodeSuccess(code),
    setIsLoginTrue: () => setIsLogin(true),
  })

  /**
   * JOAN-7251: Access token does not get removed from local storage so we need to clear it programatically on callback
   * In case of being redirected from a different subdomain, the local storage will still contain the expired token which should have been deleted
   * Please refer to the JIRA ticket for more details as to why this is necessary
   */
  const existingToken = useRef(localStorage.getItem("access_token"))
  useEffect(() => {
    if (existingToken.current) {
      dispatch(clearAuth())
      existingToken.current = null
    }
  }, [dispatch])
  /*  */

  const { search } = useLocation()
  const { code: newCode, state: redirectTo } = parse(search)

  const { appName } = useAppSelector(getAppParams)

  // fix for Cannot update a component (`AppParamsProvider`) while rendering a different component (`AuthCallback`)
  useEffect(() => {
    if (oldCode !== newCode && typeof newCode === "string") {
      actions.fetchAuthCodeSuccess(newCode)
    }
  }, [actions, newCode, oldCode])

  useEffect(() => {
    if (newCode) {
      actions.setIsLoginTrue()
    }
  }, [actions, newCode])

  if (newCode) {
    return <Redirect to={(redirectTo as string) || "/"} />
  } else {
    return (
      <RouteView className="AuthCallback">
        <Screen>
          <Heading>Authorization failed</Heading>
          <p>Code missing in reply from {appName} Portal.</p>
        </Screen>
      </RouteView>
    )
  }
}

export default AuthCallback
