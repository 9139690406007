import { useTranslation } from "react-i18next"

import Button from "../components/advanced/Button"

import { ReactComponent as AlertIcon } from "../assets/images/icons/Alert.svg"
import { ReactComponent as RefreshUpdateIcon } from "../assets/images/icons/RefreshUpdate.svg"

import "./ServiceWorkerUpdateNotification.sass"

type ServiceWorkerUpdateNotificationProps = {
  reload: () => void
}

const ServiceWorkerUpdateNotification = ({
  reload,
}: ServiceWorkerUpdateNotificationProps) => {
  const { t } = useTranslation()
  return (
    <div className="UpdateNotification">
      <div className="TitleRow">
        <p className="UpdateTitle">
          {t("desktop.components.update_toast.new_version_available")}
        </p>
        <AlertIcon />
      </div>
      <p className="UpdateDescription">
        {t("desktop.components.update_toast.update_found")} <br />{" "}
        {t("desktop.components.update_toast.refresh_page_text")}
      </p>
      <Button className="RefreshButton" onClick={() => reload()}>
        <RefreshUpdateIcon />
        {t("desktop.components.update_toast.refresh_page_button")}
      </Button>
    </div>
  )
}

export default ServiceWorkerUpdateNotification
