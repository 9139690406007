import { useTranslation } from "react-i18next"
import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { ReservationType } from "../redux/reservations/types"

import { FailedReservations } from "../components/FailedReservations"

type Props = {
  open: boolean
  failedReservations: any[]
  reservationType: ReservationType
  onClose?: () => void
}

export default function FailedReservationsModal({
  open,
  failedReservations,
  reservationType,
  onClose,
}: Props) {
  const { closeAllModals } = useModals()
  const { t } = useTranslation()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => {
        onClose?.()
        closeAllModals()
      }}
      ariaHideApp={false}
    >
      <FailedReservations
        type="web"
        title={t("We couldn't create reservations for the following days")}
        buttonText={"Confirm"}
        failedReservations={failedReservations}
        handleClose={() => {
          onClose?.()
          closeAllModals()
        }}
        reservationType={reservationType}
      />
    </ReactModal>
  )
}
