import { Children } from "../../../../../types/sharedTypes"
import "../ContentGridSection.sass"

import {
  GalleryContent,
  SectionContent,
} from "../../../../../redux/api/customContent/types"

import { ReactComponent as PencilSVG } from "../../../../../assets/images/icons/Pencil.svg"
import { ReactComponent as TrashSVG } from "../../../../../assets/images/icons/Trash.svg"

import "./GalleryContentPreview.sass"

type GalleryContentPreviewProps = {
  index: number
  sectionContent: SectionContent
  onDeleteSectionContent: (index: number) => void
  onUpdateSectionContent: () => void
  fallbackIcon: Children
}

const GalleryContentPreview = ({
  index,
  sectionContent,
  onDeleteSectionContent,
  onUpdateSectionContent,
  fallbackIcon,
}: GalleryContentPreviewProps) => {
  const { images } = sectionContent as GalleryContent

  const image = images ? images[0] : null

  return (
    <div
      className="ContentGridSection GalleryContentPreview"
      onClick={onUpdateSectionContent}
    >
      <div className="ContentSectionActions">
        <button type="button" className="UpdateSectionContent">
          <PencilSVG />
        </button>
        <button
          type="button"
          className="DeleteSectionContent"
          onClick={(e) => {
            e.stopPropagation()
            onDeleteSectionContent(index)
          }}
        >
          <TrashSVG />
        </button>
      </div>
      {image ? (
        <img
          onClick={onUpdateSectionContent}
          className="PreviewImage"
          src={image.src}
          title="Gallery content"
        />
      ) : (
        fallbackIcon
      )}
    </div>
  )
}

export default GalleryContentPreview
