import React, { useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import { userTimeFormat } from "../../../utils"
import Datestring from "../../Datestring"

import "./style.sass"

const Clock = () => {
  const [date, setDate] = useState<Dayjs>(dayjs())

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(dayjs())
    }, 1000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return (
    <div className="Clock">
      <div>
        <Datestring date={date} isLong isInline />
      </div>{" "}
      <div>{date.format(userTimeFormat())}</div>
    </div>
  )
}

export default Clock
