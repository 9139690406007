import React, { PropsWithChildren } from "react"

import { OnboardingGuard } from "../screens/Onboarding"
import AppParamsProvider from "./AppParamsProvider"
import AppUpdateProvider from "./AppUpdateProvider"
import AuthProvider from "./AuthProvider"
import { CheckInProvider } from "./CheckInProvider"
import CompanyProvider from "./CompanyProvider"
import { InitializationProvider } from "./InitializationProvider"
import LoadingProvider from "./LoadingProvider"
import LocalizationProvider from "./LocalizationProvider"
import MobileCompanyProvider from "./MobileCompanyProvider"
import MobileLoadingProvider from "./MobileLoadingProvider"
import UserProvider from "./UserProvider"
import { APIProvider as GoogleMapsProvider } from "@vis.gl/react-google-maps"

import { selectIsMobile } from "../redux/app/selectors"
import { useAppSelector } from "../redux/reducers"

import { ToastContainer } from "../components/Toast"

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY ?? ""

export function Providers({ children }: PropsWithChildren<unknown>) {
  const isMobile = useAppSelector(selectIsMobile)

  return (
    <>
      <AppParamsProvider>
        <LocalizationProvider>
          <AuthProvider>
            <UserProvider>
              <InitializationProvider>
                {isMobile ? (
                  <>
                    <MobileCompanyProvider />
                    <MobileLoadingProvider>{children}</MobileLoadingProvider>
                  </>
                ) : (
                  <>
                    <GoogleMapsProvider
                      libraries={["places", "maps"]}
                      apiKey={GOOGLE_API_KEY}
                    >
                      <CompanyProvider />
                      <AppUpdateProvider>
                        <LoadingProvider>
                          <OnboardingGuard>
                            <CheckInProvider>{children}</CheckInProvider>
                          </OnboardingGuard>
                        </LoadingProvider>
                      </AppUpdateProvider>
                    </GoogleMapsProvider>
                  </>
                )}
              </InitializationProvider>
            </UserProvider>
          </AuthProvider>
        </LocalizationProvider>
      </AppParamsProvider>
      <ToastContainer />
    </>
  )
}
