import { selectBuildingsWithVM } from "../../redux/buildings/selectors"
import { useAppSelector } from "../../redux/reducers"

import AuxNav, { AuxButton, MobileAppLinks } from "../../components/AuxNav"

import { ReactComponent as AssetSVG } from "../../assets/images/icons/Asset.svg"
import { ReactComponent as BadgeSVG } from "../../assets/images/icons/BadgeOutline.svg"
import { ReactComponent as ChairSVG } from "../../assets/images/icons/Chair.svg"
import { ReactComponent as FloorPlanSVG } from "../../assets/images/icons/Maps.svg"
import { ReactComponent as PeopleSVG } from "../../assets/images/icons/People.svg"

import "./ManageNav.sass"

export const ManageNav = () => {
  const { entries: buildignsWithVM } = useAppSelector(selectBuildingsWithVM)

  const buildingHasVM = buildignsWithVM && buildignsWithVM.length > 0

  return (
    <AuxNav className="ManageNav">
      <AuxButton to="/manage/people">
        <PeopleSVG />
        <span>People</span>
      </AuxButton>
      {buildingHasVM && (
        <AuxButton to={"/manage/visitors/visits"} activePath="/manage/visitors">
          <BadgeSVG />
          <span>Visitors</span>
        </AuxButton>
      )}
      <AuxButton to="/manage/desks">
        <ChairSVG />
        <span>Desks</span>
      </AuxButton>
      <AuxButton to="/manage/assets">
        <AssetSVG />
        <span>Parking & Assets</span>
      </AuxButton>
      <AuxButton to="/manage/floor-plan">
        <FloorPlanSVG />
        <span>Floor plan</span>
      </AuxButton>
      <MobileAppLinks />
    </AuxNav>
  )
}
