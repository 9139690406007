import React from "react"

import { useTranslation } from "react-i18next"

import { forceMode, getLabel } from "../../../utils"
import LogoVariant from "../../LogoVariant"

import { logout } from "../../../redux/auth/authSlice"
import { useActions } from "../../../redux/utils"

import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./About.sass"

const About: React.FC = () => {
  const { t } = useTranslation()

  const actions = useActions({ logout: () => logout() })

  const handleForceTablet = async () => {
    forceMode("tablet")

    const res = await actions.logout()

    if (logout.fulfilled.match(res)) {
      window.location.href = window.location.origin
    }
  }

  return (
    <SafeViewArea className="About">
      <TopNav />
      <div className="body">
        <div className="logo-box">
          <LogoVariant variant="mobile" />
        </div>
        <div className="version">
          {t("mobile.profile.version")} {process.env.REACT_APP_VERSION}
        </div>
        <div className="description">
          <p>{t("mobile.profile.description1")}</p>
          <p>{t("mobile.profile.description2")}</p>
          <p>
            {t("mobile.profile.description3")}
            <a href={`mailto:${getLabel("links.supportEmail")}`}>
              {getLabel("links.supportEmail")}
            </a>
            {t("mobile.profile.description4")}
          </p>
        </div>
        <div className="app-switch">
          {t("mobile.auth.visitor_app")}
          <div className="switch" onClick={handleForceTablet}>
            {t("mobile.auth.click_here")}
          </div>
        </div>
      </div>
    </SafeViewArea>
  )
}

export default About
