import React, { useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { useNavigateToPlanCreation } from "../../../hooks/useNavigateToPlanCreation"
import { useToast } from "../../../hooks/useToast"
import { Children } from "../../../types/sharedTypes"
import DemoDataModal from "../Desks/General/DemoDataModal"
import SolutionActivationModal from "./SolutionActivationModal"
import {
  getSolutionNameTranslationKey,
  hasTrialAvailable,
  solutionToPlanGroup,
} from "./utils"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  ACTIVE_LICENSE_STATUSES,
  SUBSCRIPTION_STATUSES,
} from "../../../redux/api/billing/constants"
import {
  useFetchSubscriptionsQuery,
  useLazyFetchSubscriptionsQuery,
} from "../../../redux/api/billing/subscriptions"
import { useCreateDemoDataMutation } from "../../../redux/api/demo"
import { useFetchFloorsQuery } from "../../../redux/api/floors"
import {
  useActivateSolutionMutation,
  useDeactivateSolutionMutation,
  useFetchSolutionsQuery,
} from "../../../redux/api/solutions"
import { SolutionTypes } from "../../../redux/api/solutions/types"
import { isRejected } from "../../../redux/api/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUserGroups } from "../../../redux/user/selectors"
import { isNormalUser } from "../../../redux/user/utils"

import Accordion from "../../../components/basic/Accordion"
import Card from "../../../components/basic/Card"
import Switch from "../../../components/basic/Switch"

import "./SolutionOverviewCard.sass"

type SolutionOverviewCardProps = {
  icon?: Children
  description?: Children
  disabled?: boolean
  className?: string
  children?: Children
  solution?: SolutionTypes
  isDefaultExtended?: boolean
}

const SolutionOverviewCard = ({
  icon,
  description,
  disabled,
  className,
  children,
  solution,
  isDefaultExtended = false,
}: SolutionOverviewCardProps) => {
  const { t } = useTranslation()
  const { openModal, closeModal, closeAllModals } = useModals()
  const { infoToast, errorToast } = useToast()

  const { data: solutions } = useFetchSolutionsQuery()
  const [activateSolution] = useActivateSolutionMutation()
  const [deactivateSolution] = useDeactivateSolutionMutation()

  const [fetchSubscriptions] = useLazyFetchSubscriptionsQuery()
  const [createDemoData] = useCreateDemoDataMutation()
  const { data: { count: floorCount } = {} } = useFetchFloorsQuery({
    limit: 1,
    building: null,
  })
  const { data: { subsByPlanGroup } = {} } = useFetchSubscriptionsQuery()

  const navigateToPlanCreation = useNavigateToPlanCreation()

  const groups = useAppSelector(selectUserGroups)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const solutionName = t(getSolutionNameTranslationKey(solution))
  const cn = classNames("SolutionOverviewCard", className)

  const handleSolutionActivation = async () => {
    if (!solution) {
      return
    }

    const response = await activateSolution(solution)

    if (isRejected(response)) {
      errorToast(response.error.message)

      return
    }

    await fetchSubscriptions()

    infoToast(
      t("desktop.settings.overview.toasts.activate_success", { solutionName }),
    )

    if (solution === "desk" && floorCount === 0) {
      openModal(DemoDataModal, {
        onConfirm: onConfirmDemoData,
        onCancel: closeAllModals,
      })

      return
    }

    closeModal()
  }

  const handleSolutionDeactivation = async () => {
    if (!solution) {
      return
    }
    const response = await deactivateSolution(solution)

    if (isRejected(response)) {
      errorToast(response.error.message)

      return
    }

    await fetchSubscriptions()

    infoToast(
      t("desktop.settings.overview.toasts.deactivate_success", {
        solutionName,
      }),
    )
    closeModal()
  }

  const onConfirmDemoData = async () => {
    const demoDataResponse = await createDemoData()

    if (isRejected(demoDataResponse)) {
      errorToast(demoDataResponse.error.message)

      return
    }

    infoToast(
      t("desktop.settings.desks.general.toasts.create_demo_data_success"),
    )
    closeAllModals()
  }

  const handleOnSolutionEnableChange = (isActivation: boolean) => {
    const hasTrial = hasTrialAvailable(solution, solutions)

    if (!solutions || !solutionName || !solution) {
      return
    }

    const planGroup = solutionToPlanGroup(solution)
    const hasActiveSubscription = !planGroup
      ? false
      : subsByPlanGroup?.[planGroup]?.some((s) =>
          ACTIVE_LICENSE_STATUSES.includes(s.status),
        ) ?? false

    openModal(SolutionActivationModal, {
      isActivation,
      hasTrialAvailable: hasTrial,
      isTrial: solutions?.[solution]?.status === SUBSCRIPTION_STATUSES.IN_TRIAL,
      hasActiveSubscription: hasActiveSubscription,
      solution,
      onConfirm: async () => {
        setIsSubmitting(true)

        if (isActivation) {
          if (hasTrial || hasActiveSubscription) {
            await handleSolutionActivation()
            setIsSubmitting(false)

            return
          }

          setIsSubmitting(false)
          closeModal()
          navigateToPlanCreation(solutionToPlanGroup(solution))

          return
        }

        handleSolutionDeactivation()
        setIsSubmitting(false)
      },
      onCancel: closeModal,
    })
  }

  const canEdit = !isNormalUser({ groups })
  const isDisabled = !canEdit || disabled
  const isDefaultOpen = isDefaultExtended
    ? isDefaultExtended
    : solution
      ? !!solutions?.[solution]?.active
      : true

  const header = solution && (
    <Switch
      className={"SolutionOverviewCard__switch"}
      value={!!solutions?.[solution]?.active}
      onChange={handleOnSolutionEnableChange}
      label={solutionName}
      disabled={isDisabled || isSubmitting}
    />
  )

  return (
    <Card className={cn} header={header}>
      <div className="SolutionOverviewCard__description">
        <div>{description}</div>
        {icon}
      </div>

      {children && (
        <Accordion
          key={isDefaultOpen ? "open" : "closed"}
          header={t("desktop.settings.overview.hide_onboarding_steps")}
          headerClosed={t("desktop.settings.overview.show_onboarding_steps")}
          defaultOpen={isDefaultOpen}
        >
          <div className="SolutionOverviewCard__steps">{children}</div>
        </Accordion>
      )}
    </Card>
  )
}

export default SolutionOverviewCard
