import { useCallback, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import BuildingFilter from "../../../../components/Filter/BuildingFilter"
import DeviceBatteryFilter from "../../../../components/Filter/DeviceBatteryFilter"
import DeviceTypeFilter from "../../../../components/Filter/DeviceTypesFilter"
import DeviceWiFiFilter from "../../../../components/Filter/DeviceWiFiFilter"
import Filters from "../../../../components/Filter/Filters"
import FloorFilter from "../../../../components/Filter/FloorFilter"
import SearchFilter from "../../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../../components/Filter/types"

export type Filter = {
  building_id: string
  floor_id: string
  type: string
  battery: string
  wifi: string
  search: string
}

type FilterKeys = keyof Filter

type Props = {
  onChange: (filter: Filter) => Promise<void>
  defaultValues: Filter
}

const DevicesFilters = ({ onChange, defaultValues }: Props) => {
  const { t } = useTranslation()

  const floorRef = useRef<string>(defaultValues.floor_id)
  const filtersRef = useRef<Filter>(defaultValues)

  const [filters, setFilters] = useState<Filter>(defaultValues)

  const handleOnChange = useCallback(
    (filterName: FilterKeys) => (value: string | string[]) => {
      // the FloorFilter triggers unwanted onChanges this is temporary fix
      if (filterName === "floor_id" && floorRef.current === value) {
        return
      }

      filtersRef.current = {
        ...filtersRef.current,
        [filterName]: value,
      }
      onChange(filtersRef.current as Filter)
      floorRef.current = filtersRef.current.floor_id
      setFilters(filtersRef.current)
    },
    [onChange],
  )

  return (
    <Filters>
      <div className="f-container">
        <BuildingFilter
          onChange={(value) => {
            handleOnChange("building_id")(value)
            handleOnChange("floor_id")(FilterSpecialValues.ALL)
          }}
          value={filters.building_id}
          showAll
        />

        <FloorFilter
          value={filters.floor_id}
          onChange={handleOnChange("floor_id")}
          buildingId={filters.building_id}
          showAll
        />

        <DeviceTypeFilter
          onChange={handleOnChange("type")}
          value={filters.type}
        />

        <DeviceBatteryFilter
          onChange={handleOnChange("battery")}
          value={filters.battery}
        />

        <DeviceWiFiFilter
          onChange={handleOnChange("wifi")}
          value={filters.wifi}
        />

        <SearchFilter
          placeholder={t("desktop.settings.visitors.devices.search_devices")}
          onChange={handleOnChange("search")}
          value={filters.search}
        />
      </div>
    </Filters>
  )
}

export default DevicesFilters
