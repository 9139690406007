import React, { MouseEvent, useCallback } from "react"

import cn from "classnames"
import { Block, Data, Value } from "slate"
import { Editor } from "slate-react"

import {
  BACKGROUND_THEMES,
  GRAY_100,
  HEADING_ONE,
  HEADING_THREE,
  HEADING_TWO,
  HORIZONTAL_ALIGNMENT_CENTER,
  HORIZONTAL_ALIGNMENT_LEFT,
  HORIZONTAL_ALIGNMENT_RIGHT,
  PARAGRAPH,
  TYPOGRAPHY_OPTIONS,
  VERTICAL_ALIGNMENT_BOTTOM,
  VERTICAL_ALIGNMENT_CENTER,
  VERTICAL_ALIGNMENT_TOP,
} from "./constants"
import { renderMark, renderNode } from "./utils"

import Dropdown from "../../../../../../components/basic/Dropdown"

import { ReactComponent as FormatBoldSVG } from "../../../../../../assets/images/icons/FormatBold.svg"
import { ReactComponent as FormatColorTextSVG } from "../../../../../../assets/images/icons/FormatColorText.svg"
import { ReactComponent as FormatHAlignCenterSVG } from "../../../../../../assets/images/icons/FormatHAlignCenter.svg"
import { ReactComponent as FormatHAlignLeftSVG } from "../../../../../../assets/images/icons/FormatHAlignLeft.svg"
import { ReactComponent as FormatHAlignRightSVG } from "../../../../../../assets/images/icons/FormatHAlignRight.svg"
import { ReactComponent as FormatItalicSVG } from "../../../../../../assets/images/icons/FormatItalic.svg"
import { ReactComponent as FormatListBulletedSVG } from "../../../../../../assets/images/icons/FormatListBulleted.svg"
import { ReactComponent as FormatListNumberedSVG } from "../../../../../../assets/images/icons/FormatListNumbered.svg"
import { ReactComponent as FormatUnderlinedSVG } from "../../../../../../assets/images/icons/FormatUnderlined.svg"
import { ReactComponent as FormatVAlignBottomSVG } from "../../../../../../assets/images/icons/FormatVAlignBottom.svg"
import { ReactComponent as FormatVAlignCenterSVG } from "../../../../../../assets/images/icons/FormatVAlignCenter.svg"
import { ReactComponent as FormatVAlignTopSVG } from "../../../../../../assets/images/icons/FormatVAlignTop.svg"

import "./TextEditor.sass"

type Props = {
  value: Value
  setValue?: (value: Value) => void
  readOnly?: boolean
}

const TextEditor = ({ value, setValue, readOnly }: Props) => {
  const getRootTheme = useCallback(
    () => value.getIn(["document", "nodes"]).first().getIn(["data", "theme"]),
    [value],
  )

  const hasMark = useCallback(
    (type: string) => value.activeMarks.some((mark) => mark?.type === type),
    [value],
  )

  const hasBlock = useCallback(
    (type: string) => {
      const { document } = value

      if (type === "numbered-list" || type === "bulleted-list") {
        return value.blocks.some(
          (node: any) =>
            !!document.getClosest(
              node.key,
              (parent: any) => parent.type === type,
            ),
        )
      }

      return value.blocks.some((node) => node?.type === type)
    },
    [value],
  )

  const hasData = useCallback(
    (dataKey: string, dataValue: any) => {
      const isValueObject = typeof dataValue === "object"

      return value.blocks.some((node) => {
        const valueToCheck = node?.data.get(dataKey)

        if (isValueObject) {
          return JSON.stringify(valueToCheck) === JSON.stringify(dataValue)
        }

        return valueToCheck === dataValue
      })
    },
    [value],
  )

  const getSelectedTypography = useCallback(() => {
    if (hasBlock(HEADING_ONE)) return HEADING_ONE

    if (hasBlock(HEADING_TWO)) return HEADING_TWO

    if (hasBlock(HEADING_THREE)) return HEADING_THREE

    return PARAGRAPH
  }, [hasBlock])

  const isBackgroundThemeActive = useCallback(
    (type: string, defaultTheme: string) => {
      const isBackgroundTheme =
        value.getIn(["document", "nodes"]).first().get("type") === type
      const isDefaultTheme = getRootTheme() === defaultTheme

      return isBackgroundTheme && !isDefaultTheme
    },
    [value, getRootTheme],
  )

  const onCopy = useCallback(() => {
    return true
  }, [])

  const onChange = useCallback(
    ({ value }: { value: Value }) => {
      setValue && setValue(value)
    },
    [setValue],
  )

  const handleClick = (event: MouseEvent, callback: () => void) => {
    event.preventDefault()

    callback()
  }

  const onClickMark = useCallback(
    (event: React.MouseEvent, type: string) =>
      handleClick(event, () => onChange(value.change().toggleMark(type))),
    [value, onChange],
  )

  const onClickBlock = useCallback(
    (_event: React.MouseEvent, type: string) => {
      const { document, selection } = value

      const change = value.change()

      if (!selection || !selection?.anchorKey) {
        return
      }

      if (type !== "bulleted-list" && type !== "numbered-list") {
        const isActive = hasBlock(type)
        const isList = hasBlock("list-item")

        if (isList) {
          change
            .setBlocks(isActive ? PARAGRAPH : type)
            .unwrapBlock("bulleted-list")
            .unwrapBlock("numbered-list")
        } else {
          change.setBlocks(isActive ? PARAGRAPH : type)
        }
      } else {
        const isList = hasBlock("list-item")

        const isType = value.blocks.some((block: any) => {
          return !!document.getClosest(
            block.key,
            (parent: any) => parent.type === type,
          )
        })

        if (isList && isType) {
          change
            .setBlocks(PARAGRAPH)
            .unwrapBlock("bulleted-list")
            .unwrapBlock("numbered-list")
        } else if (isList) {
          change
            .unwrapBlock(
              type === "bulleted-list" ? "numbered-list" : "bulleted-list",
            )
            .wrapBlock(type)
        } else {
          change.setBlocks("list-item").wrapBlock(type)
        }
      }

      onChange({ value: change.value })
    },
    [value, hasBlock, onChange],
  )

  const onClickTheme = useCallback(
    (event: MouseEvent, theme: string) => {
      event.preventDefault()

      const { document } = value
      const { nodes } = document
      const change = value.change()

      const prevThemeName = getRootTheme()
      const nextThemeName = theme

      const prevThemeData = nodes.first().get("data").toJS()
      const nextThemeData = { ...prevThemeData, theme: nextThemeName }
      const defaultThemeData = { ...prevThemeData, theme: GRAY_100 }

      const nextThemeBlock = Block.create({
        type: "background-theme",
        data: nextThemeData,
      })

      const prevThemeBlock = Block.create({
        type: "background-theme",
        data: prevThemeData,
      })

      const defaultThemeBlock = Block.create({
        type: "background-theme",
        data: defaultThemeData,
      })

      change.selectAll().unwrapBlock(prevThemeBlock)

      prevThemeName === nextThemeName
        ? change.wrapBlock(defaultThemeBlock)
        : change.wrapBlock(nextThemeBlock)

      change.deselect()

      onChange({ value: change.value })
    },
    [value, getRootTheme, onChange],
  )

  const onClickVerticalAlign = useCallback(
    (event: MouseEvent, alignment: string) => {
      handleClick(event, () => {
        const { document } = value
        const { nodes } = document
        const change = value.change()

        const prevThemeData = nodes.first().get("data").toJS()
        const prevThemeAlignment = nodes
          .first()
          .getIn(["data", "verticalAlignment"])

        const nextThemeData = Object.assign({}, prevThemeData, {
          verticalAlignment: alignment,
        })

        const defaultThemeData = Object.assign({}, prevThemeData, {
          verticalAlignment: VERTICAL_ALIGNMENT_TOP,
        })

        const nextThemeBlock = Block.create({
          type: "background-theme",
          data: nextThemeData,
        })

        const prevThemeBlock = Block.create({
          type: "background-theme",
          data: prevThemeData,
        })

        const defaultThemeBlock = Block.create({
          type: "background-theme",
          data: defaultThemeData,
        })

        change.selectAll().unwrapBlock(prevThemeBlock)

        prevThemeAlignment === alignment
          ? change.wrapBlock(defaultThemeBlock)
          : change.wrapBlock(nextThemeBlock)

        change.deselect()

        onChange(change)
      })
    },
    [onChange, value],
  )

  const onClickAttribute = (
    event: MouseEvent,
    attributeKey: string,
    attributeValue: Record<string, string>,
  ) =>
    handleClick(event, () => {
      const { document, selection } = value

      if (!selection || !selection?.anchorKey) {
        return
      }

      const anchorKey = selection.anchorKey

      const currentBlock = document.getClosestBlock(anchorKey ?? "")

      if (!currentBlock) {
        return
      }

      const blockType = currentBlock.type

      const attributeData = Data.create({ attributeKey, attributeValue })

      const change = value.change()

      change.setBlocks({
        type: blockType,
        data: attributeData,
      })

      onChange(change)
    })

  const renderElement = (
    isActive: boolean,
    onMouseDown: (event: MouseEvent) => void,
    icon: JSX.Element | null,
    label?: string,
    isButton: boolean = true,
  ) =>
    isButton ? (
      <button
        type="button"
        className={cn({ active: isActive })}
        onMouseDown={onMouseDown}
      >
        {icon && <span>{icon}</span>}
        {label && <span>{label}</span>}
      </button>
    ) : (
      <span className={cn({ active: isActive })} onMouseDown={onMouseDown}>
        {icon && <span>{icon}</span>}
        {label && <span>{label}</span>}
      </span>
    )

  const renderMarkButton = (type: string, icon: JSX.Element) =>
    renderElement(hasMark(type), (event) => onClickMark(event, type), icon)

  const renderBlockButton = (
    type: string,
    icon: JSX.Element | null,
    label?: string,
    isButton: boolean = true,
  ) =>
    renderElement(
      hasBlock(type),
      (event) => onClickBlock(event, type),
      icon,
      label,
      isButton,
    )

  const renderThemeButton = (themeName: string) =>
    renderElement(
      isBackgroundThemeActive("background-theme", themeName),
      (event) => onClickTheme(event, themeName),
      <FormatColorTextSVG />,
    )

  const renderVerticalAlignButton = (alignment: string, icon: JSX.Element) => {
    const activeAlignment = value
      .getIn(["document", "nodes"])
      .first()
      .getIn(["data", "verticalAlignment"])

    const isActive = alignment === activeAlignment

    return renderElement(
      isActive,
      (event) => onClickVerticalAlign(event, alignment),
      icon,
    )
  }

  const renderAttributeButton = (
    attributeKey: string,
    attributeValue: Record<string, string>,
    icon: JSX.Element,
  ) => {
    const isActive =
      hasData("attributeKey", attributeKey) &&
      hasData("attributeValue", attributeValue)

    return renderElement(
      isActive,
      (event) => onClickAttribute(event, attributeKey, attributeValue),
      icon,
    )
  }

  const BACKGROUND_THEME_OPTIONS = BACKGROUND_THEMES.map((value) => ({
    value,
    label: renderThemeButton(value),
    className: value,
  }))

  const MODIFIED_TYPOGRAPHY_OPTIONS = TYPOGRAPHY_OPTIONS.map(
    ({ value, label }) => ({
      value,
      label: renderBlockButton(value, null, label, false),
    }),
  )

  return (
    <div className="TextEditorContainer">
      {!readOnly && (
        <div className="TextEditorToolbar">
          <div className="TextEditorActionsGroup">
            <Dropdown
              options={MODIFIED_TYPOGRAPHY_OPTIONS}
              value={getSelectedTypography()}
              onChange={() => {}}
            />
          </div>

          <div className="TextEditorActionsGroup">
            {renderAttributeButton(
              "style",
              { textAlign: HORIZONTAL_ALIGNMENT_LEFT },
              <FormatHAlignLeftSVG />,
            )}
            {renderAttributeButton(
              "style",
              { textAlign: HORIZONTAL_ALIGNMENT_CENTER },
              <FormatHAlignCenterSVG />,
            )}
            {renderAttributeButton(
              "style",
              { textAlign: HORIZONTAL_ALIGNMENT_RIGHT },
              <FormatHAlignRightSVG />,
            )}
          </div>

          <div className="TextEditorActionsGroup">
            {renderMarkButton("bold", <FormatBoldSVG />)}
            {renderMarkButton("italic", <FormatItalicSVG />)}
            {renderMarkButton("underline", <FormatUnderlinedSVG />)}
          </div>

          <div className="TextEditorActionsGroup">
            {renderBlockButton("numbered-list", <FormatListNumberedSVG />)}
            {renderBlockButton("bulleted-list", <FormatListBulletedSVG />)}
          </div>

          <div className="TextEditorActionsGroup">
            {renderVerticalAlignButton(
              VERTICAL_ALIGNMENT_TOP,
              <FormatVAlignTopSVG />,
            )}
            {renderVerticalAlignButton(
              VERTICAL_ALIGNMENT_CENTER,
              <FormatVAlignCenterSVG />,
            )}
            {renderVerticalAlignButton(
              VERTICAL_ALIGNMENT_BOTTOM,
              <FormatVAlignBottomSVG />,
            )}
          </div>

          <div className="TextEditorActionsGroup">
            <Dropdown
              options={BACKGROUND_THEME_OPTIONS}
              className="bg-theme"
              value={getRootTheme()}
              onChange={() => {}}
            />
          </div>
        </div>
      )}
      {/*// TODO: Need to deal with type incompatibility */}
      <Editor
        // @ts-ignore
        value={value}
        onChange={onChange}
        onCopy={onCopy}
        renderNode={renderNode}
        renderMark={renderMark}
        placeholder="Enter some text..."
        className="TextEditor"
        spellCheck
        autoFocus
        readOnly={readOnly}
      />
    </div>
  )
}

export default TextEditor
