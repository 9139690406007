import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./Field.sass"

type Props = {
  className?: any
  order?: number | null
}

/**
 * for new forms use the Field from /src/components/Filed/index.tsx
 * @see /src/components/Filed/index.tsx
 *
 * this Field can be used only outside the new forms
 *
 */
export const Field = ({
  children,
  className,
  order,
  ...rest
}: PropsWithChildren<Props>) => {
  const style: React.CSSProperties = {}
  if (order != null) {
    style.order = order
  }

  return (
    <div
      className={classNames({ Field: true, [className]: className })}
      style={style}
      {...rest}
    >
      {children}
    </div>
  )
}
