import React, { useCallback, useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"

import { HOME_PATHS } from "../../constants"
import { useNavigation } from "../../hooks/useNavigation"
import {
  BarcodeScanner,
  SupportedFormat,
} from "@joan/capacitor-barcode-scanner"

import Button from "../../components/advanced/Button"
import SafeViewArea from "../../components/Mobile/SafeViewArea"

import "./QRScanner.sass"

const QRScanner = () => {
  const isBarcodeScannerStarted = useRef<boolean>(false)
  const { t } = useTranslation()
  const { push } = useNavigation()

  const startScanning = useCallback(() => {
    if (document !== null) {
      document.querySelector("body")!.classList.add("scanner-active")
      document.querySelector(".QRScanner")!.classList.add("scanner-active")
    }
    BarcodeScanner.hideBackground()
  }, [])

  const finishScanning = useCallback(() => {
    if (document !== null) {
      document.querySelector("body")!.classList.remove("scanner-active")
      document.querySelector(".QRScanner")!.classList.remove("scanner-active")
    }
    BarcodeScanner.showBackground()
  }, [])

  const handleGoBack = useCallback(() => {
    BarcodeScanner.stopScan()

    finishScanning()
    push(HOME_PATHS.mobile)
  }, [finishScanning, push])

  useEffect(() => {
    if (!isBarcodeScannerStarted.current) {
      isBarcodeScannerStarted.current = true

      startScanning()

      BarcodeScanner.startScan({
        targetedFormats: [SupportedFormat.QR_CODE],
        cameraDirection: "back",
      }).then((result) => {
        if (!result.hasContent) {
          return
        }

        BarcodeScanner.stopScan()
        finishScanning()

        // Check if the QR code is a desk check-in QR code
        const checkinUrlRegex =
          /(\/manage\/desks\/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}\/checkin)/

        if (checkinUrlRegex.test(result.content)) {
          push(checkinUrlRegex.exec(result.content)![1])
        }
      })
    }
  }, [handleGoBack, startScanning, finishScanning, push])

  return (
    <SafeViewArea className="QRScanner">
      <div className="scanWindow"></div>
      <div className="scanInfo">
        {t("tablet.visitors.screens.scan.qr_code_focused")}
      </div>

      <Button variant="link" onClick={handleGoBack}>
        {t("general.redirection.go_back")}
      </Button>
    </SafeViewArea>
  )
}

export default QRScanner
