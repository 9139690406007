import { OVERVIEW_PATH } from "../Settings/Overview/constants"
import { PROFILE_PATHS } from "../Settings/UserProfile/constants"
import { FLOOR_PLAN_SCHEDULE_PATHNAME } from "./FloorPlan/FloorPlanProvider"

export const ENTRIES_PER_PAGE = 20

export const ASSETS_SCHEDULE_PATHNAME = "/manage/assets"
export const DESKS_SCHEDULE_PATHNAME = "/manage/desks"
export const PEOPLE_SCHEDULE_PATHNAME = "/manage/people"
export const VISITORS_INVITES_PATHNAME = "/manage/visitors/invites"
export const VISITORS_VISITS_PATHNAME = "/manage/visitors/visits"

export const ASSETS_FILTER_STORE_NAME = "manage-assets-filters"
export const DESK_FILTERS_STORE_NAME = "manage-desk-filters"
export const INVITES_FILTERS_STORE_NAME = "manage-invites-filters"
export const PEOPLE_FILTERS_STORE_NAME = "manage-people-filters"
export const VISITORS_FILTERS_STORE_NAME = "manage-visitors-filters"

export const determineRole = (strength: number) => {
  switch (strength) {
    case 0:
      return "ADMIN"
    case 1:
      return "OFFICE_MANAGER"
    case 2:
    default:
      return "USER"
  }
}

export const getInitialRoute = (
  index: number,
  isRoomActive: boolean,
  isDeskActive: boolean,
  isVisitorActive: boolean,
): string => {
  const role = determineRole(index)

  const activeServices = [isRoomActive, isDeskActive, isVisitorActive]
  const serviceNames = ["ROOM", "DESK", "VISITOR"]
  const activeServicesNames = activeServices
    .map((isActive, i) => isActive && serviceNames[i])
    .filter(Boolean)
    .join("-")

  return (
    INITIAL_REROUTE_PATHS[activeServicesNames]?.[role] ||
    (isDeskActive ? PEOPLE_SCHEDULE_PATHNAME : VISITORS_VISITS_PATHNAME)
  )
}

export const INITIAL_REROUTE_PATHS: {
  [key: string]: {
    ADMIN: string
    OFFICE_MANAGER: string
    USER: string
  }
} = {
  ROOM: {
    ADMIN: OVERVIEW_PATH,
    OFFICE_MANAGER: PROFILE_PATHS.root,
    USER: PROFILE_PATHS.root,
  },
  DESK: {
    ADMIN: PEOPLE_SCHEDULE_PATHNAME,
    OFFICE_MANAGER: PEOPLE_SCHEDULE_PATHNAME,
    USER: FLOOR_PLAN_SCHEDULE_PATHNAME,
  },
  VISITOR: {
    ADMIN: VISITORS_VISITS_PATHNAME,
    OFFICE_MANAGER: VISITORS_VISITS_PATHNAME,
    USER: VISITORS_VISITS_PATHNAME,
  },
  "ROOM-DESK": {
    ADMIN: PEOPLE_SCHEDULE_PATHNAME,
    OFFICE_MANAGER: PEOPLE_SCHEDULE_PATHNAME,
    USER: FLOOR_PLAN_SCHEDULE_PATHNAME,
  },
  "ROOM-VISITOR": {
    ADMIN: VISITORS_VISITS_PATHNAME,
    OFFICE_MANAGER: VISITORS_VISITS_PATHNAME,
    USER: VISITORS_VISITS_PATHNAME,
  },
  "ROOM-DESK-VISITOR": {
    ADMIN: PEOPLE_SCHEDULE_PATHNAME,
    OFFICE_MANAGER: PEOPLE_SCHEDULE_PATHNAME,
    USER: FLOOR_PLAN_SCHEDULE_PATHNAME,
  },
} as const
