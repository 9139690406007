import React, { useCallback, useEffect, useMemo, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"

import { useMediaQuery } from "../../../hooks/useMediaQuery"
import { internalTimeFormat, userTimeFormat } from "../../../utils"
import debounce from "lodash.debounce"
import "swiper/css"
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react"
import { SwiperModule } from "swiper/types/shared"

import "./style.sass"

type Props = {
  times: string[]
  onPick: (index: number) => void
  slidesPerView?: number | "auto"
  minutesIntervalLabelView?: number
  grabCursor?: boolean
  centeredSlides?: boolean
  freeMode?: boolean
  simulateTouch?: boolean
  virtual?: boolean
  modules?: SwiperModule[]
  initialTimeIndex?: number
}

const slidesPerViewOptions = {
  xs: 18,
  sm: 28,
  md: 36,
  lg: 48,
}

export const SliderTimePicker = ({
  times,
  onPick,
  slidesPerView,
  minutesIntervalLabelView,
  grabCursor,
  freeMode,
  centeredSlides,
  simulateTouch,
  virtual,
  modules,
  initialTimeIndex,
}: Props) => {
  const [swiper, setSwiper] = useState<SwiperClass>()

  const [defaultSlidesPerView, setDefaultSlidesPerView] = useState<
    number | "auto"
  >(slidesPerView || 20)

  const initialSlide = useMemo(
    () =>
      times.findIndex(
        (t) => t === dayjs().minute(0).format(internalTimeFormat()),
      ),
    [times],
  )

  const isXsScreen = useMediaQuery("(min-width: 320px) and (max-width: 480px)")
  const isSmScreen = useMediaQuery("(min-width: 478px) and (max-width: 680px)")
  const isMdScreen = useMediaQuery("(min-width: 680px) and (max-width: 1200px)")
  const isLgScreen = useMediaQuery("(min-width: 1200px)")

  const handleSlideClick = (idx: number) => swiper?.slideTo(idx) && onPick(idx)

  const handleSlideChange = (idx: number) => onPick(idx)

  const debouncedSliderChange = debounce(handleSlideChange, 700)

  const calculateSlidesPerView = useCallback(() => {
    switch (true) {
      case isXsScreen:
        setDefaultSlidesPerView(slidesPerViewOptions.xs)
        break
      case isSmScreen:
        setDefaultSlidesPerView(slidesPerViewOptions.sm)
        break
      case isMdScreen:
        setDefaultSlidesPerView(slidesPerViewOptions.md)
        break
      case isLgScreen:
        setDefaultSlidesPerView(slidesPerViewOptions.md)
        break
      default:
      // Do nothing
    }
  }, [isLgScreen, isMdScreen, isSmScreen, isXsScreen])

  useEffect(() => {
    if (virtual && defaultSlidesPerView !== "auto") {
      calculateSlidesPerView()
    }
  }, [virtual, defaultSlidesPerView, calculateSlidesPerView])

  return (
    <div className="SliderTimePickerWrapper">
      <Swiper
        initialSlide={initialTimeIndex ?? initialSlide}
        onSwiper={(swiper) => setSwiper(swiper)}
        onSlideChange={(e) => debouncedSliderChange(e.activeIndex)}
        slidesPerView={defaultSlidesPerView}
        grabCursor={grabCursor}
        freeMode={freeMode}
        centeredSlides={centeredSlides}
        simulateTouch={simulateTouch}
        virtual={virtual}
        modules={modules}
        className="SliderTimePicker"
      >
        {times.map((time, idx) => {
          const [, minutes] = time.split(":")

          return (
            <SwiperSlide
              key={idx}
              virtualIndex={idx}
              className="SliderTimePickerOption"
            >
              {({ isActive }) => (
                <div
                  onClick={() => handleSlideClick(idx)}
                  className={classNames({
                    SliderTimePickerSlot: true,
                    SliderTimePickerSlotSimple:
                      minutesIntervalLabelView &&
                      parseInt(minutes) % minutesIntervalLabelView !== 0,
                    active: isActive,
                  })}
                >
                  <div className="SliderTimePickerSlotName">
                    {dayjs(time, internalTimeFormat()).format(userTimeFormat())}
                  </div>
                  <div className="SliderTimePickerSlotMarker">|</div>
                </div>
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}
