import { MouseEvent, useCallback, useRef } from "react"

import saveAs from "file-saver"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { amenitiesURL, departmentsURL, usersURL } from "../../api"
import { FEATURE_FLAGS } from "../../constants"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import { useToast } from "../../hooks/useToast"
import { equalArraysById } from "../../utils"
import AsyncSelect from "../advanced/AsyncSelect"
import Button from "../advanced/Button"
import { Input } from "../basic/Input"
import Switch from "../basic/Switch"
import Field from "../Field"
import { setErrors } from "./formUtils"
import ModalForm from "./ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import { AmenityResponse } from "../../redux/amenities/types"
import { DepartmentResponse } from "../../redux/api/departments/types"
import {
  useCreateDeskMutation,
  useDestroyDeskMutation,
  useFetchDesksQuery,
  useUpdateDeskMutation,
} from "../../redux/api/desks"
import { DeskRequestWithId, DeskResponse } from "../../redux/api/desks/types"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { setRepositionDesk } from "../../redux/app/appSlice"
import { FloorResponse } from "../../redux/floors/types"
import { qrCodeFetch } from "../../redux/qrCodeThunk"
import { formatUser } from "../../redux/user/utils"
import { UserResponse } from "../../redux/users/types"
import { useActions } from "../../redux/utils"

import "./DeskForm.sass"

type Props = {
  floor: FloorResponse | null
  desks: DeskResponse[]
}

type FormValues = {
  name: string
  active: boolean
  departments: DepartmentResponse[]
  users: UserResponse[]
  amenities: AmenityResponse[]
}

const DeskForm = ({ floor, desks }: Props) => {
  const { closeModal } = useModals()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()

  const [createDesk] = useCreateDeskMutation()
  const [updateDesk] = useUpdateDeskMutation()
  const [destroyDesk] = useDestroyDeskMutation()

  const actions = useActions({
    repositionDesk: (desk: DeskRequestWithId) => setRepositionDesk(desk),
    fetchQrCode: (data: string) => qrCodeFetch(data),
  })

  const { data: { results: allDesks = [] } = {} } = useFetchDesksQuery({})

  const isPortalMergerEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.PORTAL_MERGER,
  )

  const multiple = desks.length > 1

  let multipleDepartments = false
  let multipleUsers = false
  let multipleAmenities = false
  let multipleEnabled = false

  if (multiple) {
    desks.forEach((desk) => {
      if (!multipleDepartments) {
        multipleDepartments = !equalArraysById(
          desk.departments!,
          desks[0].departments!,
        )
      }
      if (!multipleUsers) {
        multipleUsers = !equalArraysById(desk.users!, desks[0].users!)
      }
      if (!multipleAmenities) {
        multipleAmenities = !equalArraysById(
          desk.amenities!,
          desks[0].amenities!,
        )
      }
      if (desk.active !== desks[0].active) {
        multipleEnabled = true
      }
    })
  }

  const desk = desks[0]
  const { id, name, users, departments, amenities, active = true } = desk ?? {}

  const methods = useForm<FormValues>({
    defaultValues: {
      name: name ?? "",
      active: multipleEnabled ? false : active,
      departments: multiple ? [] : departments ?? [],
      users: multiple ? [] : users ?? [],
      amenities: multiple ? [] : amenities ?? [],
    },
  })

  const {
    setError,
    control,
    formState: { isSubmitting },
  } = methods

  const deskEnabled = useRef<boolean | undefined>(
    multipleEnabled ? undefined : active,
  )

  const onDeleteClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()

      const requests = []
      const ids = desks.map((d) => d.id)

      for (const desk of desks) {
        requests.push(destroyDesk(desk.id))
      }

      Promise.all(requests)
        .then((responses) => {
          for (const response of responses) {
            const deskId = ids.shift()
            if (!isRejected(response)) {
              analyticsEvent(SupportedEvents.DESK_DELETE, {
                id: deskId,
                total: allDesks.length - ids.length,
              })
              infoToast(
                t("desktop.settings.desks.desk_form.desk_deleted_toast"),
              )
            } else {
              errorToast(response.error.message)
            }
          }
        })
        .finally(() => {
          closeModal()
        })
    },
    [desks, allDesks, destroyDesk, infoToast, t, errorToast, closeModal],
  )

  const onCreateClick = useCallback(
    async ({ name, departments, users, amenities, active }: FormValues) => {
      if (!floor) {
        return
      }
      const response = await createDesk({
        floor_id: floor.id,
        name,
        coord_x: desk.coord_x,
        coord_y: desk.coord_y,
        active,
        departments: departments?.map((d) => d.id) ?? [],
        users: users?.map((u) => u.email) ?? [],
        amenities: amenities?.map((a) => a.id) ?? [],
      })

      if (!isRejected(response)) {
        analyticsEvent(SupportedEvents.DESK_ADD, {
          id: response.data.id,
          name,
          num_amenities: amenities?.length ?? 0,
          total: allDesks.length + 1,
        })
        infoToast(t("desktop.settings.desks.desk_form.desk_created_toast"))
        closeModal()
      } else {
        if (isApiResponseError(response.error)) {
          setErrors(response.error.formError, setError, errorToast)
        }
      }
    },
    [
      floor,
      allDesks,
      createDesk,
      desk.coord_x,
      desk.coord_y,
      infoToast,
      t,
      closeModal,
      setError,
      errorToast,
    ],
  )

  const onUpdateClick = useCallback(
    async ({ name, active, departments, users, amenities }: FormValues) => {
      if (!floor) {
        return
      }

      let response

      if (multiple) {
        const requests = []

        for (let d of desks) {
          const r = updateDesk({
            id: d.id,
            floor_id: d.floor_id,
            name: d.name,
            coord_x: d.coord_x,
            coord_y: d.coord_y,
            active: deskEnabled.current === undefined ? d.active : active,
            departments:
              departments.length === 0
                ? d.departments?.map((d) => d.id)
                : departments.map((d) => d.id),
            users:
              users.length === 0
                ? d.users?.map((u) => u.email)
                : users.map((u) => u.email),
            amenities:
              amenities.length === 0
                ? d.amenities?.map((a) => a.id)
                : amenities.map((a) => a.id),
          })

          requests.push(r)
        }

        Promise.all(requests)
          .then((responses) => {
            for (const response of responses) {
              if (!isRejected(response)) {
                analyticsEvent(SupportedEvents.DESK_UPDATE, {
                  id: response.data.id,
                  name: response.data.name,
                  amenities: response.data.amenities?.length ?? 0,
                })
                infoToast(
                  t("desktop.settings.desks.desk_form.desk_updated_toast"),
                )
              } else {
                if (isApiResponseError(response.error)) {
                  setErrors(response.error.formError, setError, errorToast)
                }
              }
            }
          })
          .finally(() => {
            closeModal()
          })
      } else {
        response = await updateDesk({
          id: desk.id,
          floor_id: floor.id,
          name,
          coord_x: desk.coord_x,
          coord_y: desk.coord_y,
          active,
          departments: departments.map((d) => d.id),
          users: users.map((u) => u.email),
          amenities: amenities.map((a) => a.id),
        })

        if (!isRejected(response)) {
          infoToast(t("desktop.settings.desks.desk_form.desk_updated_toast"))
          closeModal()
        } else {
          if (isApiResponseError(response.error)) {
            setErrors(response.error.formError, setError, errorToast)
          }
        }
      }
    },
    [
      floor,
      multiple,
      desks,
      updateDesk,
      infoToast,
      t,
      setError,
      errorToast,
      closeModal,
      desk.id,
      desk.coord_x,
      desk.coord_y,
    ],
  )

  const onRepositionClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()

      infoToast(
        t("desktop.settings.desks.desk_form.desk_reposition_toast_click", {
          deskName: desk.name,
        }),
      )

      actions.repositionDesk({
        ...desk,
        departments: desk.departments?.map((d) => d.id),
        users: desk.users?.map((u) => u.email),
        amenities: desk.amenities?.map((a) => a.id),
      })
      closeModal()
    },
    [infoToast, t, desk, actions, closeModal],
  )

  const generateCheckInURL = (deskId: string) =>
    `${window.location.origin}/manage/desks/${deskId}/checkin`

  const handleLinkCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(generateCheckInURL(desks[0].id))
    } catch {
      return
    }

    infoToast(t("desktop.settings.desks.desk_form.link_copied_toast"))
  }, [desks, infoToast, t])

  const handleGetQRCode = useCallback(async () => {
    const response = await actions.fetchQrCode(generateCheckInURL(desks[0].id))
    if (qrCodeFetch.fulfilled.match(response)) {
      await saveAs(
        response.payload,
        `${desks[0].name.replace(" ", "-")}-QR-code.png`,
      )

      return
    }
    errorToast(response.error.message)
  }, [actions, desks, errorToast])

  const canReposition =
    !multiple && !process.env.REACT_APP_FEATURE_DISABLE_DESK_REPOSITIONING

  const updateMode = Boolean(id)

  const title = updateMode
    ? multiple
      ? t("desktop.settings.desks.desk_form.edit_multi_desk")
      : t("desktop.settings.desks.desk_form.edit_desk")
    : t("desktop.settings.desks.desk_form.new_desk")

  return (
    <FormProvider {...methods}>
      <ModalForm
        className="DeskForm"
        updateMode={updateMode}
        title={title}
        onCreate={onCreateClick}
        onUpdate={onUpdateClick}
        onDelete={onDeleteClick}
        additionalButton={
          updateMode && canReposition ? (
            <Button
              variant="secondary"
              className="move"
              onClick={onRepositionClick}
              isDisabled={isSubmitting}
            >
              {t("desktop.settings.desks.desk_form.reposition_button")}
            </Button>
          ) : undefined
        }
      >
        {!multiple && (
          <Field
            control={control}
            name={"name"}
            label={t("desktop.settings.desks.desk_form.desk_name_or_number")}
            helpText={t("desktop.settings.desks.desk_form.desk_name_example")}
          >
            {(props) => (
              <Input
                autoFocus
                maxLength={30}
                disabled={isSubmitting}
                {...props}
              />
            )}
          </Field>
        )}

        <Field
          control={control}
          name="departments"
          label={t("desktop.settings.desks.desk_form.departments")}
          subText={multipleDepartments && t("general.multiple_values")}
        >
          {(props) => (
            <AsyncSelect
              isPaginated
              isMulti
              urlGenerator={(fetchOptions) => {
                return departmentsURL(fetchOptions)
              }}
              nothingFoundMessage={t(
                "desktop.settings.desks.desk_form.no_departments_found",
              )}
              getOptionLabel={(department) => department?.name ?? ""}
              getOptionValue={(department) => department?.id ?? ""}
              disabled={isSubmitting}
              {...props}
            />
          )}
        </Field>
        <Field
          control={control}
          name="users"
          label={t("desktop.settings.desks.desk_form.assign_to")}
          subText={multipleUsers && t("general.multiple_values")}
        >
          {(props) => (
            <AsyncSelect
              isMulti
              urlGenerator={(fetchOptions) => {
                return usersURL(fetchOptions)
              }}
              nothingFoundMessage={t(
                "desktop.settings.desks.desk_form.no_user_found",
              )}
              getOptionLabel={(user) => formatUser(user ?? {})}
              getOptionValue={(user) => user?.email ?? ""}
              disabled={isSubmitting}
              {...props}
            />
          )}
        </Field>

        <Field
          control={control}
          name={"amenities"}
          label={t("general.amenities")}
          subText={multipleAmenities && t("general.multiple_values")}
        >
          {(props) => (
            <AsyncSelect
              isMulti
              urlGenerator={(fetchOptions) => {
                return amenitiesURL(fetchOptions)
              }}
              nothingFoundMessage={t(
                "desktop.settings.desks.desk_form.no_amenities_found",
              )}
              getOptionLabel={(amenity) => amenity?.name ?? ""}
              getOptionValue={(amenity) => amenity?.id ?? ""}
              disabled={isSubmitting}
              {...props}
            />
          )}
        </Field>
        <Field control={control} name="active" className="asset-enabled-field">
          {({ onChange, ...props }) => (
            <Switch
              {...props}
              onChange={(v) => {
                deskEnabled.current = v
                onChange(v)
              }}
              label={t("desktop.settings.desks.desk_form.enable_desk")}
              subLabel={multipleEnabled ? "multiple values" : undefined}
              disabled={isSubmitting}
            />
          )}
        </Field>
        {updateMode && !multiple && isPortalMergerEnabled && (
          <div className="qr-code">
            <Button variant="primary" onClick={handleGetQRCode}>
              {t("desktop.settings.desks.desk_form.buttons.get_qr_code")}
            </Button>
            <Button variant="primary" onClick={handleLinkCopy}>
              {t("desktop.settings.desks.desk_form.buttons.copy_link")}
            </Button>
          </div>
        )}
      </ModalForm>
    </FormProvider>
  )
}

export default DeskForm
