import React, { useContext, useEffect, useRef, useState } from "react"

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"
import { Device } from "@capacitor/device"
import { captureMessage } from "@sentry/react"

type FirebaseConfig = {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

type TFirebaseAnalytics = {
  logEvent: Function
}

const firebaseConfigEnv = process.env.REACT_APP_FIREBASE_ANALYTICS_CONFIG
let firebaseConfig: FirebaseConfig
let isFirebaseConfiguredWithEnv: Boolean

function initializeFirebaseEnv() {
  if (typeof firebaseConfigEnv === "string" && firebaseConfigEnv.length > 0) {
    try {
      firebaseConfig = JSON.parse(firebaseConfigEnv)
      if (firebaseConfig.apiKey && firebaseConfig.measurementId) {
        isFirebaseConfiguredWithEnv = true
      }
    } catch (e) {
      isFirebaseConfiguredWithEnv = false
      captureMessage("Failed to parse Firebase ENV string")
    }
  } else {
    isFirebaseConfiguredWithEnv = false
    captureMessage("Didn't receive Firebase ENV string")
  }
}

initializeFirebaseEnv()

type ProviderState = {
  analytics: TFirebaseAnalytics | {}
  isReady: boolean
}

const defaultValue: ProviderState = { analytics: {}, isReady: false }

export const FirebaseAnalyticsContext = React.createContext(defaultValue)

type ProviderProps = {
  children: React.ReactNode
}

export function useAnalyticsLog() {
  const { analytics, isReady } = useContext(FirebaseAnalyticsContext)

  function logEvent(name: string, params: object = {}) {
    if (isReady) {
      ;(analytics as TFirebaseAnalytics).logEvent({ name, params })
      // captureMessage(`Logged event ${event}.`)
    } else {
      console.log(`${name}: ${JSON.stringify(params)}`)
    }
  }

  return logEvent
}

export function useAnalyticsScreenView(name: string) {
  const logEvent = useAnalyticsLog()

  useEffect(() => {
    logEvent("screen_view", { screen_name: name })
  }, [])
}

const FirebaseAnalyticsProvider: React.FC<
  React.PropsWithChildren<ProviderProps>
> = ({ children }) => {
  const [contextValue, setContextValue] = useState(defaultValue)
  const isInitialized = useRef<Boolean>(false)

  useEffect(() => {
    async function initFirebase() {
      const deviceInfo = Device.getInfo()

      if ((await deviceInfo).platform === "web") {
        // Web, we check if we have config if not disable firebase
        if (isFirebaseConfiguredWithEnv === true) {
          FirebaseAnalytics.initializeFirebase(firebaseConfig)
          setContextValue({ analytics: FirebaseAnalytics, isReady: true })
        } else {
          setContextValue({ analytics: FirebaseAnalytics, isReady: false })
        }
      } else {
        // Is native device
        // Plugin initialize firebase
        setContextValue({ analytics: FirebaseAnalytics, isReady: true })
      }
    }

    if (!isInitialized.current) {
      initFirebase()
      isInitialized.current = true
    }

    return () => {}
  }, [])

  return (
    <FirebaseAnalyticsContext.Provider value={contextValue}>
      {children}
    </FirebaseAnalyticsContext.Provider>
  )
}

export default FirebaseAnalyticsProvider
