import { forwardRef, useRef } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import SignatureCanvas from "react-signature-canvas"

import ErrorText from "../../ErrorText"
import { Label } from "../../Field/Label"

import { ReactComponent as RefreshSVG } from "../../../assets/images/icons/Refresh.svg"

import "./style.sass"

type SignatureInputProps = {
  className?: string
  hasError?: boolean
}

export const SignatureInput = forwardRef<SignatureCanvas, SignatureInputProps>(
  ({ className, hasError }, ref) => {
    const { t } = useTranslation()

    const innerRef = useRef<SignatureCanvas | null>(null)

    const onClear = () => {
      innerRef.current?.clear()
    }

    return (
      <div className={classNames(["SignatureInput", className])}>
        <div>
          <Label>
            {t("general.signature")}
            <RefreshSVG onClick={onClear} />
          </Label>
        </div>
        <SignatureCanvas
          penColor="black"
          canvasProps={{ height: 100, width: 336 }}
          ref={(node) => {
            innerRef.current = node
            if (typeof ref === "function") {
              ref(node)
            } else if (ref) {
              ref.current = node
            }
          }}
        />
        {hasError && <ErrorText error={t("general.signature_required")} />}
      </div>
    )
  },
)
