import { forwardRef, useEffect, useRef, useState } from "react"

import { geocodeByPlaceId } from "../../../utils"
import { Input, InputProps } from "../../basic/Input"
import Loader from "../../basic/Loader"
import { GooglePlacesSelectType } from "./types"
import usePlacesAutocomplete from "react-google-autocomplete/lib/usePlacesAutocompleteService"

import "./style.sass"

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

type PlacesListProps = {
  places: any
  loading: boolean
  onSelect: (v: GooglePlacesSelectType) => void
}

const PlacesList = ({ places, loading, onSelect }: PlacesListProps) => {
  if (loading) {
    return (
      <div className="PlacesList">
        <div className="loading">
          <Loader size="small" />
        </div>
      </div>
    )
  }

  return (
    <div className="PlacesList">
      {places.map((place: any) => {
        const onClick = () => {
          onSelect(place)
        }

        return (
          <div className="PlaceItem" onClick={onClick} key={place.place_id}>
            {place.description}
          </div>
        )
      })}
    </div>
  )
}

type Props = {
  value: GooglePlacesSelectType
  onChange: (v: GooglePlacesSelectType) => void
  label?: string
} & Omit<InputProps, "value" | "onChange">

export const GooglePlacesSelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, label, ...props }, ref) => {
    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
      usePlacesAutocomplete({
        apiKey: GOOGLE_API_KEY,
        libraries: ["places", "maps"],
        options: {
          input: "",
          types: ["address"],
        },
      })

    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

    const [textValue, setTextValue] = useState(value.address || "")
    const [showList, setShowList] = useState(false)

    const innerOnChange = (value: string) => {
      if (value.length < textValue.length) {
        setTextValue(value)
        if (value.length === 0) {
          onChange({
            address: "",
            latitude: null,
            longitude: null,
            country: undefined,
          })
        }
        return
      }
      if (value.length > 0) {
        getPlacePredictions({ input: value })
        setShowList(true)
      }
      setTextValue(value)
    }

    const innerKeyUp = () => {
      onChange({
        address: textValue,
        latitude: null,
        longitude: null,
      })
    }

    const innerSelect = async (place: any) => {
      let geoResult = undefined
      let latitude = null
      let longitude = null
      let country = undefined

      if (place && place.place_id) {
        const geoResults = await geocodeByPlaceId(place.place_id)
        if (geoResults !== null && geoResults.length > 0) {
          geoResult = geoResults[0]
        }
      }

      if (geoResult) {
        latitude = geoResult.geometry.location.lat()
        longitude = geoResult.geometry.location.lng()
        country = geoResult.address_components.find(
          (c) => c.types[0] === "country",
        )?.short_name
      }

      onChange({
        address: place.description,
        latitude,
        longitude,
        country,
      })

      setTextValue(place.description)
    }

    const innerBlur = () => {
      timeoutRef.current = setTimeout(() => {
        setShowList(false)
      }, 200)
    }

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }
    }, [])

    useEffect(() => {
      if (textValue) return
      setTextValue(value.address || "")
    }, [textValue, value])

    return (
      <div className="GooglePlacesSelect">
        <div className="Wrapper">
          <Input
            {...props}
            ref={ref}
            value={textValue}
            onChange={innerOnChange}
            onBlur={innerBlur}
            onKeyUp={innerKeyUp}
          />
          {showList && placePredictions.length > 0 && (
            <PlacesList
              places={placePredictions}
              loading={isPlacePredictionsLoading}
              onSelect={innerSelect}
            />
          )}
        </div>
      </div>
    )
  },
)
