import React from "react"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { LICENSE_ASSIGNMENT } from "../../redux/users/types"

export const LicenseFilterValues = {
  ALL: {
    label: "All licenses",
    value: FilterSpecialValues.ALL,
  },
  ACTIVE: {
    label: "Assigned",
    value: LICENSE_ASSIGNMENT.ASSIGNED,
  },
  INACTIVE: {
    label: "Unassigned",
    value: LICENSE_ASSIGNMENT.UNASSIGNED,
  },
}

type Props = {
  value: string
  onChange: (v: string) => void
}

export default function LicenseFilter({ value, onChange }: Props) {
  const LicensesOptions = Object.values(LicenseFilterValues)

  return (
    <Dropdown
      className="role-filter"
      options={LicensesOptions}
      value={value}
      onChange={onChange}
    />
  )
}
