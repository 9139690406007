import React from "react"

import { Switch as RouteSwitch } from "react-router"
import { Redirect } from "react-router-dom"

import { PROFILE_PATHS } from "./constants"
import Email from "./Email"
import General from "./General"
import Password from "./Password"

import ProtectedRoute from "../../../components/ProtectedRoute"

const UserProfile = () => {
  return (
    <RouteSwitch>
      <ProtectedRoute exact path={PROFILE_PATHS.root} component={General} />
      <ProtectedRoute exact path={PROFILE_PATHS.email} component={Email} />
      <ProtectedRoute
        exact
        path={PROFILE_PATHS.password}
        component={Password}
      />
      <Redirect to={PROFILE_PATHS.root} />
    </RouteSwitch>
  )
}

export default UserProfile
