import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchDepartmentQuery } from "../../../redux/api/departments"
import { DepartmentResponse } from "../../../redux/api/departments/types"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import DepartmentForm from "../../../components/Form/DepartmentForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

type ParamsType = {
  department_id: string
}

const DEFAULT_DEPARTMENT = {
  name: "",
} as DepartmentResponse

const EditDepartments = () => {
  const { t } = useTranslation()
  const { department_id } = useParams<ParamsType>()

  const {
    data: department = DEFAULT_DEPARTMENT,
    isLoading,
    error,
  } = useFetchDepartmentQuery(department_id ?? skipToken)

  const departmentNotFound =
    (!!department_id && !department.id) ||
    (error && "status" in error && error.status === 404)

  return (
    <View className="EditDepartments">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.departments.title"),
          department?.id
            ? t("desktop.settings.departments.edit_breadcrumb")
            : t("desktop.settings.departments.add_breadcrumb"),
        ]}
      />

      <Intro isConstrained>
        {department?.id
          ? t("desktop.settings.departments.edit_title")
          : t("desktop.settings.departments.add_title")}
      </Intro>

      <Space size={0.75} />

      {isLoading ? (
        <Loader />
      ) : departmentNotFound ? (
        <p>{t("desktop.settings.departments.not_found")}</p>
      ) : (
        <DepartmentForm department={department} />
      )}
    </View>
  )
}

export default EditDepartments
