import { useMemo } from "react"

import dayjs from "dayjs"
import { t } from "i18next"
import { Trans } from "react-i18next"

import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { getLabel } from "../../../../utils"
import { INTEGRATIONS_PATHS } from "../constants"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  useDeleteApiKeyMutation,
  useFetchApiKeysQuery,
} from "../../../../redux/api/apiKeys"
import { ApiKeyResponse } from "../../../../redux/api/apiKeys/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import Button from "../../../../components/advanced/Button"
import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import CopyToClipboard from "../../../../components/advanced/CopyToClipboard"
import Table from "../../../../components/advanced/Table"
import { Column } from "../../../../components/basic/Table"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./ApiKeys.sass"

const ApiKeys = () => {
  const { errorToast, infoToast } = useToast()
  const { push } = useNavigation()
  const { openModal, closeModal } = useModals()

  const { data: { results: apiKeys = [] } = {} } = useFetchApiKeysQuery()

  const apiKeysWithId = useMemo(() => {
    return apiKeys.map((apiKey) => ({
      ...apiKey,
      id: apiKey.client_id,
    }))
  }, [apiKeys])

  const [expireApiKey] = useDeleteApiKeyMutation()

  const expireApiKeyHandler = async (clientId: string) => {
    const response = await expireApiKey({ client_id: clientId })

    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        errorToast(error.message)
        return
      }
    }

    infoToast(
      t("desktop.settings.integrations.api.table.expired_toast_success"),
    )
  }

  const handleExpireClick = (clientId: string) => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await expireApiKeyHandler(clientId)
        closeModal()
      },
    })
  }

  const tableColumns = useMemo<Column<ApiKeyResponse>[]>(() => {
    return [
      {
        field: "name",
        label: t("desktop.settings.integrations.api.table.header.name"),
        renderCell: (i) => {
          return i.name
        },
      },
      {
        field: "created_at",
        label: t("desktop.settings.integrations.api.table.header.created"),
        renderCell: (i) => {
          return dayjs(i.created_at).format("YYYY/MM/DD HH:mm")
        },
      },
      {
        field: "client_id",
        label: t("desktop.settings.integrations.api.table.header.client_id"),
        renderCell: (i) => {
          return (
            <div className="ClientIdCell">
              <CopyToClipboard text={i.client_id} />
              <Button
                isSmall
                noConfirm
                variant="danger"
                onClick={() => handleExpireClick(i.client_id)}
              >
                {t("desktop.settings.integrations.api.table.expire")}
              </Button>
            </div>
          )
        },
      },
    ]
  }, [t])

  return (
    <View className="ApiIntegration">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.integrations.title"),
          t("desktop.settings.integrations.api.title"),
        ]}
      />
      <Intro>
        <span>
          <Trans i18nKey="desktop.settings.integrations.api.intro">
            <a href={`mailto:${getLabel("links.supportEmail")}`}>
              Support Email
            </a>
          </Trans>
        </span>
        <Button isSmall onClick={() => push(INTEGRATIONS_PATHS.newApiKey)}>
          {t("desktop.settings.integrations.api.new_token")}
        </Button>
      </Intro>

      <Space size={0.75} />

      <p className="TableTitle">
        {t("desktop.settings.integrations.api.table.title")}
      </p>

      <Space size={0.75} />

      <Table
        className="ApiKeysTable"
        loading={false}
        columns={tableColumns}
        rows={apiKeysWithId}
      />
    </View>
  )
}

export default ApiKeys
