import { MouseEvent, useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { COUNTRY_OPTIONS } from "../../constants"
import { useToast } from "../../hooks/useToast"
import { LANGUAGE_OPTIONS } from "../../i18n"
import { ORGANIZATION_PATHS } from "../../screens/Settings/Organization/constants"
import { OptionType } from "../../types/sharedTypes"
import { getOption } from "../../utils"
import { Input } from "../basic/Input"
import { InputPhone } from "../basic/InputPhone"
import { Select } from "../basic/Select"
import Field from "../Field"
import { setErrors } from "./formUtils"
import { TIMEZONE_OPTIONS } from "./options"
import PageForm from "./PageFormHook"

import { useUpdateCompanyDetailsMutation } from "../../redux/api/company"
import {
  EMPLOYEES_OPTIONS,
  INDUSTRIES_OPTIONS,
} from "../../redux/api/company/constants"
import {
  CompanyDetails,
  TIME_FORMAT_OPTIONS,
} from "../../redux/api/company/types"
import { isApiResponseError, isRejected } from "../../redux/api/types"

import "./CompanyDetailsForm.sass"

type CompanyDetailsFormProps = {
  company: CompanyDetails
}

type FormValues = {
  name: string
  country: OptionType
  industry: OptionType
  employees: OptionType
  timezone: OptionType
  language: OptionType
  phone: string
  timeformat: OptionType
}

const CompanyDetailsForm = ({ company }: CompanyDetailsFormProps) => {
  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()

  const history = useHistory()

  const {
    name,
    country,
    industry,
    employees,
    settings: { timezone, language, timeformat },
    phone,
  } = company ?? {}

  const methods = useForm<FormValues>({
    defaultValues: {
      name: name ?? "",
      country: getOption(COUNTRY_OPTIONS, country),
      industry: getOption(INDUSTRIES_OPTIONS, industry),
      employees: getOption(EMPLOYEES_OPTIONS, employees),
      timezone: getOption(TIMEZONE_OPTIONS, timezone),
      language: getOption(LANGUAGE_OPTIONS, language),
      timeformat: getOption(TIME_FORMAT_OPTIONS, timeformat?.toString()),
      phone: phone ?? "",
    },
  })
  const {
    setError,
    control,
    formState: { isSubmitting },
  } = methods

  const [updateCompanyDetails] = useUpdateCompanyDetailsMutation()

  const onUpdateClick = useCallback(
    async ({
      name,
      country,
      industry,
      employees,
      phone,
      timezone,
      language,
      timeformat,
    }: FormValues) => {
      const response = await updateCompanyDetails({
        name,
        country: country?.value,
        industry: industry?.value,
        employees: employees?.value,
        phone,
        settings: {
          timezone: timezone?.value,
          language: language?.value,
          timeformat: timeformat?.value,
        },
      })

      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast)
          return
        }
      }

      infoToast(
        t("desktop.settings.organization.company_details.form.update_toast"),
      )
    },
    [infoToast, t, updateCompanyDetails, setError, errorToast],
  )

  const onDeleteClick = async (e: MouseEvent) =>
    history.push(ORGANIZATION_PATHS.company_details_delete)

  return (
    <FormProvider {...methods}>
      <PageForm
        className="OrganizationCompanyDetailsForm"
        deleteButtonText={t(
          "desktop.settings.organization.company_details.form.delete",
        )}
        confirmationPrompt={false}
        updateMode
        onUpdate={onUpdateClick}
        onDelete={onDeleteClick}
      >
        <div className="field-width-50">
          <Field
            control={control}
            name="name"
            label={t(
              "desktop.settings.organization.company_details.form.company_name",
            )}
            required
            rules={{
              required: t(
                "desktop.settings.organization.company_details.form.required",
              ),
            }}
          >
            {(props) => (
              <Input
                autoFocus
                maxLength={60}
                disabled={isSubmitting}
                {...props}
              />
            )}
          </Field>

          <Field
            control={control}
            name="industry"
            label={t(
              "desktop.settings.organization.company_details.form.industry",
            )}
            required
            rules={{
              required: t(
                "desktop.settings.organization.company_details.form.required",
              ),
            }}
          >
            {(props) => <Select {...props} options={INDUSTRIES_OPTIONS} />}
          </Field>

          <Field
            control={control}
            name="phone"
            label={t(
              "desktop.settings.organization.company_details.form.phone",
            )}
          >
            {(props) => (
              <InputPhone
                {...props}
                placeholder={t(
                  "desktop.settings.organization.company_details.form.phone_placeholder",
                )}
                disabled={isSubmitting}
              />
            )}
          </Field>

          <Field
            control={control}
            name="language"
            label={t(
              "desktop.settings.organization.company_details.form.language",
            )}
          >
            {(props) => <Select {...props} options={LANGUAGE_OPTIONS} />}
          </Field>
        </div>
        <div className="field-width-50">
          <Field
            control={control}
            name="country"
            label={t(
              "desktop.settings.organization.company_details.form.country",
            )}
            required
            rules={{
              required: t(
                "desktop.settings.organization.company_details.form.required",
              ),
            }}
          >
            {(props) => <Select {...props} options={COUNTRY_OPTIONS} />}
          </Field>

          <Field
            control={control}
            name="employees"
            label={t(
              "desktop.settings.organization.company_details.form.employees",
            )}
            required
            rules={{
              required: t(
                "desktop.settings.organization.company_details.form.required",
              ),
            }}
          >
            {(props) => <Select {...props} options={EMPLOYEES_OPTIONS} />}
          </Field>

          <Field
            control={control}
            name="timezone"
            label={t(
              "desktop.settings.organization.company_details.form.timezone",
            )}
          >
            {(props) => <Select {...props} options={TIMEZONE_OPTIONS} />}
          </Field>

          <Field
            control={control}
            name="timeformat"
            label={t(
              "desktop.settings.organization.company_details.form.time_format",
            )}
          >
            {(props) => <Select {...props} options={TIME_FORMAT_OPTIONS} />}
          </Field>
        </div>
      </PageForm>
    </FormProvider>
  )
}

export default CompanyDetailsForm
