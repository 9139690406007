import { registerLocale, setDefaultLocale } from "react-datepicker"

import { enGB, enUS, es, fr, nl, pl, sl } from "date-fns/locale"

type LocaleMap = {
  [key: string]: Locale
}

/**
 * Imports and registers locales dynamically.
 * @param locale - The locale to load.
 * @returns A promise that resolves when the locale data has been loaded.
 */
export const loadLocaleData = async (locale: string) => {
  try {
    // Import the locale data
    const locales: LocaleMap = {
      en: enUS,
      "en-GB": enGB,
      "en-US": enUS,
      es,
      fr,
      nl,
      pl,
      sl,
    }
    const localeData = locales[locale]

    // Register the locale data
    registerLocale(locale, localeData)
  } catch (error) {
    console.error(`Error loading locale data for ${locale}:`, error)
  }

  // Set the default locale
  setDefaultLocale("en-US")
}

/**
 * Formats the time according to the locale.
 * @param locale - The locale to use for formatting the time.
 * @returns The format string for the time.
 */
export const getTimeFormat = (locale: string): string => {
  if (locale.includes("en")) return "h:mm aa" // 12-hour format
  return "HH:mm" // Default to 24-hour format
}
