import { MouseEvent, useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { FEATURE_FLAGS } from "../../constants"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import { useToast } from "../../hooks/useToast"
import BuildingDeleteModal from "../../modals/BuildingDeleteModal"
import { BUILDINGS_PATHS } from "../../screens/Settings/Buildings/constants"
import { OptionType } from "../../types/sharedTypes"
import { calculatePercentAmount } from "../../utils"
import Button from "../advanced/Button"
import GoogleMap from "../advanced/GoogleMap"
import { GooglePlacesSelect } from "../advanced/GooglePlacesSelect"
import { GooglePlacesSelectType } from "../advanced/GooglePlacesSelect/types"
import { LimitOccupancyDecider } from "../advanced/LimitOccupancyDecider"
import Divider from "../basic/Divider"
import { Input } from "../basic/Input"
import { Select } from "../basic/Select"
import Field from "../Field"
import Space from "../Space"
import { setErrors } from "./formUtils"
import { TIMEZONE_OPTIONS } from "./options"
import PageForm from "./PageFormHook"
import { useModals } from "@mattjennings/react-modal-stack"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import {
  useCreateBuildingMutation,
  useFetchBuildingsQuery,
  useUpdateBuildingMutation,
} from "../../redux/api/buildings"
import { BuildingResponse } from "../../redux/api/buildings/types"
import { useFetchFloorsQuery } from "../../redux/api/floors"
import { useFetchSolutionsQuery } from "../../redux/api/solutions"
import { isApiResponseError, isRejected } from "../../redux/api/types"

import "./BuildingForm.sass"

type BuildingFormProps = {
  building: Partial<BuildingResponse>
}

type FormValues = {
  name: string
  address: GooglePlacesSelectType
  timezone: OptionType<string>
  limit: number | null
  maintenance_email?: string
}

const BuildingForm = ({ building }: BuildingFormProps) => {
  const { openModal } = useModals()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()
  const { id, name, address, latitude, longitude, settings } = building || {}
  const history = useHistory()

  const isPortalMergerEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.PORTAL_MERGER,
  )

  const { data: solutions } = useFetchSolutionsQuery()

  const hasDeskBookingActivated = !!solutions?.desk.active

  const { data: { results: floors = [] } = {}, isLoading: areFloorsLoading } =
    useFetchFloorsQuery(
      id
        ? {
            limit: 0,
            building: id,
          }
        : skipToken,
    )

  const methods = useForm<FormValues>({
    defaultValues: {
      name: name ?? "",
      address: {
        address,
        latitude,
        longitude,
      } as GooglePlacesSelectType,
      timezone: settings?.timezone
        ? {
            label: settings?.timezone,
            value: settings?.timezone,
          }
        : undefined,
      limit: settings?.desk_capacity_limit ?? null,
      ...(isPortalMergerEnabled && {
        maintenance_email: settings?.maintenance_email ?? "",
      }),
    },
  })

  const {
    setError,
    control,
    getValues,
    formState: { isSubmitting },
    watch,
  } = methods

  const coords = watch("address")

  const hasCoords = !!coords?.latitude && !!coords?.longitude

  const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

  const [updateBuilding] = useUpdateBuildingMutation()
  const [createBuilding] = useCreateBuildingMutation()

  const onCreateClick = useCallback(
    async ({
      name,
      address,
      limit,
      timezone,
      maintenance_email,
    }: FormValues) => {
      const response = await createBuilding({
        ...address,
        name,
        parking_slots: 0,
        active: true,
        settings: {
          timezone: timezone?.value ?? null,
          desk_capacity_limit: limit,
          ...(isPortalMergerEnabled && {
            maintenance_email: maintenance_email,
          }),
        },
      })

      if (isRejected(response)) {
        if (isApiResponseError(response.error)) {
          setErrors(response.error.formError, setError, errorToast, {
            "settings.timezone": "timezone",
            "settings.maintenance_email": "maintenance_email",
          })
        }
      } else {
        analyticsEvent(SupportedEvents.LOCATION_ADD, {
          id: response.data.id,
          name,
          total: buildings.length + 1,
        })
        infoToast(
          t("desktop.settings.buildings.building_form.building_created_toast"),
        )
        history.push(BUILDINGS_PATHS.root)
      }
    },
    [
      history,
      buildings,
      isPortalMergerEnabled,
      createBuilding,
      setError,
      errorToast,
      infoToast,
      t,
    ],
  )

  const onUpdateClick = useCallback(
    async ({
      name,
      address,
      limit,
      timezone,
      maintenance_email,
    }: FormValues) => {
      if (id) {
        const response = await updateBuilding({
          id,
          ...address,
          name,
          parking_slots: 0,
          active: true,
          settings: {
            timezone: timezone?.value ?? null,
            desk_capacity_limit: limit,
            ...(isPortalMergerEnabled && {
              maintenance_email: maintenance_email,
            }),
          },
        })

        if (isRejected(response)) {
          if (isApiResponseError(response.error)) {
            setErrors(response.error.formError, setError, errorToast, {
              "settings.timezone": "timezone",
              "settings.maintenance_email": "maintenance_email",
            })
          }
        } else {
          analyticsEvent(SupportedEvents.LOCATION_UPDATE, {
            id,
            name,
          })
          infoToast(
            t(
              "desktop.settings.buildings.building_form.building_updated_toast",
            ),
          )
          history.push(BUILDINGS_PATHS.root)
        }
      }
    },
    [
      history,
      id,
      isPortalMergerEnabled,
      updateBuilding,
      setError,
      errorToast,
      infoToast,
      t,
    ],
  )

  const onDeleteClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()

      if (building !== null && building.id) {
        openModal(BuildingDeleteModal, {
          building: building as BuildingResponse,
        })
      }
    },
    [openModal, building],
  )

  const totalDeskNo = building?.desks_count || 0

  const activeDeskAmount = calculatePercentAmount(
    totalDeskNo,
    getValues().limit || 0,
  )

  return (
    <FormProvider {...methods}>
      <PageForm
        backUrl={BUILDINGS_PATHS.root}
        className="BuildingForm"
        updateMode={!!id}
        onCreate={onCreateClick}
        onUpdate={onUpdateClick}
        onDelete={onDeleteClick}
        confirmationPrompt={false}
      >
        <Field
          control={control}
          name="name"
          label={t("desktop.settings.buildings.building_form.building_name")}
        >
          {(props) => (
            <Input
              autoFocus
              maxLength={60}
              disabled={isSubmitting}
              {...props}
            />
          )}
        </Field>
        <Field
          control={control}
          name="address"
          label={t("desktop.settings.buildings.building_form.address")}
          subText={t("general.optional")}
        >
          {(props) => (
            <GooglePlacesSelect
              {...props}
              maxLength={200}
              disabled={isSubmitting}
            />
          )}
        </Field>

        {hasCoords && (
          <GoogleMap
            className="map"
            position={{ lat: coords.latitude!, lng: coords.longitude! }}
          />
        )}

        <Field
          control={control}
          name="timezone"
          label={t("desktop.settings.buildings.building_form.timezone")}
          subText={t("general.optional")}
        >
          {(props) => (
            <Select
              {...props}
              options={TIMEZONE_OPTIONS}
              disabled={isSubmitting}
              clearable
            />
          )}
        </Field>

        {isPortalMergerEnabled && (
          <Field
            control={control}
            name="maintenance_email"
            label={t(
              "desktop.settings.buildings.building_form.maintenance_email",
            )}
            subText={t("general.optional")}
          >
            {(props) => (
              <Input
                type="email"
                {...props}
                maxLength={60}
                disabled={isSubmitting}
              />
            )}
          </Field>
        )}

        <Field control={control} name="limit">
          {(props) => (
            <LimitOccupancyDecider
              {...props}
              label={t(
                "desktop.settings.buildings.building_form.building_occupancy_limit",
              )}
              infoText={t(
                "desktop.settings.buildings.building_form.building_bookable_desks",

                { activeDeskAmount, totalDeskNo },
              )}
              maxLength={30}
              serviceRestriction={!hasDeskBookingActivated ? "desk" : "none"}
              disabled={isSubmitting}
            />
          )}
        </Field>

        {!areFloorsLoading && id && (
          <div className="building-floors-container">
            <p className="title">
              {t("desktop.settings.buildings.building_form.building_floors")}
            </p>
            <div className="building-floors-list">
              {floors.map((floor, pos) => (
                <div key={floor.id} className="building-floor">
                  {pos === 0 && <Divider color="gray-3" hasMargin={false} />}
                  <div>
                    <Link
                      to={BUILDINGS_PATHS.edit_floor
                        .replace(":building_id", id)
                        .replace(":floor_id", floor.id)}
                    >
                      <p className="floor-link">{floor.name}</p>
                    </Link>
                  </div>
                  <Divider color="gray-3" hasMargin={false} />
                </div>
              ))}
            </div>
            <Button
              className="add-floor-button"
              variant="secondary"
              onClick={() => {
                history.push(
                  `${BUILDINGS_PATHS.add_floor}`.replace(":building_id", id),
                )
              }}
            >
              {t("desktop.settings.buildings.building_form.add_floor")}
            </Button>
            <Space size={0.75} />
          </div>
        )}
      </PageForm>
    </FormProvider>
  )
}

export default BuildingForm
