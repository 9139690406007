import React from "react"

import dayjs from "dayjs"

import { pluralize } from "../utils"

const shortFormats = {
  day: "ddd",
  month: "MMM",
  date: "D",
  year: "YYYY",
}

const longFormats = {
  day: "dddd",
  month: "MMMM",
  date: "D",
  year: "YYYY",
}

type Props = {
  className?: any
  date?: dayjs.ConfigType
  isInline?: boolean
  isLong?: boolean
  showYear?: boolean
  isPluralShown?: boolean
}

const Datestring = ({
  className,
  date,
  isInline,
  isLong,
  showYear,
  isPluralShown = true,
  ...props
}: Props) => {
  const fmt = isLong ? longFormats : shortFormats

  const dayjsDate = dayjs(date)
  const month = dayjsDate.format(fmt.month)
  const day = dayjsDate.format(fmt.day)
  const dateNum = dayjsDate.format(fmt.date)
  const year = dayjsDate.format(fmt.year)

  const delim = isInline ? ", " : " "

  return (
    <span className={className} {...props}>
      {`${day}${delim}${month} ${
        isPluralShown ? pluralize(parseInt(dateNum)) : dateNum
      }`}
      {showYear && `${delim}${year}`}
    </span>
  )
}

export default Datestring
