import { useReducer } from "react"

import classNames from "classnames"

import { patchJSON, postJSON, putJSON } from "../../../api"
import { useToast } from "../../../hooks/useToast"
import { Checkbox, CheckboxProps } from "../../basic/Checkbox"
import reducer, { ActionTypes, initialState } from "./reducer"

import { useAppSelector } from "../../../redux/reducers"
import { getErrorMessage } from "../../../redux/reduxUtils"

type Props = {
  className?: string
  urlGenerator: () => string
  bodyGenerator: (value: boolean) => {
    [x: string]: any
  }
  value: boolean | undefined
  refresh?: (value: boolean) => void
  updateMode?: boolean
  type?: "patch" | "put"
  isSecondary?: boolean
} & CheckboxProps

/**
 * A component that will trigger a put or a patch
 * to a certain endpoint with a provided body as
 * a parameter. Once done, it will trigger an onSuccess
 * function. It will display the given value as well.
 */
export const AsyncCheckbox = ({
  className,
  urlGenerator,
  bodyGenerator,
  value,
  refresh,
  updateMode = true,
  disabled,
  type = "patch",
  isSecondary = false,
  ...props
}: Props) => {
  const { errorToast } = useToast()
  const { access_token } = useAppSelector((state) => state.auth)

  const [{ loading, error }, dispatch] = useReducer(reducer, initialState)

  const toggle = async (value: boolean) => {
    if (!access_token) return

    dispatch({ type: ActionTypes.START })

    let response
    const url = urlGenerator()
    const body = {
      body: bodyGenerator(value),
    }

    if (updateMode) {
      if (type === "patch") {
        response = await patchJSON(url, body, access_token)
      } else {
        response = await putJSON(url, body, access_token)
      }
    } else {
      response = await postJSON(url, body, access_token)
    }

    if (response.ok) {
      dispatch({ type: ActionTypes.STOP })
      refresh!(value)
      return
    }

    const error = await getErrorMessage(response)

    dispatch({ type: ActionTypes.ERROR, payload: error })
    errorToast(error)
  }

  const actualValue = value ?? false

  return (
    <div className={classNames("AsyncCheckbox", className)}>
      <Checkbox
        {...props}
        value={actualValue}
        onChange={toggle}
        hasError={!!error}
        disabled={disabled || loading}
        isSecondary={isSecondary}
      />
    </div>
  )
}
