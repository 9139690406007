import { MouseEvent, SyntheticEvent, useMemo, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"

import { InternalTime } from "../../types/sharedTypes"
import { setTimeToDayjs, toInternalTime } from "../../utils"
import Button from "../advanced/Button"
import Card from "../basic/Card"
import { EmailField } from "../Field/EmailField"
import { NameField } from "../Field/NameField"
import { PhoneField } from "../Field/PhoneField"
import { StartEndField } from "../Field/StartEndField"
import Space from "../Space"

import {
  AppointmentRequest,
  AppointmentResponse,
} from "../../redux/appointments/types"

import "./VisitorAppointmentForm.sass"

type Props = {
  className?: any
  onCancel?: () => void
  onSubmit?: (a: AppointmentRequest) => void
  submitLabel?: string
  appointment: Partial<AppointmentResponse>
  isLoading?: boolean
  canEdit?: boolean
}

const VisitorAppointmentForm = ({
  className,
  onCancel,
  onSubmit,
  submitLabel = "Submit",
  appointment,
  isLoading,
  canEdit,
}: Props) => {
  const { visitors, host, start, end } = appointment || {}

  const visitor = Array.isArray(visitors) ? visitors[0] : undefined

  const [visitorFirstName, setVisitorFirstName] = useState(
    visitor?.first_name || "",
  )
  const [visitorLastName, setVisitorLastName] = useState(
    visitor?.last_name || "",
  )
  const [visitorEmail, setVisitorEmail] = useState(visitor?.email || "")
  const [visitorPhone, setVisitorPhone] = useState(visitor?.phone || "")
  const [visitorHost, setVisitorHost] = useState(host?.email || "")
  const [visitStart, setVisitStart] = useState<InternalTime>(
    toInternalTime(dayjs(start || dayjs())),
  )
  const [visitEnd, setVisitEnd] = useState<InternalTime>(
    toInternalTime(dayjs(end || dayjs())),
  )

  const isValid = useMemo(() => {
    return !!visitorHost && !!visitorEmail
  }, [visitorEmail, visitorHost])

  const onSubmitClick = (e: SyntheticEvent) => {
    e.preventDefault()

    const appointment = {
      host: { email: visitorHost },
      visitors: [
        {
          id: visitor?.id,
          email: visitorEmail,
          first_name: visitorFirstName,
          last_name: visitorLastName,
          phone: visitorPhone,
        },
      ],
      start: setTimeToDayjs(dayjs(), visitStart).toISOString(),
      end: setTimeToDayjs(dayjs(), visitEnd).toISOString(),
    }

    onSubmit && onSubmit(appointment)
  }

  const onCancelClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()

    onCancel && onCancel()
  }

  const formClassName = classNames({
    VisitorAppointmentForm: true,
    [className]: className,
  })

  return (
    <form className={formClassName} onSubmit={onSubmitClick}>
      <Card className="visitor-ad-hoc-login">
        <div className="fields">
          <NameField
            label={"First name"}
            value={visitorFirstName}
            onChange={setVisitorFirstName}
            maxLength={30}
            canEdit={canEdit}
            required
          />

          <NameField
            label={"Last name"}
            value={visitorLastName}
            onChange={setVisitorLastName}
            maxLength={30}
            canEdit={canEdit}
            required
          />

          <EmailField
            label={"Your email address *"}
            value={visitorEmail}
            onChange={setVisitorEmail}
            maxLength={320}
            canEdit={canEdit}
            required
          />

          <PhoneField
            label={"Phone"}
            value={visitorPhone}
            onChange={setVisitorPhone}
            maxLength={30}
            canEdit={canEdit}
            required
          />

          <EmailField
            label={"Host's email address *"}
            value={visitorHost}
            onChange={setVisitorHost}
            maxLength={320}
            canEdit={canEdit}
            required
          />

          <StartEndField
            startValue={visitStart}
            onStartChange={setVisitStart}
            endValue={visitEnd}
            onEndChange={setVisitEnd}
            canEdit={canEdit}
            required
          />
        </div>
      </Card>

      <Space size={0.75} />

      <div className={`actions`}>
        <Button
          isSubmit
          variant="submit"
          className="submit"
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          {submitLabel}
        </Button>

        <Button variant="danger-pop" onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default VisitorAppointmentForm
