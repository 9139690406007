import { ParseKeys } from "i18next"

import { Children } from "../../../../types/sharedTypes"

import { INTEGRATION_TYPES } from "../../../../redux/api/integrations/constants"
import { IntegrationTypes } from "../../../../redux/api/integrations/types"

import { ReactComponent as MSTeamsSVG } from "../../../../assets/images/chat_bots/MSTeams.svg"
import { ReactComponent as SlackSVG } from "../../../../assets/images/chat_bots/Slack.svg"

export const CHAT_BOTS: Record<
  IntegrationTypes,
  { intro: ParseKeys; title: ParseKeys; icon: Children }
> = {
  [INTEGRATION_TYPES.SLACK]: {
    intro: "desktop.settings.integrations.chat_bots.bots.slack.intro",
    title: "desktop.settings.integrations.chat_bots.bots.slack.title",
    icon: <SlackSVG />,
  },
  [INTEGRATION_TYPES.TEAMS]: {
    intro: "desktop.settings.integrations.chat_bots.bots.ms_teams.intro",
    title: "desktop.settings.integrations.chat_bots.bots.ms_teams.title",
    icon: <MSTeamsSVG />,
  },
}
