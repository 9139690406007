import React from "react"

import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { BILLING_PATHS } from "../constants"
import CreditCardInfo from "../PaymentMethod/CreditCardInfo"

import { PAYMENT_METHODS } from "../../../../redux/api/billing/constants"
import { Payments } from "../../../../redux/api/billing/payments/types"
import { useFetchSubscriptionsQuery } from "../../../../redux/api/billing/subscriptions"

import Button from "../../../../components/advanced/Button"

import { ReactComponent as EditSVG } from "../../../../assets/images/icons/Pencil.svg"
import { ReactComponent as AddSVG } from "../../../../assets/images/icons/PlusCircle.svg"

import "./PaymentMethodInfo.sass"

type PaymentMethodInfoProps = {
  payments: Payments
}
const PaymentMethodInfo = ({ payments }: PaymentMethodInfoProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: { results: subscriptions = [] } = {} } =
    useFetchSubscriptionsQuery()
  const subscriptionsWithPrepaidCode = subscriptions.filter(
    (s) => s.prepaid_code,
  )
  const getPaymentMethodInfo = () => {
    if (payments.type === PAYMENT_METHODS.CREDIT_CARD) {
      return payments.credit_card ? (
        <CreditCardInfo creditCard={payments.credit_card} />
      ) : (
        <div>
          {t("desktop.settings.billing.payment_method.credit_card.no_card")}
        </div>
      )
    }
    if (payments.type === PAYMENT_METHODS.INVOICE) {
      return (
        <div>
          {t("desktop.settings.billing.payment_method.options.invoice")}
        </div>
      )
    }
    if (payments.type === PAYMENT_METHODS.PREPAID_CODE) {
      return (
        <div>
          {t("desktop.settings.billing.payment_method.options.prepaid_code")}
        </div>
      )
    }
  }

  const handleOnPaymentMethodClick = () =>
    history.push(BILLING_PATHS.overview.paymentMethod)

  return (
    <div className="PaymentMethodInfo">
      <div className="PaymentMethodInfo__header">
        {t("desktop.settings.billing.payment_details.headers.payment_method")}
      </div>
      <div className="PaymentMethodInfo_info">{getPaymentMethodInfo()}</div>
      {subscriptionsWithPrepaidCode.length > 0 ? (
        <div className="PaymentMethodInfo_prepaid-code">
          {t("desktop.settings.billing.payment_details.prepaid_codes_applied", {
            count: subscriptionsWithPrepaidCode.length,
          })}
        </div>
      ) : null}
      <Button
        variant="link"
        onClick={handleOnPaymentMethodClick}
        icon={payments.type ? <EditSVG /> : <AddSVG />}
      >
        {t(
          `desktop.settings.billing.payment_details.buttons.${
            payments.type ? "manage" : "add"
          }_payment_method` as ParseKeys,
        )}
      </Button>
    </div>
  )
}

export default PaymentMethodInfo
