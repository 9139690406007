import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import {
  useCreateTimeslotMutation,
  useLazyFetchTimeslotsQuery,
} from "../redux/api/timeslots"
import { changeLanguage } from "../redux/app/appSlice"
import { fetchBuildingsWithVM } from "../redux/buildings/buildingsWithVMSlice"
import { selectIsAuth } from "../redux/selectors"
import { FetchOptions } from "../redux/users/types"
import { useActions } from "../redux/utils"

import { MemoizedErrorFallback } from "../components/RouteView"

/*
    Make sure the app is ready for usage, the customer must have a building
	and a timeslot ready before we can proceed proceed.

	Authentication and user fetching are already complete at this point.
*/
export const InitializationProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const { i18n } = useTranslation()
  const isAuth = useSelector(selectIsAuth)

  const [error, setError] = useState<string>()

  const memoizedError = useMemo(() => (error ? Error(error) : null), [error])

  const [createTimeslot] = useCreateTimeslotMutation()

  const reloadPage = useCallback(() => window.location.reload(), [])

  const actions = useActions({
    fetchBuildingsWithVM: (options: FetchOptions = {}) =>
      fetchBuildingsWithVM(options),
    changeLanguage: (lang: string) => changeLanguage(lang),
  })

  const [triggerFetchTimeslotsQuery, result] = useLazyFetchTimeslotsQuery()

  const {
    data: slots = [],
    isUninitialized: areTimeslotsUninitialized,
    isSuccess: areTimeslotsLoaded,
  } = result

  const initializeApp = useCallback(async () => {
    setError(undefined)
    try {
      actions.fetchBuildingsWithVM()

      if (slots.length === 0) {
        createTimeslot({
          name: "All day",
          from: "09:00",
          to: "17:00",
          is_default: true,
          type: "desk",
          active: true,
        })
      }
    } catch (e: any) {
      setError("Something went wrong when the app was being set up.")
      console.error(e.message)
    }
  }, [slots])

  useEffect(() => {
    // Calling API for timeslots is too early as the token is not registered yet so we have to fetch manually when auth is ready
    if (areTimeslotsUninitialized && isAuth) {
      triggerFetchTimeslotsQuery()
    }
    if (isAuth && areTimeslotsLoaded) {
      initializeApp()
    }
  }, [isAuth, areTimeslotsLoaded, areTimeslotsUninitialized])

  useEffect(() => {
    actions.changeLanguage(i18n.language)
  }, [i18n.language])

  if (memoizedError) {
    return (
      <MemoizedErrorFallback resetError={reloadPage} error={memoizedError} />
    )
  }

  return <>{children}</>
}
