import React from "react"

import classNames from "classnames"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import { useCurrencyFormatter } from "../../../../hooks/useCurrencyFormatter"
import { createArray } from "../../../../utils"
import { getPlanTypeObject } from "../utils"

import { Plan } from "../../../../redux/api/billing/plans/types"
import {
  PlanType,
  SubscriptionPeriod,
} from "../../../../redux/api/billing/types"

import Button from "../../../../components/advanced/Button"
import Card from "../../../../components/basic/Card"

import "./PlanVariationCard.sass"

type PaymentPlanCardProps = {
  planType: PlanType
  plan: Plan
  subscriptionPeriod: SubscriptionPeriod
  className?: string
  isSelected?: boolean
  isCurrentPlan?: boolean
  onSelectPlan: (plan: PlanType) => void
}

const PlanVariationCard = ({
  planType,
  plan,
  className,
  isSelected = false,
  subscriptionPeriod,
  isCurrentPlan = false,
  onSelectPlan,
}: PaymentPlanCardProps) => {
  const { t } = useTranslation()

  const currency = useCurrencyFormatter(plan.currency)
  const price =
    subscriptionPeriod === "month" ? plan.plan_price : plan.plan_price / 12
  const cn = classNames("PaymentPlanCard", className, {
    "is-selected": isSelected,
  })
  const planTypeObj = getPlanTypeObject(planType)

  const handlePlanSelect = () => onSelectPlan(planType)

  return (
    <div className={cn}>
      {planTypeObj && (
        <Card>
          <div>
            <div className="PaymentPlanCard__title">{t(planTypeObj?.name)}</div>
            {isCurrentPlan ? (
              <div className="PaymentPlanCard__current">
                {t("desktop.components.plan_variation_card.current_plan")}
              </div>
            ) : planTypeObj.isRecommended ? (
              <div className="PaymentPlanCard__recommended">
                {t("desktop.components.plan_variation_card.most_popular")}
              </div>
            ) : (
              <div className="PaymentPlanCard__current" />
            )}
            <div className="PaymentPlanCard__description">
              {t(planTypeObj.description)}
            </div>
            {planTypeObj.featuresCount > 0 ? (
              <ul className="PaymentPlanCard__features">
                {createArray(planTypeObj.featuresCount).map((i) => (
                  <li key={i}>
                    {t(`${planTypeObj.features}.${i}` as ParseKeys)}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
          <div className="PaymentPlanCard__price-wrapper">
            <div className="PaymentPlanCard__price">
              {currency.format(price)}
            </div>
            <div className="PaymentPlanCard__period">
              month / {plan.unit_type}
            </div>
            {subscriptionPeriod === "year" && (
              <div className="PaymentPlanCard__one-time-price">
                {t("desktop.components.plan_variation_card.one_time_charge", {
                  price: currency.format(plan.plan_price),
                  unit: plan.unit_type,
                })}
              </div>
            )}
            <Button
              onClick={handlePlanSelect}
              variant="primary"
              isDisabled={isCurrentPlan}
            >
              {t(
                "desktop.components.plan_variation_card.get_plan_type_button",
                {
                  planType: t(planTypeObj.name),
                },
              )}
            </Button>
          </div>
        </Card>
      )}
    </div>
  )
}

export default PlanVariationCard
