import { useState } from "react"

import { Value } from "slate"

import "../ContentGridSection.sass"
import TextEditor from "../ContentTypes/TextEditor/TextEditor"

import {
  GalleryContent,
  SectionContent,
  TextContent,
} from "../../../../../redux/api/customContent/types"

import { ReactComponent as PencilSVG } from "../../../../../assets/images/icons/Pencil.svg"
import { ReactComponent as TrashSVG } from "../../../../../assets/images/icons/Trash.svg"

import "./TextContentPreview.sass"

type TextContentPreviewProps = {
  index: number
  sectionContent: SectionContent
  onDeleteSectionContent: (index: number) => void
  onUpdateSectionContent: () => void
}

const TextContentPreview = ({
  index,
  sectionContent,
  onDeleteSectionContent,
  onUpdateSectionContent,
}: TextContentPreviewProps) => {
  const { text } = sectionContent as TextContent

  return (
    <div
      className="ContentGridSection TextContentPreview"
      onClick={onUpdateSectionContent}
    >
      <div className="ContentSectionActions">
        <button type="button" className="UpdateSectionContent">
          <PencilSVG />
        </button>
        <button
          type="button"
          className="DeleteSectionContent"
          onClick={(e) => {
            e.stopPropagation()
            onDeleteSectionContent(index)
          }}
        >
          <TrashSVG />
        </button>
      </div>
      <TextEditor readOnly value={Value.fromJSON(text)} />
    </div>
  )
}

export default TextContentPreview
