import React, { useEffect } from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "../../../../modals/modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchCreditCardUrlQuery } from "../../../../redux/api/billing/creditCard"

import Button from "../../../../components/advanced/Button"
import Loader from "../../../../components/basic/Loader"

import { ReactComponent as CrossSVG } from "../../../../assets/images/icons/Cross.svg"

import "./CreditCardModal.sass"

type CreditCardModalProps = {
  onSuccess: () => void
  onCancel: () => void
}

const CreditCardForm = ({ onSuccess, onCancel }: CreditCardModalProps) => {
  const { closeModal } = useModals()
  const [size, setSize] = React.useState({ width: 200, height: 200 })

  const { data } = useFetchCreditCardUrlQuery()

  const handleOnload = (event: any) => {
    const { width, height } = event
    setSize({ width, height })
  }

  const handleResize = (event: {
    height: number
    width: number
    resize: "resize"
  }) => {
    const { width, height } = event
    setSize({ width, height })
  }

  const handleCancel = () => onCancel()

  const handleSuccess = () => onSuccess()

  const handleMessage = (msg: MessageEvent) => {
    const { data } = msg
    try {
      if (typeof data === "string" && data.startsWith("{")) {
        const msg = JSON.parse(data)
        if (msg.onload === "ready") {
          handleOnload(msg)
        } else if (msg.resize === "resize") {
          handleResize(msg)
        } else if (msg.cancel) {
          handleCancel()
        } else if (msg.success) {
          handleSuccess()
        }
      }
    } catch {}
  }

  useEffect(() => {
    window.addEventListener("message", handleMessage, false)
    return () => window.removeEventListener("message", handleMessage)
  }, [])
  const { origin } = window.location

  const iframeSrc = `${data?.url}#${origin}`
  const iframeStyle = {
    height: `${size.height + 13}px`,
    border: 0,
    width: `32rem`,
  }

  return (
    <div className="CreditCardForm">
      <Button className="CreditCardForm__close" onClick={closeModal}>
        <CrossSVG />
      </Button>
      {data ? (
        <iframe
          src={iframeSrc}
          style={iframeStyle}
          title="credit-card-iframe"
        />
      ) : (
        <Loader />
      )}
    </div>
  )
}

/**
 *  CreditCardModal
 */
const CreditCardModal = ({
  open,
  ...props
}: CreditCardModalProps & { open: boolean }) => {
  const { closeModal } = useModals()
  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <CreditCardForm {...props} />
    </ReactModal>
  )
}
export default CreditCardModal
