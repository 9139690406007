import React, { MouseEvent, useMemo, useState } from "react"

import { Trans, useTranslation } from "react-i18next"
import { Link, useHistory, useLocation } from "react-router-dom"

import { queryBuilder } from "../../api/queryBuilder"
import { PERMISSIONS } from "../../constants"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import { useCheckForPermission } from "../../hooks/useCheckForPermission"
import FloorModal from "../../modals/FloorModal"
import ShareFloorAccessActivityModal from "../../modals/ShareFloorAccessActivityModal"
import ShareFloorModal from "../../modals/ShareFloorModal"
import { useModals } from "@mattjennings/react-modal-stack"
import { captureMessage } from "@sentry/react"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import { useFetchFloorsQuery } from "../../redux/api/floors"
import { BuildingResponse } from "../../redux/buildings/types"
import { FloorResponse } from "../../redux/floors/types"
import { useAppSelector } from "../../redux/reducers"

import Button from "../../components/advanced/Button"
import Card from "../../components/basic/Card"
import Divider from "../../components/basic/Divider"
import Loader from "../../components/basic/Loader"
import ExampleFloorPlanLink from "../../components/ExampleFloorPlanLink"
import FileUploadAction from "../../components/FileUploadAction"
import BuildingFilter from "../../components/Filter/BuildingFilter"
import Filters from "../../components/Filter/Filters"
import FilterSpace from "../../components/Filter/FilterSpace"
import FloorFilter from "../../components/Filter/FloorFilter"
import { FilterSpecialValues } from "../../components/Filter/types"
import Heading from "../../components/Heading"
import Intro from "../../components/Intro"
import Map from "../../components/Map"
import NoDataFound from "../../components/NoDataFound"
import OccupancyInfo from "../../components/OccupancyInfo"
import Space from "../../components/Space"
import View from "../../components/View"

import { ReactComponent as AspectRatioSVG } from "../../assets/images/icons/AspectRatio.svg"
import { ReactComponent as AutoRenewSVG } from "../../assets/images/icons/AutoRenew.svg"
import { ReactComponent as ChairSVG } from "../../assets/images/icons/Chair.svg"
import { ReactComponent as MapSVG } from "../../assets/images/icons/Map.svg"
import { ReactComponent as PlusCircleSVG } from "../../assets/images/icons/PlusCircle.svg"
import { ReactComponent as UploadSVG } from "../../assets/images/icons/Upload.svg"

import "./FloorPlans.sass"

const BRANDED_FLOOR_PLAN_URL =
  "https://form.asana.com/?k=z7aSyWx5f3PYjljMMxWXKQ&d=140918963538"

const isFloorPlanDesignCTADisabled =
  process.env.REACT_APP_FEATURE_DISABLE_BRANDED_FLOOR_PLAN

const SettingsFloorPlans = () => {
  const { openModal } = useModals()

  const history = useHistory()
  const { search } = useLocation()
  const { t } = useTranslation()

  const query = useMemo(() => new URLSearchParams(search), [search])

  const {
    data: { results: buildings = [] } = {},
    isSuccess: areBuildingsLoaded,
  } = useFetchBuildingsQuery()

  const { isLoading: areFilesLoading } = useAppSelector((state) => state.files)

  const canAddFloor =
    useCheckForPermission(PERMISSIONS.floorPlans.canAddFloor) &&
    buildings.length > 0
  const canEditFloor = useCheckForPermission(
    PERMISSIONS.floorPlans.canChangeFloor,
  )
  const canDeleteFloor = useCheckForPermission(
    PERMISSIONS.floorPlans.canDeleteFloor,
  )

  const isShareableEnabled: boolean = useCheckForFeatureFlag(
    "shareable.isEnabled",
  )

  // Filters
  const [buildingFilter, setBuildingFilter] = useState<string>(
    query.get("building") || FilterSpecialValues.EMPTY,
  )

  const [floorFilter, setFloorFilter] = useState<string>(
    query.get("floor") || FilterSpecialValues.EMPTY,
  )

  const building = buildings.find(
    (bd: BuildingResponse) => bd.id === buildingFilter,
  )

  const {
    data: { results: floors = [] } = {},
    isFetching: areFloorsLoading,
    isSuccess: areFloorsLoaded,
  } = useFetchFloorsQuery(
    {
      stats: true,
      building: buildingFilter,
    },
    {
      skip: !buildingFilter,
    },
  )

  const getFloor = () =>
    floors.find((fl: FloorResponse) => fl.id === floorFilter) ?? null

  async function handleNewFloorClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault()

    openModal(FloorModal, { floor: null, buildingId: buildingFilter })
  }

  async function handleEditFloorClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault()

    const floor = getFloor()

    openModal(FloorModal, { floor, buildingId: buildingFilter })
  }

  async function handleShareClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault()

    const floor = getFloor()

    openModal(ShareFloorModal, {
      floorId: floor!.id,
      onAccessActivityClick: openShareAccessActivity,
    })
  }
  const openShareAccessActivity = (shareableId: string) => {
    openModal(ShareFloorAccessActivityModal, {
      shareableId,
    })
  }

  const floor = getFloor()

  const areLocationsLoaded: boolean = areBuildingsLoaded && areFloorsLoaded
  const isInChangeFloorState = building && floor && floor.image
  const hasSharingEnabled = Boolean(floor?.shareable?.token)
  const isShareButtonShown =
    canEditFloor && floor && floor.image && isShareableEnabled

  if (buildings.length === 0 || (floors.length === 0 && areLocationsLoaded)) {
    const msg0 = `Building: ${buildings.length === 0 ? "missing" : "present"}`
    const msg1 = `Floor: ${floors.length === 0 ? "missing" : "present"}`
    captureMessage(`WARNING: Settings / Floorplan - ${msg0}. ${msg1}.`)
  }

  return (
    <View className="SettingsFloorPlans">
      <Heading>{t("desktop.settings.floor_plans.title")}</Heading>
      <Intro>{t("desktop.settings.floor_plans.subtitle")}</Intro>

      <Space size={0.75} />

      <Filters>
        <BuildingFilter value={buildingFilter} onChange={setBuildingFilter} />

        <FloorFilter
          value={floorFilter}
          onChange={setFloorFilter}
          buildingId={buildingFilter}
        />

        <FilterSpace />

        {floor && canEditFloor && (
          <Button
            onClick={handleEditFloorClick}
            variant="secondary-white"
            isSmall
          >
            {t("desktop.settings.floor_plans.buttons.edit_floor")}
          </Button>
        )}

        {canAddFloor && (
          <Button onClick={handleNewFloorClick} isSmall>
            {t("desktop.settings.floor_plans.buttons.new_floor")}
          </Button>
        )}

        {isShareButtonShown && (
          <Button
            onClick={handleShareClick}
            isSmall
            variant={hasSharingEnabled ? "secondary-white" : "primary"}
          >
            {hasSharingEnabled
              ? t("desktop.settings.floor_plans.buttons.edit_share_floor")
              : t("desktop.settings.floor_plans.buttons.share_floor")}
          </Button>
        )}
      </Filters>
      <div className="floor">
        <div className="floor-wrapper">
          <Space size={0.75} />

          {(!areBuildingsLoaded || areFloorsLoading || areFilesLoading) && (
            <Loader className="loader" />
          )}

          {areBuildingsLoaded && building && floor && floor.image ? (
            <Card isFloorPlan className="floor-plan">
              <Map map={floor} isDisabled />
            </Card>
          ) : areBuildingsLoaded && buildings.length === 0 ? (
            <NoDataFound className="floor-plan building-missing">
              <div>
                <div>
                  {t("desktop.settings.floor_plans.no_data.no_buildings")}
                </div>
                <div>
                  <Trans i18nKey="desktop.settings.floor_plans.actions.add_building">
                    <Link to="buildings">Add a new building</Link>
                  </Trans>
                </div>
              </div>
            </NoDataFound>
          ) : (
            <>
              {areFloorsLoaded && floors.length === 0 ? (
                <NoDataFound warning className="floor-plan floor-missing">
                  <div>
                    {t("desktop.settings.floor_plans.no_data.no_floors")}
                  </div>
                  <Trans i18nKey="desktop.settings.floor_plans.actions.add_floor">
                    <a href="#add-floor" onClick={handleNewFloorClick}>
                      new floor
                    </a>
                  </Trans>
                </NoDataFound>
              ) : (
                areFloorsLoaded &&
                floor && (
                  <>
                    <NoDataFound className="floor-plan floor-plan-missing">
                      <div>
                        <div>
                          <MapSVG />
                          <div>
                            {t(
                              "desktop.settings.floor_plans.labels.upload_floor_plan",
                            )}
                          </div>
                        </div>
                      </div>
                    </NoDataFound>
                    <NoDataFound warning>
                      <div>
                        {t(
                          "desktop.settings.floor_plans.no_data.no_floor_plan",
                        )}
                      </div>
                      <div>
                        <Trans i18nKey="desktop.settings.floor_plans.actions.use_example_floor_plan">
                          <ExampleFloorPlanLink
                            buildingId={buildingFilter}
                            floor={floor}
                          />
                        </Trans>
                      </div>
                    </NoDataFound>
                  </>
                )
              )}
            </>
          )}
        </div>

        {floor ? (
          <div className="panel">
            <div className="panel-head">
              <h4 className="panel-head-title">
                {isInChangeFloorState
                  ? t("desktop.settings.floor_plans.labels.add_floor_plan")
                  : t("desktop.settings.floor_plans.labels.change_floor_plan")}
              </h4>
            </div>

            <div className="panel-body">
              <div className="options">
                <div className="option">
                  <FileUploadAction
                    buildingId={buildingFilter}
                    floor={floor}
                    hasFloorPlan={!!floor.image}
                    buttonProps={{
                      variant: "link",
                      icon: isInChangeFloorState ? (
                        <AutoRenewSVG />
                      ) : (
                        <UploadSVG />
                      ),
                    }}
                  />

                  {!isInChangeFloorState && (
                    <>
                      <div className="helper-info">
                        {t(
                          "desktop.settings.floor_plans.tips.supported_image_formats",
                        )}
                      </div>

                      <Divider />
                    </>
                  )}
                </div>

                {isInChangeFloorState && (
                  <div className="option info">
                    <AspectRatioSVG className="InfoIcon" />
                    <span>
                      {floor.width} x {floor.height}{" "}
                      {t("desktop.settings.floor_plans.labels.image")}
                    </span>
                  </div>
                )}
              </div>

              {isInChangeFloorState && (
                <div className="options">
                  <Divider />

                  <div className="options">
                    <div className="option info">
                      {areFloorsLoading ? (
                        <Loader size="small" />
                      ) : (
                        <>
                          <ChairSVG className="InfoIcon" />
                          <span>
                            {floor.desks_count ?? 0}{" "}
                            {t("desktop.settings.floor_plans.labels.desks")}
                          </span>
                        </>
                      )}
                    </div>

                    <div className="option action">
                      <Button
                        variant="link"
                        onClick={() => {
                          history.push({
                            pathname: "/settings/desks/layout",
                            search: queryBuilder({
                              building: buildingFilter,
                              floor: floorFilter,
                            }),
                          })
                        }}
                        icon={<PlusCircleSVG />}
                      >
                        {t("desktop.settings.floor_plans.buttons.add_desks")}
                      </Button>
                    </div>

                    {(floor.capacity_limit || floor.capacity_limit === 0) && (
                      <div className="option info">
                        <OccupancyInfo type="Floor" floor={floor} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {!isFloorPlanDesignCTADisabled && canDeleteFloor && (
              <div className="panel-foot">
                <div className="floor-plan-cta">
                  <Trans i18nKey="desktop.settings.floor_plans.tips.request_floor_plan_design">
                    <a
                      href={BRANDED_FLOOR_PLAN_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Request a branded floor plan design
                    </a>
                  </Trans>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="panel hidden" />
        )}
      </div>
    </View>
  )
}

export default SettingsFloorPlans
