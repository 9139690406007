import { useCallback } from "react"

import dayjs from "dayjs"
import { saveAs } from "file-saver"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../hooks/useToast"
import { DatePicker } from "../basic/DatePicker"
import Field from "../Field"
import ModalForm from "./ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import { exportScreeningConfig } from "../../redux/screenings/screeningsSlice"
import { ScreeningConfigExportFetchOptions } from "../../redux/screenings/types"
import { useActions } from "../../redux/utils"

type Props = {
  export_for: string
}

type FormValues = {
  start: Date | string
  end: Date | string
  export_for: string
}

const HealthScreeningExportForm = ({ export_for }: Props) => {
  const { t } = useTranslation()
  const { closeModal } = useModals()
  const { errorToast } = useToast()

  const actions = useActions({
    exportCSV: (params: ScreeningConfigExportFetchOptions) =>
      exportScreeningConfig(params),
  })

  const methods = useForm<FormValues>({
    defaultValues: {
      start: dayjs().toDate(),
      end: dayjs().toDate(),
      export_for,
    },
  })

  const { control } = methods

  const onSubmit = useCallback(
    async ({ start, end }: FormValues) => {
      const response = await actions.exportCSV({
        start: dayjs(start).startOf("day").toISOString(),
        end: dayjs(end).endOf("day").toISOString(),
        export_for,
      })

      if (exportScreeningConfig.fulfilled.match(response)) {
        const blob = new Blob([response.payload], {
          type: "text/plain;charset=utf-8",
        })

        const startStr = dayjs(start).format("YYYY-MM-DD")
        const endStr = dayjs(end).format("YYYY-MM-DD")

        saveAs(
          blob,
          `${t(
            "desktop.manage.visitors.health_screening.form.export.screenings",
          )} ${startStr} - ${endStr}.csv`,
        )

        closeModal()
      } else {
        errorToast(response.error.message)
      }
    },
    [actions, t, closeModal, errorToast, export_for],
  )

  return (
    <FormProvider {...methods}>
      <ModalForm
        className="ScreeningConfigExportForm"
        updateMode={true}
        title={t(
          "desktop.manage.visitors.health_screening.form.export.export_screening_config_title",
        )}
        onUpdate={onSubmit}
      >
        <Field
          control={control}
          name="start"
          label={t("desktop.manage.visitors.export.from")}
          className="field-width-50"
        >
          {(props) => (
            <DatePicker {...props} showMonthDropdown showYearDropdown />
          )}
        </Field>
        <Field
          control={control}
          name="end"
          label={t("desktop.manage.visitors.export.to")}
          className="field-width-50"
        >
          {(props) => (
            <DatePicker {...props} showMonthDropdown showYearDropdown />
          )}
        </Field>
      </ModalForm>
    </FormProvider>
  )
}

export default HealthScreeningExportForm
