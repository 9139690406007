import React, { useCallback, useEffect, useState } from "react"

import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useBackendPagination } from "../../../../hooks/useBackendPagination"
import { parseQueryWithDefault } from "../../../../utils"
import { ENTRIES_PER_PAGE } from "../../../Manage/constants"
import InvoiceTable from "./InvoiceTable"

import { useFetchInvoicesQuery } from "../../../../redux/api/billing/invoices"

import Loader from "../../../../components/basic/Loader"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Pagination from "../../../../components/Pagination"
import View from "../../../../components/View"

import "./styles.sass"

const { stringify } = queryString

const INVOICES_PATHNAME = "/settings/billing/invoices"

type Filter = {
  page: number
}

const InvoicesEmptyTableRows = () => {
  const { t } = useTranslation()
  return (
    <div className="InvoicesEmptyTableRows">
      {t("desktop.settings.billing.invoices.no_invoices")}
    </div>
  )
}

const Invoices = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { search } = history.location

  const defaultFilter: Filter = {
    page: Number(
      parseQueryWithDefault(search, {
        page: 1,
      }).page,
    ),
  }
  const [page, setCurrentPage] = useState(defaultFilter.page)

  const {
    data: { count = 0, offset = 0, results: invoices = [] } = {},
    isSuccess: isLoaded,
  } = useFetchInvoicesQuery({
    offset: (page - 1) * ENTRIES_PER_PAGE,
  })
  const { from, to, hasNext, hasPrevious, paginationLinks } =
    useBackendPagination({
      offset,
      totalNumberOfItems: count,
      entriesPerPage: ENTRIES_PER_PAGE,
      maxLinks: 7,
      maxTrailingLinks: 2,
    })

  const setPage = useCallback(
    (nextPage: number) => {
      history.push(`${INVOICES_PATHNAME}?${stringify({ page: nextPage })}`)
      setCurrentPage(nextPage)
    },
    [history],
  )

  // it will navigate to the first page when there is no results on higher pages
  useEffect(() => {
    if (isLoaded && count > 0 && invoices.length === 0 && page > 1) {
      setPage(1)
    }
  }, [count, isLoaded, page, invoices.length, setPage])

  return (
    <View className="Invoices">
      <Breadcrumbs
        depth={2}
        includeParamsAsPath
        values={[
          t("desktop.settings.billing.title"),
          t("desktop.settings.billing.invoices.title"),
        ]}
      />
      <Intro>{t("desktop.settings.billing.invoices.intro")}</Intro>
      {!isLoaded ? (
        <Loader />
      ) : (
        <InvoiceTable
          invoices={invoices}
          emptyTableCell={<InvoicesEmptyTableRows />}
          pagination={
            <Pagination
              links={paginationLinks}
              setPage={setPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              from={from}
              to={to}
              total={count}
              items={t("desktop.settings.billing.invoices.invoices", {
                count,
              })}
            />
          }
        />
      )}
    </View>
  )
}

export default Invoices
