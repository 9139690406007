import { useEffect, useState } from "react"

import classNames from "classnames"

import { API_ROOT, get } from "../../../api"
import { colorFromString, isMobile } from "../../../utils"

import { UserProfile } from "../../../redux/api/users/types"
import { useAppSelector } from "../../../redux/reducers"
import { getAuth } from "../../../redux/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"

import { ReactComponent as AccountCircleBoldSVG } from "../../../assets/images/icons/AccountCircleBold.svg"
import { ReactComponent as EllipseSVG } from "../../../assets/images/icons/Ellipse.svg"

import "./style.sass"

export type AvatarProps = {
  user: {
    email?: string | null
    groups?: string[]
    profile?: UserProfile | null
  }
  size?: "small" | "medium" | "large" | "extra-large"
  variant?: "square" | "circle"
  className?: string
  hasDefaultAvatar?: boolean
}

const Avatar = ({
  user,
  size = "small",
  variant = "circle",
  className,
  hasDefaultAvatar,
}: AvatarProps) => {
  const { access_token } = useAppSelector(getAuth)

  const [src, setSrc] = useState<string | null>(null)

  const [loadingState, setLoadingState] = useState<
    "isLoading" | "isLoaded" | "hasError"
  >(isMobile() ? "isLoading" : "isLoaded")

  const { url, name } = user.profile?.picture ?? {}

  const userStyle: Record<string, string> = {
    "--user-color": colorFromString(user?.email ?? "default"),
  }

  const cn = classNames(
    "Avatar",
    { Default: hasDefaultAvatar },
    className,
    size,
    variant,
  )

  useEffect(() => {
    const fetchUserAvatar = async (url: string) => {
      setLoadingState("isLoading")
      const response: Response = await get(url, {}, access_token)
      if (response.ok) {
        return await response.blob()
      }

      throw new Error("Failed to fetch user avatar")
    }

    if (isMobile() && url) {
      fetchUserAvatar(`${API_ROOT}${url}`)
        .then((src) => {
          setSrc(URL.createObjectURL(src))
          setLoadingState("isLoaded")
        })
        .catch(() => {
          setLoadingState("hasError")
        })
    }
  }, [access_token, url])

  const DefaultAvatar = () => (
    <div className={cn}>
      <AccountCircleBoldSVG />
    </div>
  )

  // This fallback is needed if the user does not have an authorization token in
  // the Joan Portal app, for mobile apps (native and mobile web variations).
  // Otherwise, users receive a broken profile picture as a result of a 401-not
  // authorized error.
  if (url) {
    if (loadingState === "isLoading" || loadingState === "hasError") {
      return <DefaultAvatar />
    }
    if (src) {
      return (
        <div className={cn}>
          <img src={src} alt={name} />
        </div>
      )
    }

    return (
      <div className={cn}>
        <img src={`${API_ROOT}${url}`} alt={name} />
      </div>
    )
  }

  if (hasDefaultAvatar) {
    return <DefaultAvatar />
  }

  if (user?.groups?.length && isOfficeManager(user)) {
    return <span className={cn}>👑</span>
  }

  if (user?.groups?.length && isPortalAdmin(user)) {
    return <span className={cn}>⛑</span>
  }

  return <EllipseSVG className={cn} style={userStyle} />
}

export default Avatar
