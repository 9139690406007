import { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { FETCH_WITH_NO_LIMIT } from "../../constants"
import { MultiOptionType } from "../../types/sharedTypes"
import DropdownMulti from "../basic/DropdownMulti"
import { FilterSpecialValues } from "./types"

import { AmenityResponse } from "../../redux/amenities/types"
import { useFetchAmenitiesQuery } from "../../redux/api/amenities"

type MultiAmenitiesFilterProps = {
  preSelectedValues: string[]
  onChange: (v: string[]) => void
}

export default function MultiAmenitiesFilter({
  preSelectedValues = [],
  onChange,
}: MultiAmenitiesFilterProps) {
  /**
   * State
   */
  const [selected, setSelected] = useState<string[]>(preSelectedValues)

  /**
   * Hooks
   */

  const { data: { results: amenities = [] } = {}, isLoading } =
    useFetchAmenitiesQuery({
      limit: FETCH_WITH_NO_LIMIT,
    })

  const { t } = useTranslation()

  /**
   * Computed values
   */
  const amenityOptions: MultiOptionType<string>[] = amenities.map(
    (a: AmenityResponse) => ({
      value: a.id,
      label: a.name,
      isSingleSelect: false,
    }),
  )
  const count = amenities.length
  amenityOptions.unshift({
    label: (
      <>
        All amenities <span className="count">&middot; {count}</span>
      </>
    ),
    value: FilterSpecialValues.ALL,
    isSingleSelect: true,
  })

  /**
   * Handlers
   */
  const handleOnChange = (filterValues: string[]) => {
    if (filterValues.length === 0) {
      filterValues = [FilterSpecialValues.ALL]
    }
    setSelected(filterValues)
    onChange(filterValues)
    analyticsEvent(SupportedEvents.FILTER_BY_AMENITY, {
      ids: filterValues,
    })
  }

  /**
   * Effects
   */

  useEffect(() => {
    // Handle if amenity deletes or if not selected
    if (!isLoading) {
      if (preSelectedValues.length === 0 || amenities.length === 0) {
        setSelected([FilterSpecialValues.ALL])
        return
      }
      const values = preSelectedValues.flatMap((s) => {
        const amenity = amenities.find((a) => a.id === s)
        return amenity ? amenity.id : []
      })
      setSelected(values.length > 0 ? values : [FilterSpecialValues.ALL])
    }
  }, [amenities, isLoading])

  /**
   * Render
   */
  return (
    <DropdownMulti
      className="amenity-filter"
      options={amenityOptions}
      values={selected}
      onChange={handleOnChange}
      loading={isLoading}
      optionsName={t("general.amenities")}
    />
  )
}
