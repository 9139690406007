import React, { useCallback, useContext, useMemo } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { visitorRegistrationVisitorsURL } from "../../api"
import {
  FlowContext,
  Step,
} from "../../providers/Tablet/RegistrationFlowProvider"
import { isNative } from "../../utils"

import { appError } from "../../redux/app/appSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectTablet } from "../../redux/tablet/selectors"
import { SignInFieldType } from "../../redux/tablet_settings/types"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import {
  RegistrationCreateRequest,
  RegistrationUpdateRequest,
} from "../../redux/visitor_registration/types"
import {
  createRegistration,
  updateRegistration,
} from "../../redux/visitor_registration/visitorRegistrationSlice"
import { VisitorSearchResponse } from "../../redux/visitors/types"

import AsyncAutocomplete from "../../components/advanced/AsyncAutocomplete"
import Button from "../../components/advanced/Button"
import Field from "../../components/Field"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorForm from "../../components/Visitors/VisitorForm"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"

import { ReactComponent as QrCodeSVG } from "../../assets/images/icons/QrCode.svg"

import "./Name.sass"

type FormValues = {
  name: string
}

const Name = () => {
  const { t } = useTranslation()

  const { nextStep, previousStep, goToStep } = useContext(FlowContext)

  const { buildingId, id: tabletId } = useAppSelector(selectTablet)
  const { data, branding, registration, isSubmitting } = useAppSelector(
    selectVisitorRegistration,
  )

  const actions = useActions({
    appError: (message: string) => appError(message),
    createRegistration: (
      buildingId: string,
      tabletId: string,
      body: RegistrationCreateRequest,
    ) => createRegistration({ buildingId, tabletId, ...body }),
    updateRegistration: (
      buildingId: string,
      tabletId: string,
      id: string,
      body: RegistrationUpdateRequest,
    ) => updateRegistration({ buildingId, tabletId, id, ...body }),
  })

  const exName = useMemo(() => {
    const nameField = data?.signin_fields.find(
      (f) => f.type === SignInFieldType.NAME,
    )

    if (nameField && data?.fields_values) {
      const field = data.fields_values.find((f) => f.id === nameField.id)

      if (field && typeof field.value === "string") {
        return field.value
      }
    }
  }, [data])

  const methods = useForm<FormValues>({
    defaultValues: {
      name: registration?.full_name ?? exName ?? "",
    },
  })
  const { control } = methods

  const onSubmit = useCallback(
    async ({ name }: FormValues) => {
      if (buildingId && tabletId) {
        const response = await actions.createRegistration(
          buildingId,
          tabletId,
          {
            full_name: name,
          },
        )

        if (createRegistration.fulfilled.match(response)) {
          const updateResponse = await actions.updateRegistration(
            buildingId,
            tabletId,
            response.payload?.id,
            {},
          )

          if (updateRegistration.fulfilled.match(updateResponse)) {
            nextStep(response.payload.id)
          } else {
            actions.appError(
              updateResponse.error.message ?? t("tablet.general_error"),
            )
          }
        } else {
          actions.appError(response.error.message ?? t("tablet.general_error"))
        }
      }
    },
    [t, actions, nextStep, buildingId, tabletId],
  )

  const handleScanQr = async () => goToStep(Step.SCAN)

  const handleGoBack = () => previousStep()

  return (
    <VisitorView className="Name">
      <FormProvider {...methods}>
        <VisitorForm onSubmit={onSubmit}>
          <VisitorHeader />
          <VisitorContent>
            <div className="fields">
              {!isNative() && (
                <div className="title">
                  {t("tablet.visitors.screens.start.welcome")}
                  <br />
                  {branding?.company_name}
                </div>
              )}
              {isNative() && (
                <div className="info">
                  {t("tablet.visitors.screens.name.enter_full_name")}
                </div>
              )}
              <div>
                <Field
                  control={control}
                  name="name"
                  required={true}
                  label={t("tablet.visitors.form.your_full_name")}
                  rules={{
                    required: t("tablet.visitors.form.your_full_name_required"),
                  }}
                >
                  {(props) => (
                    <AsyncAutocomplete
                      {...props}
                      urlGenerator={(fetchOptions) =>
                        visitorRegistrationVisitorsURL(
                          buildingId!,
                          tabletId!,
                          fetchOptions,
                        )
                      }
                      getOptionLabel={(visitor: VisitorSearchResponse) =>
                        visitor.full_name
                      }
                      getOptionValue={(visitor: VisitorSearchResponse) =>
                        visitor.full_name
                      }
                    />
                  )}
                </Field>
              </div>
              {isNative() && (
                <div>
                  <Button isSubmit variant="submit" isLoading={isSubmitting}>
                    {t("tablet.visitors.continue")}
                  </Button>
                </div>
              )}
            </div>
          </VisitorContent>
          <VisitorFooter>
            {!isNative() && (
              <div>
                <Button isSubmit variant="submit" isLoading={isSubmitting}>
                  {t("tablet.visitors.continue")}
                </Button>
              </div>
            )}
            {isNative() && (
              <>
                <div>
                  <Button
                    variant="secondary"
                    icon={<QrCodeSVG />}
                    onClick={handleScanQr}
                    isDisabled={isSubmitting}
                  >
                    {t("tablet.visitors.scan_qr_code")}
                  </Button>
                </div>
                <div>
                  <Button
                    variant="link"
                    onClick={handleGoBack}
                    isDisabled={isSubmitting}
                  >
                    {t("general.redirection.go_back")}
                  </Button>
                </div>
              </>
            )}
          </VisitorFooter>
        </VisitorForm>
      </FormProvider>
    </VisitorView>
  )
}

export default Name
