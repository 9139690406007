import React, {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react"

import classNames from "classnames"

import { ReactComponent as ButtonLoaderSVG } from "../../../assets/images/icons/ButtonLoader.svg"

import "./style.sass"

type ButtonVariant =
  | "primary"
  | "secondary"
  | "secondary-white"
  | "gray"
  | "danger"
  | "danger-pop"
  | "link"
  | "submit"
  | "mobile-action"

type AnchorOrButton<C, K> = C | K extends string ? "a" : "button"

export type BasicButtonProps<C, K> = {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  variant?: ButtonVariant
  children?: ReactNode
  className?: string
  icon?: ReactNode
  isSmall?: boolean
  isSubmit?: boolean
  isDisabled?: boolean
  isVisible?: boolean
  noConfirm?: boolean
  isLoading?: boolean
} & ComponentPropsWithoutRef<AnchorOrButton<C, K>> & {
    ref?: ComponentPropsWithRef<AnchorOrButton<C, K>>["ref"]
  }

const BasicButton = <C, K>(
  {
    children = "Submit",
    onClick,
    className,
    icon,
    isSmall,
    isSubmit: _isSubmit,
    isDisabled,
    isVisible = true,
    isLoading,
    variant = "primary",
    // removed from props as DOM button does not have noConfirm prop
    noConfirm,
    ...props
  }: BasicButtonProps<C, K>,
  ref?: ComponentPropsWithRef<AnchorOrButton<C, K>>["ref"],
) => {
  if (!isVisible) return null

  const isSubmit = variant === "submit" || _isSubmit
  const buttonClass = classNames("Button", className, variant, {
    isSmall,
    isDisabled,
    isLoading,
    "has-icon": !!icon,
  })

  return (
    <button
      ref={ref as ForwardedRef<HTMLButtonElement>}
      {...(props as HTMLProps<HTMLButtonElement>)}
      onClick={onClick}
      disabled={isDisabled}
      type={isSubmit ? "submit" : "button"}
      className={buttonClass}
    >
      {icon && (
        <span className="button-icon">
          {isLoading ? (
            <ButtonLoaderSVG className="button--icon--loader" />
          ) : (
            icon
          )}
        </span>
      )}
      {!icon && isLoading && <ButtonLoaderSVG className="button-loader" />}
      <span className="label">
        {isSubmit && !children ? "Submit" : children}
      </span>
    </button>
  )
}

export default forwardRef(BasicButton) as <C, K>(
  props: PropsWithChildren<BasicButtonProps<C, K>> & {
    ref?: ComponentPropsWithRef<AnchorOrButton<C, K>>["ref"]
  },
) => ReactElement
