import React, { useCallback, useRef, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { useHistory, useLocation } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { roundTimeToInterval } from "../../dateUtils"
import { useUnlockOrientation } from "../../hooks/useUnlockOrientation"
import { useAnalyticsScreenView } from "../../providers/Mobile/FirebaseAnalyticsProvider"
import { InternalTime } from "../../types/sharedTypes"
import { RepeatPickerTypes } from "../../types/sharedTypes"
import {
  internalTimeFormat,
  roundToClosest30Minutes,
  setTimeToDayjs,
} from "../../utils"
import "swiper/css/virtual"
import { Virtual } from "swiper/modules"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import { useFetchFloorQuery } from "../../redux/api/floors"
import { selectIsPortraitOrientation } from "../../redux/app/selectors"
import { BuildingResponse } from "../../redux/buildings/types"
import { useAppSelector } from "../../redux/reducers"
import {
  ReservationResponse,
  SeatReservation,
} from "../../redux/reservations/types"
import { TimeslotResponse } from "../../redux/timeslots/types"

import { SliderTimePicker } from "../../components/advanced/SliderTimePicker"
import { FilterSpecialValues } from "../../components/Filter/types"
import { BottomNav } from "../../components/Mobile/BottomNav"
import { DateTimePicker } from "../../components/Mobile/DateTimePicker"
import { DeskPicker } from "../../components/Mobile/DeskPicker"
import { Drawer } from "../../components/Mobile/Drawer"
import {
  Filter,
  OccupancyFilterValues,
} from "../../components/Mobile/Filter/FilterSelector"
import SafeViewArea from "../../components/Mobile/SafeViewArea"
import { TopSelectors } from "../../components/Mobile/TopSelectors"

import "./Floor.sass"

type LocationState = {
  date?: string
  time?: string
  location_id?: string
  floor_id?: string
  desk_id?: string
}

const MINUTES_INTERVAL: number = 5

const Floor = () => {
  useAnalyticsScreenView("Floor")
  const history = useHistory()
  const { state } = useLocation<LocationState>()
  useUnlockOrientation()
  const preselectedTime =
    state && state.time
      ? roundTimeToInterval(
          dayjs(state.time, internalTimeFormat()),
          "minute",
          30,
        )
      : null

  const [dateTimePicker, setDateTimePicker] = useState(false)
  const [timeFrom, setTimeFrom] = useState(
    preselectedTime
      ? preselectedTime.format(internalTimeFormat())
      : roundToClosest30Minutes(dayjs()).format(internalTimeFormat()),
  )

  const [desk, setDesk] = useState<SeatReservation | null>(null)
  const [unavailableSlots, setUnavailableSlots] = useState<
    Array<Partial<TimeslotResponse>>
  >([])
  const [filter, setFilter] = useState<Filter>({
    occupancy: OccupancyFilterValues.ALL,
    department: FilterSpecialValues.ALL,
    amenities: [FilterSpecialValues.ALL],
  })

  const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()
  const { entries: reservations } = useAppSelector(
    (state) => state.reservations,
  )

  const isPortrait = useAppSelector(selectIsPortraitOrientation)

  // Filters
  const { date, location_id, floor_id } = state || {}

  const [floorFilter, setFloorFilter] = useState<string>(
    floor_id || FilterSpecialValues.EMPTY,
  )

  const [dayFilter, setDayFilter] = useState<string>(
    date ? date : dayjs().startOf("day").toISOString(),
  )

  /*
		we are using ref as we don't want to re-render the component
		when the building changes only when the floor changes
	*/
  const buildingRef = useRef<string>(location_id || FilterSpecialValues.EMPTY)

  const { data: floor } = useFetchFloorQuery(
    { id: floorFilter, settings: false },
    {
      skip: floorFilter === FilterSpecialValues.EMPTY,
    },
  )

  /*
    Array of times with 5 minute intervals for 24 hours
    Number of time slots in hour: 60 / 5 = 12
    Number of time slots in 24 hours: 12 * 24 = 288
  */
  const times: string[] = Array.from({ length: 288 }, (_, i) =>
    dayjs()
      .startOf("day")
      .add(i * MINUTES_INTERVAL, "minute")
      .format(internalTimeFormat()),
  )

  const handleSliderTimePick = useCallback(
    (index: number) => {
      const pickedTime = times.at(index) as InternalTime

      if (pickedTime) {
        const changedDate = setTimeToDayjs(
          dayjs(dayFilter),
          pickedTime,
        ).toISOString()

        if (changedDate) {
          setTimeFrom(pickedTime)
          setDayFilter(changedDate)
          analyticsEvent(SupportedEvents.DESK_MAP_TIMESLIDER)
        }
      }
    },
    [dayFilter, times],
  )

  const handleDeskPick = (desk: SeatReservation | null) => {
    if (desk !== null) {
      setDateTimePicker(true)
      setDesk(desk)

      const filteredReservations = reservations.filter(
        (res: ReservationResponse) =>
          res.seat?.map?.location?.id === buildingRef.current &&
          res.seat?.map?.floor_id === floorFilter &&
          res.seat.id === desk.id &&
          dayjs(dayFilter).isSame(res.date, "day"),
      )

      const uS = filteredReservations.map((r: ReservationResponse) => {
        return {
          from: r.from,
          to: r.to,
        }
      })

      setUnavailableSlots(uS)
    }
  }

  const handleDateTimePick = async (
    date: Dayjs | null,
    timeslot: Partial<TimeslotResponse> | null,
    repeat: RepeatPickerTypes | null,
    repeatUntil: Dayjs | null,
  ) => {
    if (date !== null && timeslot !== null && repeat !== null) {
      const building = buildings.find(
        (b: BuildingResponse) => b.id === buildingRef.current,
      )

      history.push("/book/desk/summary", {
        date: date.toISOString(),
        timeslot: timeslot,
        building,
        floor,
        desk: desk,
        repeat,
        repeatUntil: repeatUntil?.toISOString(),
      })

      return null
    }
    setDateTimePicker(false)
  }

  return (
    <SafeViewArea className="Floor">
      <TopSelectors
        day={dayFilter}
        dayChange={setDayFilter}
        buildingFilter={buildingRef.current}
        buildingFilterChange={(building: string) => {
          buildingRef.current = building
        }}
        floorFilter={floorFilter}
        floorFilterChange={setFloorFilter}
        showFilter
        filter={filter}
        onFilterChange={setFilter}
      />
      <div className="body">
        <DeskPicker
          date={dayjs(dayFilter)}
          timeslot={{ from: timeFrom, to: timeFrom }}
          buildingId={buildingRef.current}
          floorId={floorFilter}
          deskId={state && state.desk_id}
          popupPos="high"
          onPick={handleDeskPick}
          filter={filter}
        />
      </div>
      <SliderTimePicker
        times={times}
        onPick={handleSliderTimePick}
        initialTimeIndex={times.findIndex((t) => t === timeFrom)}
        slidesPerView={20}
        minutesIntervalLabelView={30}
        grabCursor
        freeMode
        centeredSlides
        simulateTouch
        virtual
        modules={[Virtual]}
      />
      <Drawer open={dateTimePicker}>
        <DateTimePicker
          date={dayjs(dayFilter)}
          timeslot={null}
          preferredTime={timeFrom}
          unavailableSlots={unavailableSlots}
          onPick={handleDateTimePick}
        />
      </Drawer>
      {isPortrait && <BottomNav />}
    </SafeViewArea>
  )
}

export default Floor
