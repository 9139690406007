import React from "react"

import { useTranslation } from "react-i18next"

import DeskOverview from "./DeskOverview"
import OrganizationOverview from "./OrganizationOverview"
import RoomsOverview from "./RoomsOverview"
import VisitorOverview from "./VisitorOverview"

import { getAppParams } from "../../../redux/appParams/selectors"
import { useAppSelector } from "../../../redux/reducers"

import Breadcrumbs from "../../../components/Breadcrumbs"
import Intro from "../../../components/Intro"
import View from "../../../components/View"

import "./styles.sass"

const Overview = () => {
  const { t } = useTranslation()
  const { isAppWhitelabel } = useAppSelector(getAppParams)

  return (
    <View className="SolutionsOverview">
      <Breadcrumbs depth={1} values={[t("desktop.settings.overview.title")]} />
      <Intro isConstrained>{t("desktop.settings.overview.intro")}</Intro>
      <OrganizationOverview />
      <RoomsOverview />
      <DeskOverview />
      {!isAppWhitelabel && <VisitorOverview />}
    </View>
  )
}

export default Overview
