import React from "react"

import { useTranslation } from "react-i18next"

import CreditCardInfo from "./CreditCardInfo"
import CreditCardModal from "./CreditCardModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchPaymentsQuery } from "../../../../redux/api/billing/payments"

import Button from "../../../../components/advanced/Button"

import { ReactComponent as EditSVG } from "../../../../assets/images/icons/Pencil.svg"

import "./CreditCard.sass"

type CreditCardProps = {
  onSuccess: () => void
}

const CreditCard = ({ onSuccess }: CreditCardProps) => {
  const { t } = useTranslation()
  const { openModal, closeModal } = useModals()
  const { data: { credit_card: creditCard } = {} } = useFetchPaymentsQuery()

  const handleManageCreditCards = () => {
    openModal(CreditCardModal, {
      onSuccess: () => {
        onSuccess()
        closeModal()
      },
      onCancel: () => {
        closeModal()
      },
    })
  }

  return (
    <div className="CreditCard">
      {creditCard ? (
        <>
          <CreditCardInfo creditCard={creditCard} />
          <Button
            variant="link"
            onClick={handleManageCreditCards}
            icon={<EditSVG />}
          >
            {t(
              "desktop.settings.billing.payment_method.credit_card.buttons.manage_credit_cards",
            )}
          </Button>
        </>
      ) : (
        <div>
          <div className="CreditCard__no-card">
            {t("desktop.settings.billing.payment_method.credit_card.no_card")}
          </div>
          <Button onClick={handleManageCreditCards}>
            {t(
              "desktop.settings.billing.payment_method.credit_card.buttons.add_credit_card",
            )}
          </Button>
        </div>
      )}
    </div>
  )
}

export default CreditCard
