import React, { useCallback, useEffect } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useToast } from "../../../../hooks/useToast"
import { PROFILE_PATHS } from "../constants"

import { useUpdateEmailMutation } from "../../../../redux/api/me"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import { Input } from "../../../../components/basic/Input"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"
import PageForm from "../../../../components/Form/PageFormHook"

import "./styles.sass"

const FORM_MAPPING = {
  new: "newEmail",
} as const

type Props = {
  email?: string
}

type FormValues = {
  email: string
  newEmail: string
  confirmation: string
}

const EmailForm = ({ email }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { errorToast, infoToast } = useToast()

  const [updateEmail] = useUpdateEmailMutation()

  const methods = useForm<FormValues>({
    defaultValues: {
      email: email ?? "",
      newEmail: "",
      confirmation: "",
    },
  })
  const { setError, control, reset } = methods

  const onUpdateClick = useCallback(
    async ({ newEmail, confirmation }: FormValues) => {
      if (!email) {
        return
      }

      const response = await updateEmail({
        newEmail,
        confirmation,
      })

      if (isRejected(response)) {
        const { error } = response

        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast, FORM_MAPPING)
        }

        return
      }

      infoToast(t("desktop.settings.profile.email.form.update_success_toast"))

      history.push(PROFILE_PATHS.root)
    },
    [email, updateEmail, infoToast, t, history, setError, errorToast],
  )

  useEffect(() => {
    if (email) {
      reset({
        email,
      })
    }
  }, [email, reset])

  return (
    <FormProvider {...methods}>
      <PageForm
        className="EmailForm"
        updateMode
        onUpdate={onUpdateClick}
        backUrl={PROFILE_PATHS.root}
        submitButtonText={t(
          "desktop.settings.profile.email.form.change_email_button",
        )}
      >
        <Field
          control={control}
          name="email"
          label={t("desktop.settings.profile.email.form.current_email")}
        >
          {(props) => <Input maxLength={60} {...props} disabled />}
        </Field>

        <Field
          control={control}
          name="newEmail"
          label={t("desktop.settings.profile.email.form.new_email")}
          required
        >
          {(props) => (
            <Input
              {...props}
              maxLength={60}
              placeholder={t("desktop.settings.profile.email.form.new_email")}
            />
          )}
        </Field>

        <Field
          control={control}
          name="confirmation"
          label={t("desktop.settings.profile.email.form.repeat_new_email")}
          required
        >
          {(props) => (
            <Input
              {...props}
              maxLength={60}
              placeholder={t(
                "desktop.settings.profile.email.form.repeat_new_email_placeholder",
              )}
            />
          )}
        </Field>
      </PageForm>
    </FormProvider>
  )
}

export default EmailForm
