import React, { useMemo } from "react"

import { useTranslation } from "react-i18next"

import { FETCH_FOR_COUNT } from "../../../constants"
import { DESKS_PATHS } from "../Desks/constants"
import OverviewStep from "./OverviewStep"
import SolutionOverviewCard from "./SolutionOverviewCard"

import { useFetchDeskReservationsQuery } from "../../../redux/api/deskReservations"
import { useFetchDesksQuery } from "../../../redux/api/desks"
import { useFetchFloorsQuery } from "../../../redux/api/floors"
import { useFetchSolutionsQuery } from "../../../redux/api/solutions"

import { ReactComponent as ChairSVG } from "../../../assets/images/icons/Chair.svg"

type DeskOverviewProps = {
  isDefaultExtended?: boolean
}

const DeskOverview = ({ isDefaultExtended = false }: DeskOverviewProps) => {
  const { t } = useTranslation()

  const { data: { count: deskCount = 0 } = {}, isFetching: areDesksLoading } =
    useFetchDesksQuery(FETCH_FOR_COUNT)
  const {
    data: { count: reservationCount = 0 } = {},
    isFetching: isFetchingReservations,
  } = useFetchDeskReservationsQuery(FETCH_FOR_COUNT)
  const { data: { results: floors = [] } = {}, isFetching: areFloorsLoading } =
    useFetchFloorsQuery({ building: null })
  const { data: solutions } = useFetchSolutionsQuery()

  const hasDesks = deskCount > 0
  const hasOfficeHours = reservationCount > 0
  const hasFloorPlan = floors.some((f) => !!f?.image)
  const isAddReservationDisabled =
    !solutions?.desk?.active || !hasFloorPlan || !hasDesks

  const steps = useMemo(
    () => [
      {
        title: t(
          "desktop.settings.overview.desk.steps.upload_floor_plan.title",
        ),
        description: t(
          "desktop.settings.overview.desk.steps.upload_floor_plan.description",
        ),
        path: "/settings/floor-plans",
        completed: hasFloorPlan,
        isLoading: areFloorsLoading,
      },
      {
        title: t(
          "desktop.settings.overview.desk.steps.add_bookable_desks.title",
        ),
        description: t(
          "desktop.settings.overview.desk.steps.add_bookable_desks.description",
        ),
        path: DESKS_PATHS.layout,
        completed: hasDesks,
        isLoading: areDesksLoading,
        disabled: !hasFloorPlan,
      },
      {
        title: t(
          "desktop.settings.overview.desk.steps.create_desk_reservation.title",
        ),
        description: t(
          "desktop.settings.overview.desk.steps.create_desk_reservation.description",
        ),
        path: "/manage/people/add",
        completed: hasOfficeHours,
        isLoading: isFetchingReservations,
        disabled: isAddReservationDisabled,
      },
    ],
    [
      t,
      hasFloorPlan,
      areFloorsLoading,
      hasDesks,
      areDesksLoading,
      hasOfficeHours,
      isFetchingReservations,
      isAddReservationDisabled,
    ],
  )

  return (
    <SolutionOverviewCard
      solution="desk"
      className="DeskOnboardingOverview"
      description={t("desktop.settings.overview.desk.description")}
      icon={<ChairSVG />}
      isDefaultExtended={isDefaultExtended}
    >
      {steps.map((step) => (
        <OverviewStep
          key={step.title}
          title={step.title}
          description={step.description}
          path={step.path}
          completed={step.completed}
          isLoading={step.isLoading}
          disabled={step.disabled}
        />
      ))}
    </SolutionOverviewCard>
  )
}

export default DeskOverview
