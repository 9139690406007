import React from "react"

import { useTranslation } from "react-i18next"

import { useNavigation } from "../../../../hooks/useNavigation"
import { BILLING_PATHS } from "../constants"
import CompanyDetails from "./CompanyDetails"
import PaymentMethodInfo from "./PaymentMethodInfo"
import ResellerInfo from "./ResellerInfo"

import { useFetchPaymentsQuery } from "../../../../redux/api/billing/payments"

import Card from "../../../../components/basic/Card"
import Loader from "../../../../components/basic/Loader"

import "./PaymentDetails.sass"

const PaymentDetails = () => {
  const history = useNavigation()
  const { t } = useTranslation()

  const { data: payments, isLoading } = useFetchPaymentsQuery()
  const { billing_details } = payments ?? {}

  const handleOnEditCompanyDetailsClick = () =>
    history.push(BILLING_PATHS.overview.companyDetails)

  return (
    <>
      {!isLoading && payments ? (
        <div>
          <Card className="PaymentDetails">
            <div className="PaymentDetails__section">
              <div className="PaymentDetails__header">
                {t(
                  "desktop.settings.billing.payment_details.headers.company_details",
                )}
              </div>
              <CompanyDetails
                companyDetails={billing_details}
                onButtonClick={handleOnEditCompanyDetailsClick}
              />
            </div>
            <PaymentMethodInfo payments={payments} />
          </Card>
          {payments?.type === "PREPAID_CODE" && (
            <ResellerInfo reseller={payments.reseller} />
          )}
        </div>
      ) : (
        <Loader className="loader" />
      )}
    </>
  )
}

export default PaymentDetails
