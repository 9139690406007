import { forwardRef } from "react"

import classNames from "classnames"
import ReactDatePicker from "react-datepicker"

import { DatePickerProps, DEFAULT_DATE_PICKER_PORTAL_ID } from "../DatePicker"
import "../DatePicker/style.sass"
import "react-datepicker/dist/react-datepicker.css"

import { ReactComponent as CalendarSVG } from "../../../assets/images/icons/Calendar.svg"
import { ReactComponent as DaySVG } from "../../../assets/images/icons/Day.svg"

export type BasicDateTimePickerProps = DatePickerProps & {
  withTime?: boolean
  timeIntervals?: number
  locale?: string
  timeFormat?: string
  dateFormat?: string
  timeCaption?: string
}

export const BasicDateTimePicker = forwardRef<
  HTMLInputElement,
  BasicDateTimePickerProps
>(
  (
    {
      className,
      value,
      minDate,
      maxDate,
      disabled,
      hasError,
      placeholderText = "mm/dd/yyyy",
      todayButtonText = "Select today",
      showToday = true,
      showMonthDropdown = false,
      clearable = false,
      portalId = DEFAULT_DATE_PICKER_PORTAL_ID,
      withTime = true,
      timeIntervals = 30,
      dateFormat = withTime ? "MMMM d, yyyy HH:mm" : "MMMM d, yyyy",
      timeFormat = "HH:mm",
      ...props
    }: BasicDateTimePickerProps,
    ref,
  ) => {
    const date = value ? new Date(value) : undefined

    const cn = classNames("DatePickerInput", className, {
      disabled,
      error: hasError,
    })

    return (
      <div className={cn}>
        <ReactDatePicker
          {...props}
          minDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDate ? new Date(maxDate) : undefined}
          className="date-picker-input"
          isClearable={clearable}
          selected={date}
          showMonthDropdown={showMonthDropdown}
          placeholderText={placeholderText}
          showPopperArrow={false}
          todayButton={showToday && <TodayButton text={todayButtonText} />}
          fixedHeight
          disabled={disabled}
          customInput={<input ref={ref} />}
          portalId={portalId}
          autoComplete="off"
          calendarStartDay={1}
          showTimeSelect={withTime}
          timeIntervals={timeIntervals} // Here you can change time intervals
          dateFormat={dateFormat}
          timeFormat={timeFormat}
        />
        <CalendarSVG className="calendar-icon" />
      </div>
    )
  },
)

const TodayButton = ({ text }: { text: string }) => (
  <div className="TodayButton">
    <DaySVG />
    <span>{text}</span>
  </div>
)
