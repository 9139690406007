import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import { ReactComponent as HeadingSpacerSVG } from "../assets/images/icons/HeadingSpacer.svg"

import "./Heading.sass"

export const HeadingSpacer = () => {
  return (
    <span className="spacer">
      <HeadingSpacerSVG />
    </span>
  )
}

type Props = {
  isCentered?: boolean
}

export default function Heading({
  children,
  isCentered,
  ...rest
}: PropsWithChildren<Props>) {
  const headingClassName = classNames({
    Heading: true,
    isCentered,
  })
  return (
    <h1 className={headingClassName} {...rest}>
      {children}
    </h1>
  )
}
